<template>
  <div>
    <div class="ResourceListView page-header search-form">
      <a-form :model="formState" name="basic" layout="inline" autocomplete="off">
        <a-form-item :labelCol="{span: 4}" label="状态" name="status" style="margin-bottom: 20px">
          <a-select
            style="width: 200px"
            v-model:value="formState.tag"
            placeholder="请选择"
            @change="fetchData"
          >
            <a-select-option
              v-for="(item, index) in statusOptions"
              :key="index"
              :value="item.value"
              >{{ item.text }}</a-select-option
            >
          </a-select>
        </a-form-item>

        <a-form-item label="类型" name="type" style="margin-bottom: 20px">
          <a-select
            style="width: 200px"
            v-model:value="formState.type"
            placeholder="请选择"
            @change="fetchData"
          >
            <a-select-option value="">全部类型</a-select-option>
            <a-select-option value="movie">电影</a-select-option>
            <a-select-option value="other">其他</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="标题" name="title" style="margin-bottom: 20px">
          <a-input
            style="width: 200px"
            v-model:value="formState.title"
            placeholder="搜索电影"
            allowClear
            @change="fetchData"
            @keydown.enter="fetchData"
          />
        </a-form-item>

        <a-form-item
          label="搜索"
          :label-col="{style: {visibility: 'hidden', width: '1px', overflow: 'hidden'}}"
          style="margin-bottom: 20px"
        >
          <a-space>
            <a-button type="default" @click="reset">重置</a-button>
            <a-button type="primary" @click="fetchData">搜索</a-button>
          </a-space>
        </a-form-item>
      </a-form>
    </div>

    <a-spin :spinning="loading">
      <div class="list-wrapper">
        <div v-if="dataList.length === 0">暂无数据</div>
        <div v-for="item in dataList" :key="item.cid" class="list-item" :data-nextid="item.cid">
          <div class="list-image">
            <img
              :key="item.cid"
              class="image-current"
              fit="cover"
              ondragstart="return false;"
              :src="item.cover_url"
              alt=""
            />
          </div>
          <div class="detail-info">
            <div class="detail-title">
              {{ item.title }}{{ item.year ? `（${item.year}）` : '' }}
            </div>
            <data class="detail-tags">
              <a-tag color="rgba(85,85,85,1)">{{ VAR_TYPE[item.type] }}</a-tag>
              <a-tag v-for="tkey in item.tag" :key="tkey" color="rgba(85,85,85,1)">{{
                getTagText(tkey)
              }}</a-tag>
            </data>
            <div class="detail-description multiline-ellipsis" :title="item.summary">
              {{ item.summary }}
            </div>
            <!-- <div class="detail-time">
              <span>类型：{{ VAR_TYPE[item.type] }}</span>
              <span>状态：{{ VAR_STATUS[item.status] }}</span>
              <span>副本数量：{{ item.replica_count }}</span>
            </div> -->
            <div class="detail-time">
              <span>上架时间：{{ formatRelativeTime(item.create_time) }}</span>
              <span
                >下载统计(7天/总)：{{ item.download_last_7_days }}/{{ item.download_total }}</span
              >
            </div>
            <div class="detail-time">
              <span>文件大小：{{ bytes(item.size || 0) }}</span>
            </div>
          </div>

          <div class="detail-footer">
            <a-space>
              <a-button :disabled="userInfo.role === SUPER_ADMIN" @click="handleDelete(item)"
                >删除</a-button
              >
              <a-button
                :disabled="userInfo.role === SUPER_ADMIN || item.replica_count < 3"
                type="primary"
                @click="handlePublish(item)"
                >发布</a-button
              >
            </a-space>
          </div>
        </div>
      </div>
    </a-spin>
    <div v-if="dataList.length > 0" class="pagination-wrapper">
      <a-pagination
        v-model:current="pagination.current"
        v-model:page-size="pagination.pageSize"
        :total="pagination.total"
        :show-total="pagination.showTotal"
        :defaultPageSize="pagination.defaultPageSize"
        showSizeChanger
        :pageSizeOptions="pagination.pageSizeOptions"
        @change="pagination.onChange"
      />
    </div>
    <PublishModal
      v-model:visible="modalOpenPublish"
      @success="onPublishSuccess"
      :item="selectCurrent"
    />
    <PublishSuccessModal
      v-model:visible="modalOpenPublishSuccess"
      :item="selectCurrent"
      @success="fetchData()"
    />
    <DeleteModal v-model:visible="modalOpenDelete" :item="selectCurrent" @success="fetchData()" />
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {getResourceList} from '@/apis/media.js';
import PublishModal from './PublishModal.vue';
import PublishSuccessModal from './PublishSuccessModal.vue';
import DeleteModal from './DeleteModal.vue';
import usePagination from '@/composable/usePagination';
import formatRelativeTime from '@/utils/formatRelativeTime';
import {VAR_TYPE} from '@/utils/constants';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {SUPER_ADMIN} from '@/router/roles';
import bytes from 'bytes';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);

const formDefaultState = {
  tag: '', // unpublished,published
  type: '',
  title: undefined,
};

const tagArray = [
  {text: '已发布', value: 'published'},
  {text: '未发布', value: 'unpublished'},
  {text: '备份中', value: 'backup'},
];

const statusOptions = [
  {text: '全部状态', value: ''},
  ...tagArray.filter((item) => item.value !== 'backup'),
];

const getTagText = (value) => {
  return tagArray.find((item) => item.value === value)?.text || '';
};

const formState = ref({...formDefaultState});

const modalOpenPublish = ref(false);
const modalOpenPublishSuccess = ref(false);
const modalOpenDelete = ref(false); // 删除弹窗

const dataList = ref([]);

const loading = ref(true);

const selectCurrent = ref();

const {pagination} = usePagination(getList);

const handleDelete = (item) => {
  selectCurrent.value = item;
  modalOpenDelete.value = true;
};

// 该方法解决切换分页后，修改了其他的值，刷新数据是从第n页开始的问题
function reset() {
  formState.value = {...formDefaultState};
  fetchData();
}

function onPublishSuccess(form) {
  modalOpenPublishSuccess.value = true;

  selectCurrent.value.count = form.count;
  selectCurrent.value.unpublishChannel = form.unpublishChannel;
  selectCurrent.value.publishChannel = form.publishChannel;
}

function fetchData() {
  pagination.value.current = 1;
  getList();
}

function getList() {
  loading.value = true;

  return getResourceList({
    title: formState.value.title || undefined,
    current: pagination.value.current,
    size: pagination.value.pageSize,
    type: formState.value.type || undefined,
    tag: formState.value.tag || undefined,
  })
    .then((response) => {
      pagination.value = {
        ...pagination.value,
        total: response.data.data.total,
      };
      dataList.value = response?.data?.data?.records || [];
    })
    .finally(() => {
      loading.value = false;
    });
}

function handlePublish(item) {
  console.log('[handlePublish item]', item);
  selectCurrent.value = item;
  modalOpenPublish.value = true;
  console.log('modalOpenPublish.value', modalOpenPublish.value);
}

onMounted(() => {
  fetchData();
});
</script>

<style scoped lang="scss">
.page-header {
  margin-bottom: 20px;
  display: flex;
}

.search-form {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
  .radio-btn {
    display: inline-flex;
    flex-flow: row wrap;
    gap: 8px;
  }

  .input-search {
    max-width: 460px;
  }
}

@media (max-width: 1100px) {
  .search-form {
    .input-search {
      max-width: 320px;
    }
  }
}
@media (max-width: 768px) {
  .page-header {
    justify-content: flex-start;
  }
  .search-form {
    .input-search {
      max-width: 264px;
    }
  }
}

.list-header {
  margin-bottom: 24px;
}
.list-wrapper {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}
.list-item {
  width: 358px;
  overflow: hidden;
  border: 1px solid #f0f0f0;
  border-radius: 16px;
  box-sizing: border-box;

  &:hover {
    box-shadow:
      0 1px 2px -2px rgba(0, 0, 0, 0.16),
      0 3px 6px 0 rgba(0, 0, 0, 0.12),
      0 5px 12px 4px rgba(0, 0, 0, 0.09);
    border-color: transparent;
    transition:
      box-shadow 0.2s,
      border-color 0.2s;
  }
}

.list-image {
  display: flex;
  justify-content: center;
  color: #ababab;

  width: 358px;
  height: 200px;
  user-select: none;
  background-color: #f5f5f5;
  border-radius: 16px 16px 0 0;
  overflow: hidden;

  .image-current {
    width: inherit;
    height: inherit;
    object-fit: cover;
  }

  &.is-upload {
    font-size: 60px;
    cursor: pointer;
  }
}

.detail-info {
  display: flex;
  flex-flow: column nowrap;
  padding: 12px 16px 16px 16px;

  box-sizing: border-box;

  .detail-title {
    font-size: 18px;
    font-weight: bold;
  }
  .detail-tags {
    margin: 16px 0 8px 0;
  }
  .detail-description {
    font-size: 16px;
    line-height: 1.5;
    margin-top: 12px;
    color: #7b7b7b;
    height: 1.5 * 3em;
    word-break: break-all;
  }
  .detail-time {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    color: #7b7b7b;
  }
}
.detail-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 16px 24px;
}
.poster-title {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2; /* For browsers that support it */
}
</style>

<style lang="scss">
.qrcode-header {
  background-color: #ebf5eb;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}
.qrcode-wrapper {
  width: 400px;
  background: url('@/assets/wechat_help.png') right 0 no-repeat;
}
</style>
