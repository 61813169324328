<template>
  <a-table
    rowKey="channel_id"
    :loading="pageLoading"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    :expand-column-width="100"
    expandRowByClick
    @change="onTableChange"
    @expand="onExpandedRowClick"
    class="overflow-x__auto"
  >
    <template #headerCell="{column}">
      <span v-if="column.key" style="white-space: nowrap">{{ column.title }}</span>
    </template>
    <template #bodyCell="{column, record}">
      <template v-if="column.key === 'channel_name'">
        <span style="white-space: wrap; word-break: break-all">{{
          record.channel_name || '-'
        }}</span>
      </template>
      <template v-if="column.key === 'channel_contact'">
        <span style="white-space: nowrap">{{ record.channel_contact || '-' }}</span>
      </template>
      <template v-if="column.key === 'create_time'">
        <span style="white-space: nowrap">{{
          dayjs(record.create_time).format('YYYY/MM/DD HH:mm:ss')
        }}</span>
      </template>
      <template v-if="column.key === 'action'">
        <a-space style="white-space: nowrap">
          <!-- <a-typography-link type="link" class="btn-link" disabled>查看</a-typography-link> -->
          <a-typography-link
            type="link"
            class="btn-link"
            @click.stop="showConfirm('off', record, column)"
            >下架</a-typography-link
          >
          <a-typography-link
            type="link"
            class="btn-link"
            @click.stop="showConfirm('ignore', record)"
            >忽略</a-typography-link
          >
        </a-space>
      </template>
    </template>

    <template #expandedRowRender="{record}">
      <a-table
        :columns="innerColumns"
        :data-source="innerData[record.channel_id]?.data"
        :pagination="false"
        :showHeader="false"
      >
        <template #bodyCell="{column, record}">
          <template v-if="column.key === 'create_time'">
            <span style="white-space: nowrap">
              {{
                record.create_time ? dayjs(record.create_time).format('YYYY/MM/DD HH:mm:ss') : ''
              }}
            </span>
          </template>
          <template v-if="column.key === 'user_id'">
            <span style="white-space: wrap; word-break: break-all">
              {{ record.user_id }}
              <template v-if="record.phone">（{{ record.phone }}）</template>
            </span>
          </template>
        </template>
        <template #emptyText>暂无数据</template>
      </a-table>
      <div
        v-if="innerData[record.channel_id]?.hasMore"
        style="margin-top: 16px; text-align: center"
      >
        <a-button @click="loadMore(record.channel_id)">加载更多</a-button>
      </div>
    </template>
  </a-table>
</template>

<script setup>
import {computed, createVNode, ref} from 'vue';
import dayjs from 'dayjs';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {
  getLinkAuditReportChannelExpand,
  postLinkAuditReportChannelBlock,
  postLinkAuditReportChannelIgnore,
} from '@/apis/review.js';

const props = defineProps({
  pageLoading: {type: Boolean, default: false},
  dataSource: {type: Array, default: () => []},
  query: {type: Object, default: () => ({})},
  onTableChange: {type: Function, default: () => {}},
  fetchData: {type: Function, default: () => {}},
});

const columns = computed(() =>
  [
    {
      title: '频道ID',
      key: 'channel_id',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '频道名称',
      key: 'channel_name',
      align: 'center',
      ellipsis: true,
    },
    {
      title: '举报数量',
      key: 'report_cnt',
      align: 'center',
      sorter: (a, b) => a.report_cnt - b.report_cnt,
    },
    {
      title: '资源数量',
      key: 'resource_cnt',
      align: 'center',
      sorter: (a, b) => a.resource_cnt - b.resource_cnt,
    },
    {
      title: '频道主联系方式',
      key: 'channel_contact',
      align: 'center',
    },
    {
      title: '频道上线时间',
      key: 'create_time',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const loading = ref(false);

const showConfirm = (type, record) => {
  let confirmModal = null;
  const title = type === 'off' ? '下架' : '忽略';
  const titleText = title + '频道举报';
  confirmModal = Modal.confirm({
    title: titleText,
    icon: createVNode(ExclamationCircleOutlined),
    content: `你确定 ${title} 吗？`,
    okText: '确定',
    okButtonProps: {loading: loading.value},
    centered: true,
    async onOk() {
      loading.value = true;
      confirmModal.update({
        okButtonProps: {loading: true},
      });
      try {
        let fetchData;

        if (type === 'off') {
          fetchData = postLinkAuditReportChannelBlock;
        } else {
          fetchData = postLinkAuditReportChannelIgnore;
        }

        const response = await fetchData({channel_id: record.channel_id});
        if (response.data.status === 200) {
          message.success(titleText + '成功');
          confirmModal.destroy();
        } else {
          throw response;
        }
      } catch (err) {
        console.error(err);
        message.error(err?.data?.message || titleText + '失败');
        confirmModal.update({
          okButtonProps: {loading: false},
        });
        throw err;
      } finally {
        loading.value = false;

        props.fetchData();
      }
    },
    onCancel() {},
  });
};

const innerData = ref({});
const innerColumns = [
  {
    title: '举报时间',
    key: 'create_time',
    dataIndex: 'create_time',
    width: 170,
  },
  {
    title: '举报标签',
    key: 'tag',
    dataIndex: 'tag',
    width: 200,
  },
  {
    title: '举报内容',
    key: 'description',
    dataIndex: 'description',
    ellipsis: true,
  },
  {
    title: '用户ID（手机号码）',
    key: 'user_id',
    dataIndex: 'user_id',
    width: 170,
  },
];

const onExpandedRowClick = async (expanded, item) => {
  if (expanded) {
    loadMore(item.channel_id, true);
  }
};

const loadMore = async (channelId, reset = false) => {
  const currentPage = reset ? 1 : (innerData.value[channelId]?.page || 1) + 1;

  try {
    const PAGE_SIZE = 10; // PAGE_SIZE
    const response = await getLinkAuditReportChannelExpand({
      current: currentPage,
      size: PAGE_SIZE,
      channel_id: channelId,
    });

    if (response.data.status === 200) {
      const records = response.data?.data?.records || [];
      const total = response.data?.data?.total || 0;
      const hasMore = currentPage * PAGE_SIZE < total;

      if (reset) {
        innerData.value = {
          ...innerData.value,
          [channelId]: {data: records, page: currentPage, hasMore},
        };
      } else {
        innerData.value = {
          ...innerData.value,
          [channelId]: {
            data: [...innerData.value[channelId].data, ...records],
            page: currentPage,
            hasMore,
          },
        };
      }
    } else {
      throw response;
    }
  } catch (error) {
    if (reset) {
      innerData.value = {...innerData.value, [channelId]: {data: [], page: 1, hasMore: false}};
    }
    console.error('[loadMore error]', error);
  }
};
</script>

<style lang="scss">
.alert-wrapper {
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  font-size: 14px !important;
  align-items: center;
  padding: 8px 16px;
  .ant-alert-description {
    font-size: 14px;
    line-height: 1em;
  }
  .ant-alert-icon {
    font-size: 16px;
  }
}
</style>
