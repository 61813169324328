import {useUserStore} from '@/stores/userStore';
import router from '@/router/index.js';

export function resetLoginData() {
  const userStore = useUserStore();
  userStore.setUserInfo(null);
  userStore.setLoginState(null);
  userStore.setTwoFactor(false);
}
export function handleLogout() {
  resetLoginData();
  router.replace('/login');
}
