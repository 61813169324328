// 引入dayjs库和相关插件
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

// 使用插件
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(isSameOrBefore);

export default function formatRelativeTime(create_time) {
  const now = dayjs();
  const createTime = dayjs(create_time);

  if (createTime.isToday()) {
    return createTime.fromNow().replace(/(\d+)\s+(分钟前|小时前)/g, '$1$2'); // n分钟前 / n小时前
  } else if (createTime.isYesterday()) {
    return '昨天';
  } else if (createTime.isSameOrBefore(now.subtract(2, 'day'))) {
    const daysDiff = now.diff(createTime, 'day');

    return `${daysDiff}天前`;
  }
}
