import {h} from 'vue';
import {Modal, Button} from 'ant-design-vue';

// 登录 选择角色
export default function useLoginSelectRole() {
  const showConfirm = (roles) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: '请选择您的身份继续',
        content: () =>
          h('div', {class: 'select-role'}, [
            ...roles.map((role) =>
              h(
                Button,
                {
                  type: 'default',
                  style: {margin: '5px', width: '162px'},
                  onClick: () => {
                    Modal.destroyAll();
                    resolve(role.value);
                  },
                },
                {default: () => role.label},
              ),
            ),
          ]),
        okButtonProps: {style: {display: 'none'}},
        cancelButtonProps: {style: {display: 'none'}},
        onCancel() {
          reject(new Error('操作取消'));
        },
      });
    });
  };

  return {showConfirm};
}
