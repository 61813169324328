<template>
  <div v-if="showPageContent">
    <div class="page-content">
      <MaterialLibrary />
    </div>
  </div>
  <RouterView v-else></RouterView>
</template>

<script setup>
import {ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import MaterialLibrary from './material-library.vue';
import * as RouteNames from '@/router/names.js';

const route = useRoute();
const showPageContent = ref(true);

const checkRoute = () => {
  showPageContent.value = route.name === RouteNames.MaterialLibrary;
};

// Initial check
checkRoute();

// Watch for route changes
watch(
  () => route.path,
  () => {
    checkRoute();
  },
);
</script>
