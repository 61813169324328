import {getUserProfile} from '@/apis/users';
import {message} from 'ant-design-vue';
import dayjs from 'dayjs';

export function getNextId() {
  let nextId = parseInt(sessionStorage.getItem('nextId') || 0, 10);

  nextId++;

  sessionStorage.setItem('nextId', nextId);
  return nextId;
}

export function debounce(func, wait) {
  let timeout;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(context, args);
    }, wait);
  };
}

export async function copyText(data) {
  let text;
  let showMessage = true;
  if (typeof data === 'string') {
    text = data;
  } else if (data && typeof data === 'object') {
    text = data.text;
    showMessage = data.showMessage;
  }
  try {
    if (!text) {
      throw new Error('copyText params text is ' + text);
    }
    await navigator.clipboard.writeText(text);
    showMessage && message.success('复制成功');
  } catch (error) {
    showMessage && message.error('复制失败');
  }
}

export function generatePassword(length, isLow = false) {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }

  if (isLow) {
    return result?.toLowerCase();
  }

  return result;
}

// 更新登录状态
export async function updateLoginState({userStore, loginState}) {
  const now = Date.now(); // 当前时间的时间戳（毫秒）
  const expiresInMs = loginState.expires_in * 1000; // 将过期时间转换为毫秒
  const expiresAt = now + expiresInMs; // 计算过期时间戳
  userStore.setLoginState({...loginState, expiresAt});

  const profileResponse = await getUserProfile();
  const userInfo = profileResponse.data.data;

  userStore.setUserInfo(userInfo);

  return {userInfo, loginState};
}

// 替换特殊字符 *、?、[、]、"、\、<、>、|
// eslint-disable-next-line no-unused-vars
export function replaceSpecialChars(str, value = '') {
  // eslint-disable-next-line
  // return str.replace(/[\*\?\[\]"\\<>|]/g, value);
  return str;
}

export function getMobileDevice() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // 正则表达式检查是否是移动端设备
  return /android|avantgo|blackberry|bolt|boost|cricket|docomo|fone|hiptop|mini|mobi|palm|phone|pie|tablet|up\.browser|up\.link|webos|wos/i.test(
    userAgent,
  );
}

export function calculateElapsedTime(create_time, cost_time) {
  // create_time is expected to be a Date object
  let endTime = new Date(create_time.getTime() + cost_time * 1000);

  let hours = endTime.getHours() - create_time.getHours();
  let minutes = endTime.getMinutes() - create_time.getMinutes();
  let seconds = endTime.getSeconds() - create_time.getSeconds();

  // Adjust for negative values
  if (seconds < 0) {
    seconds += 60;
    minutes -= 1;
  }
  if (minutes < 0) {
    minutes += 60;
    hours -= 1;
  }

  let result = '';
  if (hours > 0) {
    result += hours + '小时';
  }
  if (minutes > 0 || hours > 0) {
    result += minutes + '分';
  }
  result += seconds + '秒';

  return result;
}

/**
 * 将秒数转换为天、小时、分钟、秒的格式输出
 * @param {number} seconds - 输入的秒数
 * @returns {string} 格式化后的时间字符串
 */
export function formatTime(seconds) {
  // 计算天数
  let days = Math.floor(seconds / 86400);
  // 计算剩余的小时数
  let hours = Math.floor((seconds % 86400) / 3600);
  // 计算剩余的分钟数
  let minutes = Math.floor((seconds % 3600) / 60);
  // 计算剩余的秒数
  let secs = seconds % 60;

  // 用于存储结果字符串的数组
  let result = [];

  if (days > 0) {
    result.push(`${days}天`);
    if (hours > 0) {
      result.push(`${hours}小时`);
    }
  } else if (hours > 0) {
    result.push(`${hours}小时`);
    if (minutes >= 0) {
      result.push(`${minutes}分钟`);
    }
  } else {
    result.push(`${minutes}分钟`);
    if (secs >= 0) {
      result.push(`${secs}秒`);
    }
  }

  // 将结果数组连接成字符串并返回
  return result.join('');
}

export function getCurrentFormattedTime() {
  return dayjs().format('YYYY/MM/DD HH:mm:ss.SSS');
}

export function getPathFromUrl(url) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
}
