// 频道类型
export const TYPE_CHANNEL = {
  public: '公开', // 公开 且 无需认证的频道
  auth: '需认证', // 公开 且 需认证的频道
  private: '私密', // 私密（不公开） 且 需认证的频道
};

/**
 * 根据传入的对象属性判断频道类型并返回对应的字符串
 * @param {Object} channel - 频道对象
 * @param {boolean} channel.visible - 是否公开
 * @param {boolean} channel.verify - 是否认证
 * @returns {string} - 返回频道类型字符串
 */
export function getChannelType(channel) {
  if (channel.visible && !channel.verify) {
    return TYPE_CHANNEL.public; // 公开 且 无需认证的频道
  } else if (channel.visible && channel.verify) {
    return TYPE_CHANNEL.auth; // 公开 且 需认证的频道
  }
  return TYPE_CHANNEL.private; // 私密（不公开） 且 需认证的频道
}
/**
 * 根据传入的对象属性判断频道类型并返回对应的字符串
 * @param {Object} channel - 频道对象
 * @param {boolean} channel.visible - 是否公开
 * @param {boolean} channel.verify - 是否认证
 * @returns {string} - 返回频道类型字符串
 */
export function getChannelKeyType(channel) {
  if (channel.visible && !channel.verify) {
    return 'public'; // 公开 且 无需认证的频道
  } else if (channel.visible && channel.verify) {
    return 'auth'; // 公开 且 需认证的频道
  }
  return 'private'; // 私密（不公开） 且 需认证的频道
}

// normal-正常，block-整改，resource_block-影片整改，closed-注销
// 频道状态
export const channelStatuses = [
  {text: '正常', value: 'normal'},
  {text: '频道整改', value: 'block'},
  {text: '影片整改', value: 'resource_block'},
  {text: '已注销', value: 'closed'},
];

/**
 * 根据传入的value返回对应的text
 * @param {string} value - 需要查找的value值
 * @returns {string} - 返回对应的text值，如果找不到则返回'未知状态'
 */
export function getTextByValue(value) {
  const status = channelStatuses.find((status) => status.value === value);
  return status ? status.text : '';
}

/**
 * 计算从当前日期到指定过期时间的剩余天数
 * @param {string} expired_time - 过期时间的字符串，格式为 "YYYY-MM-DDTHH:MM:SS+TZD"
 * @returns {number} 剩余的到期天数，精确到天
 */
export function getRemainingDays({expired_time, showText = true}) {
  // 获取当前时间
  const currentTime = new Date();

  // 将传入的过期时间字符串转换为 Date 对象
  const expirationTime = new Date(expired_time);

  // 计算过期时间与当前时间的毫秒差
  const timeDifference = expirationTime - currentTime;

  // 将毫秒差转换为天数，并向下取整
  const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (showText) {
    if (remainingDays >= 365 * 100) {
      return '永久';
    }

    // 返回剩余天数
    return remainingDays + '天';
  } else {
    return remainingDays;
  }
}
