<template>
  <a-modal
    width="600px"
    centered
    :open="dialogVisible"
    :title="type === 'create' ? '新建频道' : '编辑频道'"
    :closable="!loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <div class="form-wrapper" @dragover.stop.prevent="handleDropOver" @drop.prevent="handleDrop">
      <a-form
        ref="formRef"
        :model="formState"
        :rules="rules"
        :label-col="{span: 6}"
        :wrapper-col="{span: 14}"
        autocomplete="off"
      >
        <a-form-item label="频道类型" name="type">
          <a-select
            v-model:value="formState.type"
            placeholder="请选择"
            allowClear
            style="min-width: 258px"
          >
            <a-select-option v-for="item in Object.keys(TYPE_CHANNEL)" :key="item" :value="item">{{
              TYPE_CHANNEL[item]
            }}</a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item label="频道名称" name="name">
          <a-input style="min-width: 258px" v-model:value="formState.name" />
        </a-form-item>
        <a-form-item label="频道简介" name="summary">
          <a-textarea style="min-width: 258px" v-model:value="formState.summary" />
        </a-form-item>
        <a-form-item label="频道logo" name="icon">
          <div class="image-container">
            <a-input v-show="false" v-model:value="formState.icon"></a-input>
            <div v-if="!formState.icon" class="add-resource__upload-btn" @click="triggerFileInput">
              <img style="width: 24px" ondragstart="return false" src="@/assets/upload_plus.png" />
            </div>

            <div v-else class="image-wrapper">
              <img
                style="width: 166px; height: 166px; object-fit: cover"
                :src="formState.icon"
                ondragstart="return false"
              />
              <img
                @click="handleClearImage"
                class="close-icon"
                width="25px"
                height="25px"
                ondragstart="return false"
                src="@/assets/close.svg"
              />
            </div>
          </div>
          <div v-if="!formState.icon" class="logo-summary">
            请上传您的频道logo，文件大小不得超过10MB。选择清晰、高质量的图片，文件格式支持JPEG、PNG、BMP、WEBP等常见格式。
          </div>
        </a-form-item>
      </a-form>
      <input
        :key="inputKey"
        type="file"
        ref="fileInput"
        accept="image/*"
        @change="handleFileChange"
        style="display: none"
      />
    </div>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleOk"
        :disabled="!isFormChanged || !formValid || loading"
        >确定</a-button
      >
    </template>
  </a-modal>

  <ModalCropper
    v-if="visibleImageCropper"
    v-model:visible="visibleImageCropper"
    :src="formState.src"
    @success="handleUploadSuccess"
  />
</template>

<script setup>
import {computed, nextTick, ref, watch} from 'vue';
import {message} from 'ant-design-vue';
import {TYPE_CHANNEL, getChannelKeyType} from '../utils';
import ModalCropper from '@/components/ModalCropper/index.vue';
import {getLinkChannelInfo, postLinkChannelCreate, postLinkChannelUpdate} from '@/apis/channel';

const props = defineProps({
  visible: {type: Boolean, default: false},
  type: {type: String, default: 'create'}, // create edit
  item: {type: Object, default: () => ({})},
});
const emits = defineEmits(['update:visible', 'success']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});
const loading = ref(false);

const inputKey = ref(Math.random());

const visibleImageCropper = ref(false); // 打开图片裁切弹出

const formDefaultState = {
  type: undefined,
  name: undefined,
  summary: undefined,
  icon: undefined,
  src: undefined,
};

const formState = ref({...formDefaultState});

const rules = {
  type: [{required: true, message: '请选择频道类型', trigger: 'change'}],
  name: [{required: true, message: '请输入频道名称', trigger: ['blur', 'change']}],
  summary: [{required: true, message: '请输入频道简介', trigger: ['blur', 'change']}],
  icon: [{required: true, message: '请上传频道logo', trigger: 'change'}],
};

const formRef = ref(null);

const formValid = computed(() => {
  return (
    formState.value.type && formState.value.name && formState.value.summary && formState.value.icon
  );
});

const isFormChanged = computed(() => {
  return Object.keys(formState.value).some((key) => formState.value[key] !== props.item[key]);
});

const handleOk = async () => {
  loading.value = true;
  try {
    await formRef.value.validate();

    let visible;
    let verify;

    switch (formState.value.type) {
      case 'public': {
        visible = true;
        verify = false;
        break;
      }
      case 'auth': {
        visible = true;
        verify = true;
        break;
      }
      case 'private': {
        visible = false;
        verify = true;
        break;
      }
    }

    const fetchData = props.type === 'edit' ? postLinkChannelUpdate : postLinkChannelCreate;

    const response = await fetchData({
      id: formState.value.id,
      visible,
      verify,
      name: formState.value.name,
      summary: formState.value.summary,
      icon: formState.value.icon,
    });

    if (response.data.status === 200) {
      emits('success', {...formState.value});
      dialogVisible.value = false;
      // console.log(response.data)
      if (props.type === 'edit') {
        message.success('编辑频道成功');
      } else {
        message.success('新建频道成功');
      }
    } else {
      throw response;
    }
  } catch (error) {
    message.error('提交表单错误');
  } finally {
    loading.value = false;
  }
};

const handleUploadSuccess = (url) => {
  formState.value = {...formState.value, icon: url};
};

const handleCancel = () => {
  dialogVisible.value = false;
};

const fileInput = ref(null);

const triggerFileInput = () => {
  fileInput.value.click();
};

const handleFileChange = (event) => {
  const file = event.target.files[0];
  if (file && file.size <= 1024 * 1024 * 100) {
    // Check file size <= 1MB
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        formState.value.src = e.target.result;

        visibleImageCropper.value = true;

        inputKey.value = Math.random() + Date.now();
      };
      reader.readAsDataURL(file);
    } else {
      message.error('仅支持JPEG、PNG、BMP、WEBP等格式的文件');
      inputKey.value = Math.random() + Date.now();
    }
  } else {
    message.error('图片大小不得超过10MB');
    inputKey.value = Math.random() + Date.now();
  }
};

const handleClearImage = () => {
  formState.value = {
    ...formState.value,
    icon: undefined,
    src: undefined,
  };
};

const handleDropOver = () => {};

const handleDrop = (event) => {
  const file = event.dataTransfer.files[0];
  if (file && file.size <= 1024 * 1024 * 10) {
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        formState.value.src = e.target.result;
        visibleImageCropper.value = true;
      };
      reader.readAsDataURL(file);
    } else {
      message.error('仅支持JPEG、PNG、BMP、WEBP等格式的文件');
    }
  } else {
    message.error('图片大小不得超过10MB');
  }
};

watch(
  () => props.visible,
  async (newVal) => {
    if (newVal) {
      if (props.type === 'create') {
        formState.value = {...formDefaultState};
      } else {
        formState.value = {
          ...formDefaultState,
          id: props.item.id,
          type: getChannelKeyType(props.item),
          name: props.item.name,
          summary: props.item.summary,
          icon: props.item.icon,
          visible: props.item.visible,
          verify: props.item.verify,
        };
        loading.value = true;
        try {
          const response = await getLinkChannelInfo({id: props.item.id});

          if (response.data.status === 200) {
            const info = response.data.data || {};

            formState.value = {
              ...formState.value,
              type: getChannelKeyType(info),
              summary: info.summary,
              icon: info.icon,
              visible: info.visible,
              verify: info.verify,
            };
          } else {
            throw response;
          }
        } catch (error) {
          message.error(error?.data?.message || '获取频道详情失败');
          console.error([error]);
        } finally {
          loading.value = false;
        }
      }
      formRef.value?.clearValidate();
    } else {
      await nextTick();
      formRef.value?.clearValidate();
    }
  },
);
</script>

<style scoped lang="scss">
.form-wrapper {
  padding: 40px 0;
}
.image-wrapper {
  display: inline-flex;
  border: 1px dashed transparent;
  padding: 12px;
  border-radius: 8px;
  user-select: none;
  position: relative;
  .close-icon {
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    display: none;
    transition: all ease 0.1s;
  }
}

.form-wrapper:hover .image-wrapper {
  border: 1px dashed #d9d9d9;

  .close-icon {
    display: block;
  }
}
.add-resource__upload-btn {
  width: 192px;
  height: 192px;
  border: 1px dashed #d9d9d9;

  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
  margin-bottom: 16px;
  &.is-disabled {
    background-color: #ededed;
  }
}
.logo-summary {
  font-size: 12px;
  line-height: 2em;
  color: #7b7b7b;
}
</style>
