<template>
  <main class="login-container">
    <div class="two-factor">
      <div class="title-wrapper">
        <div style="margin-bottom: 24px">
          你已开启登录保护，向{{ loginState.phone }}发送了登录验证码
        </div>
        <div>如果想继续登录，请输入验证码</div>
      </div>
      <div class="input-wrapper">
        <a-input-number
          v-for="(item, index) in allCodes"
          :key="index"
          v-model:value="allCodes[index]"
          :ref="(el) => (inputRefs[index] = el)"
          :status="(showError && allErrors[index]) || ''"
          @keyup="handleKeyUp($event, index)"
          @focus="handleFocus($event, index)"
          @paste="handlePaste($event, index)"
          :min="0"
          :max="9"
          size="large"
          style="width: 38px"
        />
      </div>
      <div class="resend-container">
        <span class="countdown" v-if="countdown > 0">{{ countdown }}s后重新发送验证码</span>
        <a-button
          class="resend-button"
          size="large"
          style="width: 218px"
          v-if="countdown <= 0"
          @click="resendCode"
        >
          重新发送验证码
        </a-button>
      </div>
      <div class="login-button">
        <a-button
          @click="handleLogin"
          :type="allErrors.some((item) => item === 'error') ? 'default' : 'primary'"
          size="large"
          style="width: 218px"
        >
          确认登录
        </a-button>
      </div>
    </div>
    <PageFooter />
  </main>
</template>

<script setup>
import {ref, computed, nextTick, onMounted} from 'vue';
import {useRouter} from 'vue-router';
import * as RouteNames from '@/router/names.js';
import {message} from 'ant-design-vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {postAuthRole, postAuthVerfiy, postLogin} from '@/apis/users';
import {updateLoginState} from '@/utils/utils';
import {loadUserData} from '@/utils/useLocalStorage';
import PageFooter from '@/components/PageFooter/index.vue';
import {CHANNEL_MANAGER, getRoleName} from '@/router/roles';
import useLoginSelectRole from '@/composable/useLoginSelectRole';

const {showConfirm} = useLoginSelectRole();

const userStore = useUserStore();
const {loginState} = storeToRefs(userStore);
const router = useRouter();

const allCodes = ref(new Array(6).fill(''));
const inputRefs = ref([]);
const showError = ref(false);

const countdown = ref(60);
const timer = ref(null);

const allErrors = computed(() => {
  const result = [];
  for (let index = 0; index < 6; index++) {
    if (isSingleDigit(allCodes.value[index])) {
      result.push('');
    } else {
      result.push('error');
    }
  }
  return result;
});

onMounted(() => {
  startCountdown();
});

function isSingleDigit(str) {
  const pattern = /^[0-9]$/;
  return pattern.test(str);
}

const handleKeyUp = (event, index) => {
  if (event.key === 'Backspace' || event.key === 'Delete') {
    // 如果删除时当前输入框为空，或者用户主动删除，尝试移动焦点到前一个输入框
    if (index > 0) {
      nextTick(() => {
        inputRefs.value[index - 1].focus();
      });
    }
  }
  // 检查按键输入是否为数字
  else if (event.key >= '0' && event.key <= '9') {
    // 直接将输入框的值设置为新输入的数字，无论之前是否已经有值
    allCodes.value[index] = event.key; // 用最新输入的数字替换现有内容

    allCodes.value = [...allCodes.value];

    // 使用 nextTick 确保在 DOM 更新后执行焦点转移
    nextTick(() => {
      // 如果不是最后一个输入框，将焦点移到下一个输入框
      if (index < allCodes.value.length - 1) {
        inputRefs.value[index + 1].focus(); // 移动到下一个输入框
      } else {
        // 如果是最后一个输入框，让它失去焦点
        inputRefs.value[index].blur();
      }
    });
  }
};

const handleFocus = (event) => {
  nextTick(() => {
    event.target.select(); // 选中输入框中的内容
  });
};

const handlePaste = async (event, index) => {
  event.preventDefault(); // 阻止默认粘贴行为
  const pasteData = event.clipboardData.getData('text').trim().replace(/\D/g, ''); // 获取并清理非数字字符
  const nums = pasteData.split('');

  // 循环粘贴的数字，并从当前索引开始填充
  for (let i = 0; i < nums.length; i++) {
    const currentIdx = index + i; // 计算当前应填充的索引位置
    if (currentIdx < allCodes.value.length) {
      allCodes.value[currentIdx] = Number(nums[i]); // 直接赋值
      nextTick(() => {
        if (currentIdx < allCodes.value.length - 1) {
          inputRefs.value[currentIdx + 1].focus(); // 移动到下一个输入框
        } else {
          inputRefs.value[currentIdx].blur(); // 如果是最后一个输入框，让它失去焦点
        }
      });
      await new Promise((resolve) => setTimeout(resolve, 100)); // 暂停一下以确保焦点移动正常
    }
  }

  // 更新allCodes数组以触发Vue的响应性更新
  allCodes.value = [...allCodes.value];
};

const isFormValid = () => {
  return !allErrors.value.some((item) => item === 'error');
};

const handleLogin = async () => {
  showError.value = true;
  if (isFormValid()) {
    try {
      const response = await postAuthVerfiy({
        username: loginState.value.username,
        token: loginState.value.temp_token,
        code: allCodes.value.join(''),
      });
      if (response.data.status === 200) {
        const loginData = response.data.data;

        const currentRoles = (loginData?.roles || []).map((item) => ({
          value: item,
          label: getRoleName(item),
        }));

        let role;
        if (currentRoles.length >= 2) {
          role = await showConfirm(currentRoles);
          console.log('[showConfirm role]', role);
        } else if (loginData.roles.length === 1) {
          role = loginData.roles[0];
        }

        const roleResponse = await postAuthRole({
          token: loginData.temp_token,
          role: role,
        });

        const authRoleData = roleResponse.data.data;
        const loginState = authRoleData;

        userStore.setTwoFactor(true);

        await updateLoginState({userStore, loginState, loginData});
        if (role === CHANNEL_MANAGER) {
          // 频道管理
          router.replace({name: RouteNames.NewUserApplicationView});
        } else {
          router.replace({name: RouteNames.DeviceListView});
        }
      } else {
        throw response;
      }
    } catch (error) {
      console.log(error);
      message.error(error?.data?.message || '验证码错误');
    }
  }
};

const startCountdown = () => {
  timer.value = setInterval(() => {
    if (countdown.value > 0) {
      countdown.value -= 1;
    } else {
      clearInterval(timer.value);
    }
  }, 1000);
};

const resendCode = async () => {
  try {
    const {username, password} = await loadUserData();
    const response = await postLogin({username, password});
    if (response?.data?.status === 200) {
      const loginState = response.data.data;

      if (loginState.verify) {
        countdown.value = 60;
        startCountdown();
        message.success('发送验证码成功');
      } else {
        await updateLoginState({userStore, loginState});
        router.replace({name: RouteNames.DeviceListView});
      }
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.data?.message || '发送验证码失败');
  }
};
</script>

<style lang="scss" scoped>
.login-container {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  text-align: center;

  background: #f0f2f5 url('@/assets/background.svg') no-repeat 50%;
  background-size: 100%;
}

.two-factor {
  margin-top: auto;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  text-align: center;
  line-height: 1.5em;
}

.title-wrapper {
  font-size: 24px;
}
.input-wrapper {
  display: flex;
  justify-content: center;
  padding: 40px 0px 40px 0px;
  gap: 40px;
  ::v-deep(.ant-input-number-input) {
    text-align: center;
  }

  ::v-deep(.ant-input-number-handler-wrap) {
    display: none;
  }
}

.resend-container {
  height: 40px;
  line-height: 40px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .input-wrapper {
    gap: 20px;
  }
}
</style>
