// @/apis/channel.js
import request from '@/utils/request.js';

// 频道列表 下拉，媒资管理 - 列表查看 -  发布 - 上传频道
export function getLinkChannelDropdown(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/dropdown',
    params,
  });
}
// 创建的频道 列表
export function getLinkChannelListCreate(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/list/create',
    params,
  });
}
// 创建的频道 列表 创建频道
export function postLinkChannelCreate(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/create',
    data: {
      visible: data.visible,
      verify: data.verify,
      name: data.name,
      summary: data.summary,
      icon: data.icon,
    },
  });
}
// 创建的频道 列表 频道详情
export function getLinkChannelInfo(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/info',
    params: {
      id: params.id,
    },
  });
}
// 创建的频道 列表 编辑频道
export function postLinkChannelUpdate(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/update',
    data: {
      id: data.id,
      visible: data.visible,
      verify: data.verify,
      name: data.name,
      summary: data.summary,
      icon: data.icon,
    },
  });
}
// 创建的频道 列表 频道注销
export function postLinkChannelClose(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/close',
    data: {
      id: data.id,
    },
  });
}
// 管理的频道 列表
export function getLinkChannelListManage(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/list/manage',
    params,
  });
}
// 管理的频道 内容管理 列表
export function getLinkChannelResourceList(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/resource/list',
    params,
  });
}
// 管理的频道 内容管理 查看海报
export function getLinkChannelResourceCover(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/resource/cover',
    params: {
      resource_id: params.resource_id,
    },
  });
}
// 管理的频道 内容管理 下架
export function getLinkChannelResourceTakeDown(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/resource/take_down',
    data: {
      channel_id: data.channel_id,
      resource_id: data.resource_id,
    },
  });
}
// 管理的频道 成员管理 所在频道
export function getLinkChannelWithin(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/within',
    params: {current: params.current || 1, size: params.size || 100},
  });
}
// 管理的频道 成员管理 频道用户列表
export function getLinkChannelUserList(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/user/list',
    params: {
      current: params.current || 1,
      size: params.size || 10,
      channel_id: params.channel_id || undefined,
      sort: params.sort || undefined,
      role: params.role || undefined, // 'master;manager;user'
      order: params.order || undefined,
    },
  });
}
// 管理的频道 成员管理 频道用户移除
export function getLinkChannelUserRemove(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/remove',
    data: {
      channel_id: data.channel_id,
      user_id: data.user_id,
    },
  });
}
// 管理的频道 成员管理 频道内的昵称
export function postLinkChannelUserUpdateNickname(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/update/nickname',
    data: {
      channel_id: data.channel_id,
      user_id: data.user_id,
      nickname: data.nickname,
    },
  });
}
// 管理的频道 成员管理 修改频道用户角色
export function postLinkChannelUserUpdateRole(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/update/role',
    data: {
      channel_id: data.channel_id,
      user_id: data.user_id,
      role: data.role,
    },
  });
}
// 管理的频道 成员管理 修改频道用户过期时间
export function postLinkChannelUserUpdateExpiredTime(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/update/expire',
    data: {
      channel_id: data.channel_id,
      user_id: data.user_id,
      days: data.days,
    },
  });
}
// 管理的频道 新用户申请 列表
export function getLinkChannelUserApplyList(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/user/apply/list',
    params: {
      handle: params.handle,
      auditor_id: params.auditor_id,
      channel_id: params.channel_id,
      current: params.current || 1,
      size: params.size || 10,
    },
  });
}
// 管理的频道 新用户申请 列表
export function getLinkChannelAuditorList(params) {
  return request({
    method: 'get',
    url: '/v2/link/channel/auditor/list',
    params: {
      channel_id: params.channel_id,
      current: params.current || 1,
      size: params.size || 10,
    },
  });
}
// 管理的频道 新用户申请 频道用户同意
export function getLinkChannelUserApplyApprove(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/apply/approve',
    data: {
      id: data.id,
      days: data.days,
    },
  });
}
// 管理的频道 新用户申请 拒绝
export function getLinkChannelUserApplyReject(data) {
  return request({
    method: 'post',
    url: '/v2/link/channel/user/apply/reject',
    data: {
      id: data.id,
    },
  });
}
