// @/apis/media.js
import {useUserStore} from '@/stores/userStore';
import request from '@/utils/request.js';
import {getCurrentFormattedTime} from '@/utils/utils';

// eslint-disable-next-line no-undef
const isProduction = process.env.NODE_ENV === 'production';

// 获取资源列表
export function getResourceList(params) {
  return request({
    method: 'get',
    url: '/v2/link/resource/list',
    params,
  });
}
// 查看当前用户的逻辑空间
export function getResourceLogicStorage(params) {
  return request({
    method: 'get',
    url: '/v2/link/resource/logic_storage',
    params,
  });
}
// 检查剩余空间，检查用于当前的逻辑容量是否足够上传该资源
export function getResourceSizeCheck(data) {
  return request({
    method: 'post',
    url: '/v2/link/resource/size_check',
    data: {
      size: data.size,
      replica_count: data.replica_count,
    },
  });
}
// 添加资源
// 文件上传后向系统添加资源
// 添加一个切片中的同步任务
export function getResourceCreate(data) {
  return request({
    method: 'post',
    url: '/v2/link/resource/create',
    data: {
      resource_id: data.id, // 检查空间时返回的资源id
      type: data.type, // 类型
      title: data.title, // 标题
      cover_url: data.cover_url, // 封面地址
      summary: data.summary, // 简介
      size: data.size, // 文件大小
      replica_count: data.replica_count, // 副本数量
      fac_mv_id: data.fac_mv_id, // 媒资id，影片时从媒资库匹配出的mv_id
      year: data.year,
      cover_url_height: data.cover_url_height,
      cover_url_weight: data.cover_url_width,
      cover_url_width: data.cover_url_width,
    },
  });
}

// 根据文件路径匹配影片
export function getMvsMvUrl(params, controller) {
  return request({
    method: 'get',
    url: '/mvs/mv/url',
    params: {url: params.url},
    signal: controller?.signal,
  });
}

// 根据关键词匹配影片
export function getMvsMvKeyword(params, controller) {
  return request({
    method: 'get',
    url: '/mvs/mv/keyword',
    params: {keyword: params.keyword, current: params.current || 1, size: params.size || 25},
    signal: controller?.signal,
  });
}
// 根据文件名称 检索
export function getMvsMvFilepath(params, controller) {
  return request({
    method: 'get',
    url: '/mvs/mv/filepath',
    params: {filepath: params.filepath},
    signal: controller?.signal,
  });
}

// 获取设备 X-Device
export function getAuthXDevice(data, controller = new AbortController()) {
  return request({
    method: 'post',
    url: '/v2/link/device/x_device',
    data: {device_uid: data.device_uid},
    signal: controller.signal,
  });
}
// 获取同 IP 下设备
export function getDeviceSameWanIp(params, controller = new AbortController()) {
  return request({
    method: 'get',
    url: '/v2/link/device/same_wan_ip',
    signal: controller.signal,
  });
}

// 上传 开始上传
export function startUploadTask(deviceId, data, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  // console.log('[startUploadTask lan_ip data]', data);

  if (isProduction) {
    baseURL = 'http://' + data.lanIp;
  }

  return request({
    method: 'post',
    url: baseURL + '/fast/v1/mzone/upload/start',
    headers: {
      'Content-Type': 'application/json',
      'x-device': data.xDevice,
    },
    data: {
      name: data.name,
      type: data.type, // 1,
      replica_count: data.replica_count, //  selectedPoster.value.ycb_mv_id,
      resource_id: data.resource_id, //  selectedPoster.value.poster, //'http://example.com/cover.jpg',
      total_size: data.total_size, // Calculate the total size of files to upload
    },
    signal: controller.signal,
  });
}

// 上传 正式开始上传前的post请求
export function uploadFileUsingPOST(deviceId, params, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + params.lanIp;
  }

  return request({
    method: 'post',
    url: baseURL + '/fast/v1/mzone/upload/tus',
    headers: {
      'Content-Type': 'application/json',
      'x-device': params.xDevice,
    },
    params: {
      tid: params.tid,
      path: params.path,
    },
    signal: controller.signal,
  });
}
// 上传 head
export function checkUploadUsingHEAD(deviceId, params, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + params.lanIp;
  }

  return request({
    method: 'head',
    url: baseURL + '/fast/v1/mzone/upload/tus',
    headers: {
      'Content-Type': 'application/json',
      'x-device': params.xDevice,
    },
    params: {
      tid: params.tid,
      path: params.path,
    },
    signal: controller.signal,
  });
}
export function continueUploadUsingPATCH(
  deviceId,
  chunk,
  params,
  onProgress,
  controller = new AbortController(),
) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + params.lanIp;
  }
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    // 此处需要修改。。。 params.path
    xhr.open(
      'PATCH',
      baseURL +
        `/fast/v1/mzone/upload/tus?path=${encodeURIComponent(params.path)}&tid=${params.tid}`,
      true,
    );

    const userStore = useUserStore();
    const loginState = userStore.loginState;

    xhr.setRequestHeader('Content-Type', 'application/offset+octet-stream');
    xhr.setRequestHeader('Upload-Offset', params.uploadOffset);
    xhr.setRequestHeader('x-device', params.xDevice);
    xhr.setRequestHeader('Authorization', `${loginState.token_type} ${loginState.access_token}`);

    xhr.upload.onprogress = onProgress;

    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status >= 200 && xhr.status < 300) {
          const response = JSON.parse(xhr.responseText);
          if (response.status === 200) {
            resolve(response);
            console.log(
              getCurrentFormattedTime(),
              '[continueUploadUsingPATCH ok response]',
              params,
              [response],
            );
          } else {
            console.error(
              getCurrentFormattedTime(),
              '[continueUploadUsingPATCH error response]',
              params,
              [response],
            );
            reject(response);
          }
        } else {
          let errorResponse;
          try {
            errorResponse = JSON.parse(xhr.responseText);
          } catch (e) {
            errorResponse = {message: 'Unknown error occurred'};
          }
          console.error(
            getCurrentFormattedTime(),
            `[continueUploadUsingPATCH error ${xhr.status}]`,
            params,
            loginState,
            [errorResponse],
          );
          const err = new Error(
            `Upload failed with status ${xhr.status}: ${errorResponse && errorResponse?.message}`,
          );
          err.name = 'AbortError';
          reject(err);
        }
      }
    };

    xhr.onerror = () => reject(new Error('Network error'));

    xhr.onabort = () => {
      const err = new Error('Upload aborted');
      err.name = 'AbortError';
      reject(err);
    };

    controller.abort = () => xhr.abort(); // 暂停功能

    xhr.send(chunk);
  });
}

// 上传 创建文件夹
export function createFolder(deviceId, params, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + params.lanIp;
  }
  // const directoryPath = params.path.split('/').slice(0, -1).join('/');
  return request({
    method: 'get',
    url: baseURL + '/fast/v1/mzone/upload/mkdir',
    headers: {
      'Content-Type': 'application/json',
      'x-device': params.xDevice,
    },
    params: {
      tid: params.tid,
      path: params.path,
    },
    signal: controller.signal,
  });
}
// 上传 结束上传
export function endUploadTask(deviceId, data, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + data.lanIp;
  }
  return request({
    method: 'get',
    url: baseURL + '/fast/v1/mzone/upload/end',
    headers: {
      'Content-Type': 'application/json',
      'x-device': data.xDevice,
    },
    params: {
      tid: data.tid,
    },
    signal: controller.signal,
  });
}
// 资源列表 查看存在的频道
export function getResourceExists(params, data, controller = new AbortController()) {
  return request({
    method: 'get',
    url: '/v2/link/resource/exists',
    params: {
      id: params.id,
    },
    signal: controller.signal,
  });
}

// 资源列表 发布资源
export function postResourcePost(data, controller = new AbortController()) {
  return request({
    method: 'post',
    url: '/v2/link/resource/post',
    data: {
      id: data.id,
      replica_count: data.replica_count,
      channel_ids: data.channel_ids,
    },
    signal: controller.signal,
  });
}

// 查询正在执行的task (该接口已经废弃)
export function getResourceRunningTask(deviceId, data, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + data.lanIp;
  }
  return request({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-device': data.xDevice,
    },
    url: baseURL + '/fast/v1/mzone/uploaddir/query/runningtask',
    signal: controller.signal,
  });
}
export function getResourceRunningTaskInfo(deviceId, data, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + data.lanIp;
  }
  return request({
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      'x-device': data.xDevice,
    },
    url: baseURL + '/fast/v1/mzone/uploaddir/query/runningtaskinfo',
    signal: controller.signal,
  });
}

// 删除正在执行的task
export function getResourceRemoveRunningTask(deviceId, data, controller = new AbortController()) {
  let baseURL = `/v1/proxy/${deviceId}`;

  if (isProduction) {
    baseURL = 'http://' + data.lanIp;
  }
  return request({
    method: 'get',
    url: baseURL + '/fast/v1/mzone/uploaddir/remove/runningtask',
    headers: {
      'Content-Type': 'application/json',
      'x-device': data.xDevice,
    },
    params: {
      tid: data.tid,
    },
    signal: controller.signal,
  });
}

// 资源列表 移除资源
export function postResourceRemove(data, controller = new AbortController()) {
  return request({
    method: 'post',
    url: '/v2/link/resource/remove',
    data: {
      id: data.id,
    },
    signal: controller.signal,
  });
}

// 媒资管理/素材库
export function getLinkMaterialList(params) {
  return request({
    method: 'get',
    url: '/v2/link/material/list',
    params: {
      current: params.current,
      size: params.size,
      sort: params.sort,
      order: params.order,
      query: params.query,
    },
  });
}

// 媒资管理/素材库/编辑
export function getLinkMaterialDetail(params) {
  return request({
    method: 'get',
    url: '/v2/link/material/detail',
    params: {id: params.id},
  });
}

// 媒资管理/素材库/编辑/发布
export function postLinkMaterial(data) {
  return request({
    method: 'post',
    url: '/v2/link/material/post',
    data: {
      cover_url_width: data.cover_url_width,
      cover_url_weight: data.cover_url_width,
      cover_url_height: data.cover_url_height,
      id: data.id,
      type: data.type,
      title: data.title,
      summary: data.summary,
      cover_url: data.cover_url,
      image_large_url: data.image_large_url || [],
      fac_mv_id: data.fac_mv_id,
      year: data.year,
    },
  });
}

// 媒资管理/素材库/删除
export function postLinkMaterialRemove(data) {
  return request({
    method: 'post',
    url: '/v2/link/material/remove',
    data: {id: data.id},
  });
}
