import {h} from 'vue';
import {Modal, Button} from 'ant-design-vue';

// 登录 文件或者文件夹上传
export default function useSelectFileOrFolder() {
  const showConfirm = (types) => {
    return new Promise((resolve, reject) => {
      Modal.confirm({
        title: '请选择上传类型',
        content: () =>
          h('div', {class: 'select-role'}, [
            ...types.map((type) =>
              h(
                Button,
                {
                  type: 'default',
                  style: {margin: '5px', width: '162px'},
                  onClick: () => {
                    Modal.destroyAll();
                    resolve(type.value);
                  },
                },
                {default: () => type.label},
              ),
            ),
          ]),
        okButtonProps: {style: {display: 'none'}},
        // cancelButtonProps: {style: {display: 'none'}},
        onCancel() {
          reject(new Error('操作取消'));
        },
      });
    });
  };

  return {showConfirm};
}
