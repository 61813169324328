import {ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {getUserProfile, updateUserProfile} from '@/apis/users';
import {message} from 'ant-design-vue';

export default function useNickName(loading) {
  const userStore = useUserStore();
  const {userInfo} = storeToRefs(userStore);
  const updateNickNameVisible = ref(false);
  const updateNicknameRef = ref();
  const nickNameForm = ref({nickname: undefined});

  function handleOpenUpdateNick() {
    updateNickNameVisible.value = true;
    nickNameForm.value = {nickname: userInfo.value.nickname};
  }

  function handlePassNickNameCancel() {
    updateNickNameVisible.value = false;
  }
  function handleNickNameOk() {
    updateNicknameRef.value.validate().then(async () => {
      loading.value = true;
      await updateUserProfile({
        nickname: nickNameForm.value.nickname,
        verify: userInfo.value.verify,
      })
        .then((response) => {
          if (response.data.status === 200) {
            message.success('修改昵称成功');
            updateNickNameVisible.value = false;
          } else {
            throw response;
          }
        })
        .catch((error) => {
          message.error(error?.data.message || '修改昵称失败');
        })
        .finally(() => {
          loading.value = false;
        });

      const {
        data: {data: profileData},
      } = await getUserProfile();

      userStore.setUserInfo(profileData);
    });
  }
  return {
    updateNickNameVisible,
    updateNicknameRef,
    nickNameForm,
    handleOpenUpdateNick,
    handlePassNickNameCancel,
    handleNickNameOk,
  };
}
