<template>
  <a-modal
    v-model:open="dialogVisible"
    :title="`选择${item.device_uid}（${item.name}）需要格式化的硬盘`"
    :mask-closable="false"
    :closable="!loading"
    width="640px"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div style="margin: 40px 0px">
        <p style="color: #f00">
          ! 警告：格式化硬盘将永久删除所有数据，请务必提前备份重要文件。确认格式化？
        </p>
        <a-form ref="formRef" :model="formState" :rules="rules" autocomplete="off">
          <a-form-item
            label="格式化的硬盘"
            :label-col="{style: {lineHeight: '1em!important'}}"
            name="disk_position"
          >
            <a-radio-group v-model:value="formState.disk_position" style="margin-top: 6px">
              <div v-for="hdd in item.hdd_info" :key="hdd.position">
                <a-radio :disabled="hdd.format" :value="hdd.position">
                  盘位{{ hdd.position }}: {{ hdd.brand }} {{ hdd.uuid }}
                </a-radio>
              </div>
            </a-radio-group>
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button
        type="primary"
        :loading="loading"
        @click="handleOk"
        :disabled="!formState.disk_position || loading"
      >
        确认
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {postDeviceFormat} from '@/apis/device';
import {notification} from 'ant-design-vue';
import {computed, nextTick, ref, watch} from 'vue';
import useDeviceCheckUnique from '@/composable/useDeviceCheckUnique';

const props = defineProps({
  visible: {type: Boolean, default: false, required: false},
  item: {type: Object, default: () => ({}), required: true},
});

const emits = defineEmits(['update:visible', 'success']);
const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const formRef = ref();

const loading = ref(false);
const formState = ref({
  disk_position: undefined,
});

const rules = {
  disk_position: [{required: true, message: '请选择一个硬盘进行格式化', trigger: 'change'}],
};

watch(
  () => props.visible,
  async (newVal) => {
    if (newVal) {
      formState.value = {
        disk_position: undefined,
      };
      formRef.value?.clearValidate();
    } else {
      await nextTick();
      formRef.value?.clearValidate();
    }
  },
);

const handleOk = async () => {
  formRef.value
    .validate()
    .then(async () => {
      try {
        loading.value = true;
        await useDeviceCheckUnique(props.item);
        const response = await postDeviceFormat({
          disk_position: formState.value.disk_position,
          device_uid: props.item.device_uid,
        });
        if (response.data.status !== 200) {
          throw response;
        }

        if (response.data.status === 200) {
          notification.success({
            message: `设备${props.item.device_uid}（${props.item.name}） - 盘符${formState.value.disk_position}格式化执行成功。`,
            description: '后台进行中的任务，进度完成。',
            duration: 15,
          });
        } else {
          throw response;
        }

        emits('update:visible', false);
        emits('success', {...formState.value, ...response.data.data});
      } catch (error) {
        console.error('[error]', [error]);

        notification.error({
          message: `设备${props.item.device_uid}（${props.item.name}） - 盘符${formState.value.disk_position}格式化执行失败。`,
          description: error?.data?.message || '',
          duration: 15,
        });
      } finally {
        loading.value = false;
      }
    })
    .catch((error) => {
      console.error([error]);
    });
};

const handleCancel = () => {
  emits('update:visible', false);
};
</script>
