import CryptoJS from 'crypto-js';

const PASSWORD_FIELD_NAME = 'ac13d997-377d-4c17-a55c-2eb9c41682b4'; // 随机生成的字符串，作为密码字段名称

// 生成或获取密钥
function getKey() {
  // 尝试从localStorage中获取已存储的密钥
  let storedKey = localStorage.getItem('encryptionKey');
  if (storedKey) {
    // 如果存在已存储的密钥，将其导入为CryptoJS的密钥
    return CryptoJS.enc.Hex.parse(storedKey);
  } else {
    // 如果不存在已存储的密钥，生成一个新的密钥
    const key = CryptoJS.lib.WordArray.random(32); // 256位密钥
    localStorage.setItem('encryptionKey', key.toString(CryptoJS.enc.Hex));
    return key;
  }
}

// 加密数据
function encryptData(data, key) {
  const iv = CryptoJS.lib.WordArray.random(16); // 128位IV
  const encrypted = CryptoJS.AES.encrypt(data, key, {iv: iv});

  return {
    iv: iv.toString(CryptoJS.enc.Hex), // 将IV转换为字符串存储
    data: encrypted.toString(), // 将加密数据转换为字符串存储
  };
}

// 解密数据
function decryptData(encryptedData, key) {
  const {iv, data} = encryptedData;
  const ivHex = CryptoJS.enc.Hex.parse(iv);
  const decrypted = CryptoJS.AES.decrypt(data, key, {iv: ivHex});

  return decrypted.toString(CryptoJS.enc.Utf8); // 解码并返回解密后的数据
}

// 保存用户数据
export async function saveUserData(user) {
  const encryptionKey = getKey(); // 获取加密密钥

  // 加密用户密码
  const encryptedPassword = encryptData(user.password, encryptionKey);

  // 将用户名和加密后的密码存储在localStorage中
  localStorage.setItem('username', user.username);

  if (user.remember) {
    localStorage.setItem(PASSWORD_FIELD_NAME, JSON.stringify(encryptedPassword));
    localStorage.setItem('remember', 'true');
  } else {
    localStorage.removeItem(PASSWORD_FIELD_NAME);
    localStorage.removeItem('remember');
  }
}

// 清除用户数据
export function clearUserData() {
  // localStorage.removeItem('username');
  localStorage.removeItem(PASSWORD_FIELD_NAME);
  localStorage.removeItem('remember');
}

// 加载用户数据
export async function loadUserData() {
  let password;
  try {
    const encryptionKey = getKey(); // 获取加密密钥

    // console.log('encryptionKey', encryptionKey);

    // 从localStorage中获取并解密用户密码
    const encryptedPassword = JSON.parse(localStorage.getItem(PASSWORD_FIELD_NAME));
    // console.log('encryptedPassword', encryptedPassword);
    password = encryptedPassword && decryptData(encryptedPassword, encryptionKey);
  } catch (error) {
    // not error
  }

  // 返回用户数据
  return {
    username: localStorage.getItem('username'),
    password: password,
    remember: localStorage.getItem('remember') === 'true',
  };
}
