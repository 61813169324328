import 'ant-design-vue/dist/reset.css';
import '@/assets/main.scss';
import Antd from 'ant-design-vue';
import {createApp} from 'vue';
import {createPinia} from 'pinia';

import App from '@/App.vue';
import router from '@/router';
import 'cropperjs/dist/cropper.css';

const app = createApp(App);
app.use(Antd);
app.use(createPinia());
app.use(router);

app.mount('#app');

// window.addEventListener('beforeunload', function (e) {
//   // 取消事件的默认动作（如果有的话）
//   e.preventDefault();
//   // 设置 returnValue 属性的值会改变提示信息（大多数浏览器不会使用自定义文本，而是显示默认提示）
//   e.returnValue = '';
// });
