<template>
  <a-modal
    :open="visible"
    title="新建设备子账户"
    :mask-closable="false"
    :closable="!loading"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div style="margin: 40px 0px">
        <!--  -->
        <a-form
          ref="formRef"
          :model="form"
          :label-col="{span: 6}"
          :wrapper-col="{span: 14}"
          autocomplete="off"
        >
          <a-form-item
            :rules="[
              {required: true, message: '请输入用户名'},
              {min: 2, max: 32, message: '用户名长度必须在2到32个字符之间'},
            ]"
            label="用户名"
            name="username"
          >
            <a-input v-model:value="form.username" placeholder="请输入用户名" allowClear></a-input>
          </a-form-item>
          <a-form-item
            label="昵称"
            name="nickname"
            :rules="[{required: false, message: '请输入昵称'}]"
          >
            <a-input v-model:value="form.nickname" placeholder="请输入昵称" allowClear></a-input>
          </a-form-item>
          <a-form-item
            label="运维设备"
            name="device_uid"
            :rules="[{required: false, message: '请选择'}]"
          >
            <a-select
              v-model:value="form.device_uid"
              placeholder="请选择"
              mode="multiple"
              allowClear
            >
              <a-select-option
                v-for="device in deviceOptions"
                :value="device.device_uid"
                :key="device.device_uid"
                >{{ device.name }}</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="备注" name="mark">
            <a-textarea v-model:value="form.mark" placeholder="请输入至少五个字符" :rows="4" />
          </a-form-item>
        </a-form>
      </div>
    </a-spin>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk" :disabled="loading"
        >确认</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import {getLinkRamDeviceDropdown, postLinkRamCreate} from '@/apis/config';
// import {generatePassword} from '@/utils/utils';
import {message} from 'ant-design-vue';
import {nextTick, ref, watch} from 'vue';

const props = defineProps({
  visible: {type: Boolean, default: false, required: false},
  inputData: {type: Object, default: () => ({}), required: false},
});

const emits = defineEmits(['update:visible', 'success']);

const formRef = ref();

const deviceOptions = ref([]);

const loading = ref(false);
const form = ref({
  username: undefined,
  nickname: undefined,
  device_uid: [],
  mark: undefined,
});
watch(
  () => props.visible,
  async (newVal) => {
    if (newVal) {
      loading.value = true;
      try {
        const response = await getLinkRamDeviceDropdown({current: 1, size: 100});
        if (response.data.status === 200) {
          deviceOptions.value = response.data.data.records;
        } else {
          throw response;
        }
      } catch (error) {
        deviceOptions.value = [];
        console.error(error);
      } finally {
        loading.value = false;
      }

      form.value = {
        username: undefined,
        nickname: undefined,
        device_uid: [],
        mark: undefined,
        ...props.inputData,
      }; // 每次打开时生成新密码
      formRef.value?.clearValidate();
    } else {
      await nextTick();
      formRef.value?.clearValidate();
    }
  },
);
const handleOk = async () => {
  formRef.value
    .validate()
    .then(async () => {
      try {
        loading.value = true;

        console.log('form', form.value);

        const response = await postLinkRamCreate({
          username: form.value.username,
          nickname: form.value.nickname,
          device_uid: form.value.device_uid,
          mark: form.value.mark,
        });

        if (response.data.status !== 200) {
          throw response;
        }

        emits('update:visible', false);
        emits('success', {...form.value, ...response.data.data});
        message.success(props.inputData?.isEdit ? '更新成功' : '添加成功');
      } catch (error) {
        console.error('[error]', [error]);
        console.log('error?.data?.message', error?.data?.message);
        const emsg = error?.data?.message;

        message.error(emsg || (props.inputData?.isEdit ? '更新成功' : '添加成功'));
      } finally {
        loading.value = false;
      }
    })
    .catch((error) => {
      console.error([error]);
    });
};

const handleCancel = () => {
  emits('update:visible', false);
};
</script>
