import {onMounted, ref} from 'vue';
import {getLinkAuditBlockChannelResource} from '@/apis/review.js';
import usePagination from '@/composable/usePagination';

export default function useTableBlockResource(pageLoading) {
  const {pagination} = usePagination(getList);

  const query = ref({
    query: '', // 频道名称 / 频道id
    status: [], // 状态
    sort: undefined, // create_time
    order: undefined, // desc | asc
  });

  const dataSource = ref([]);

  function fetchData() {
    if (pageLoading.value) {
      return;
    }
    pagination.value.current = 1;
    getList();
  }

  function getList() {
    if (pageLoading.value) {
      return;
    }
    pageLoading.value = true;
    getLinkAuditBlockChannelResource({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      status: query.value.status.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      query: query.value.query?.trim() || undefined,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          dataSource.value = response?.data?.data?.records || [];
          pagination.value = {
            ...pagination.value,
            total: response?.data?.data?.total || 0,
          };
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.error('[err]', err);
      })
      .finally(() => {
        pageLoading.value = false;
      });
  }

  onMounted(() => {
    getList();
  });

  return {getList, fetchData, pagination, dataSource, query};
}
