<template>
  <div>
    <div v-if="showPageContent" class="page-content">
      <DeviceList />
    </div>
    <RouterView></RouterView>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';
import {useRoute} from 'vue-router';
import DeviceList from './DeviceList.vue';
import * as RouteNames from '@/router/names.js';

const route = useRoute();
const showPageContent = ref(true);

const checkRoute = () => {
  showPageContent.value = route.name === RouteNames.DeviceListView;
};

// Initial check
checkRoute();

// Watch for route changes
watch(
  () => route.path,
  () => {
    checkRoute();
  },
);
</script>
