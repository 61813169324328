<template>
  <div class="sync-task-wrapper">
    <div class="page-content">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <div v-if="column.key === 'channel'" style="white-space: nowrap">
            <span class="channel-type" v-if="getChannelType(record)">{{
              getChannelType(record)
            }}</span>
            <span v-if="record.id > -1">（{{ record.id }}）</span>
            <span style="white-space: normal;">{{ record.name }}</span>
          </div>
          <template v-if="column.key === 'master_nickname'">
            <div style="min-width: 120px">{{ record.master_nickname }}</div>
          </template>
          <template v-if="column.key === 'status'">
            {{ getTextByValue(record.status) }}
          </template>

          <template v-if="column.key === 'create_time'">
            {{ dayjs(record.create_time).format('YYYY-MM-DD HH:mm') }}
          </template>

          <template v-if="column.key === 'action'">
            <a-space v-if="record.status !== 'closed'" style="white-space: nowrap">
              <RouterLink :to="{name: ContentManagementView, query: {id: record.id}}">
                内容管理
              </RouterLink>
              <RouterLink :to="{name: MemberManagementView, query: {id: record.id}}">
                成员管理
              </RouterLink>
            </a-space>
            <template v-else>-</template>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed} from 'vue';
import dayjs from 'dayjs';
import {getLinkChannelListManage} from '@/apis/channel';
import usePagination from '@/composable/usePagination';
import {getChannelType, channelStatuses, getTextByValue} from '../utils/index.js';

import {ContentManagementView, MemberManagementView} from '@/router/names';

const {pagination} = usePagination(getList);

const loading = ref(false);

const query = ref({
  status: [], //
  sort: undefined, // create_time
  order_joined_count: undefined, // desc | asc
  order_resource_count: undefined, // desc | asc
});

const columns = computed(() => [
  {
    title: '频道',
    key: 'channel',
    dataIndex: 'channel',
    fixed: 'left',
  },
  {
    title: '频道主',
    key: 'master_nickname',
    dataIndex: 'master_nickname',
    align: 'center',
  },
  {
    title: '频道状态',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    filters: [...channelStatuses],
    filterMultiple: false,
    filteredValue: query.value.status,
    onFilter: (value, record) => record.status.includes(value),
  },

  {
    title: '加入频道人数',
    key: 'joined_count',
    dataIndex: 'joined_count',
    align: 'center',
    sortOrder: query.value.order_joined_count,
    sorter: (a, b) => a.joined_count - b.joined_count,
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: '频道资源数',
    key: 'resource_count',
    dataIndex: 'resource_count',
    align: 'center',
    sortOrder: query.value.order_resource_count,
    sorter: (a, b) => a.resource_count - b.resource_count,
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: '申请时间',
    key: 'create_time',
    dataIndex: 'create_time',
    align: 'center',
    width: 200,
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
]);

const data = ref([]);

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;
    const response = await getLinkChannelListManage({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // status: query.value.status,
      status: query.value?.status.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data?.total || 0,
    };

    // const records = response.data?.data?.records || [];
    const records = response.data?.data?.records || [];

    data.value = records;
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.status = filters.status || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;

    if (sorter.field === 'joined_count') {
      query.value.order_joined_count = sorter.order;
      query.value.order_resource_count = undefined;
    } else {
      query.value.order_joined_count = undefined;
      query.value.order_resource_count = sorter.order;
    }
    fetchData();
  }
};

onMounted(() => {
  fetchData();
});

onUnmounted(() => {});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
}
.channel-type {
  background-color: #555;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
}
</style>
