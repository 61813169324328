<template>
  <div>
    <a-spin :spinning="loading">
      <div class="disk-container">
        <h1>磁盘总空间：{{ bytes(usedSpace) }}/{{ bytes(totalSpace) }}</h1>
        <h1>已使用：</h1>
        <div class="progress-bar">
          <div
            class="progress"
            :style="{flex: systemPercent + ' 1 0%', backgroundColor: '#f44336'}"
          ></div>
          <div
            class="progress"
            :style="{flex: moviesPercent + ' 1 0%', backgroundColor: '#ff9800'}"
          ></div>
          <div
            class="progress"
            :style="{flex: seriesPercent + ' 1 0%', backgroundColor: '#4caf50'}"
          ></div>
          <div
            class="progress"
            :style="{flex: othersPercent + ' 1 0%', backgroundColor: '#9e9e9e'}"
          ></div>
          <div
            class="progress remaining"
            :style="{flex: remainingPercent + ' 1 0%', backgroundColor: '#b0bec5'}"
          >
            {{ bytes(remainingSpace) }}
          </div>
        </div>
        <div class="labels">
          <div class="label system">
            <span></span>
            系统 ({{ bytes(systemUsage) }})
          </div>
          <div class="label movies">
            <span></span>
            电影 ({{ bytes(moviesUsage) }})
          </div>
          <div class="label series">
            <span></span>
            电视剧 ({{ bytes(seriesUsage) }})
          </div>
          <div class="label others">
            <span></span>
            其他 ({{ bytes(othersUsage) }})
          </div>
          <div class="label remaining">
            <span></span>
            剩余 ({{ bytes(remainingSpace) }})
          </div>
        </div>
      </div>

      <div class="add-resource">
        <a-button
          :disabled="userInfo && userInfo.role === SUPER_ADMIN"
          type="primary"
          size="large"
          style="width: 258px"
          @click="handleAddResource"
          >导入资源</a-button
        >
      </div>
      <AddResourceModal
        v-model:visible="modalOpenAdd"
        :allItems="allItems"
        @success="loadData"
        @clearFolder="onClearFolder"
        @clickFolderInput="clickFolderInput"
        @changeDropFile="handleChangeDropFile"
      />
    </a-spin>

    <input
      type="file"
      ref="folderInput"
      id="folderInput"
      :key="fileInputKey + 1000"
      @change="handleFileInputChange($event, 'folderInput')"
      multiple
      webkitdirectory
      style="display: none"
    />
    <input
      type="file"
      ref="fileInput"
      id="fileInput"
      :key="fileInputKey"
      @change="handleFileInputChange($event, 'fileInput')"
      multiple
      style="display: none"
    />
  </div>
</template>

<script setup>
import {ref, computed, onMounted} from 'vue';
import bytes from 'bytes';
import AddResourceModal from './AddResourceModal.vue';
import {getResourceLogicStorage} from '@/apis/media';
import {organizeFiles} from './utils/index';
import {getNextId} from '@/utils/utils';
import {SUPER_ADMIN} from '@/router/roles';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);

const loading = ref(false);
const modalOpenAdd = ref(false);

const totalSpace = ref(0); // 总空间，单位为 Byte
const systemUsage = ref(0); // 系统使用量，单位为 Byte
const moviesUsage = ref(0); // 电影使用量，单位为 Byte
const seriesUsage = ref(0); // 电视剧使用量，单位为 Byte
const othersUsage = ref(0); // 其他使用量，单位为 Byte
const usedSpace = ref(0); // 已经使用

const remainingSpace = computed(() => totalSpace.value - usedSpace.value); // 剩余空间，单位为 Byte

const systemPercent = computed(() => (systemUsage.value / totalSpace.value) * 100);
const moviesPercent = computed(() => (moviesUsage.value / totalSpace.value) * 100);
const seriesPercent = computed(() => (seriesUsage.value / totalSpace.value) * 100);
const othersPercent = computed(() => (othersUsage.value / totalSpace.value) * 100);
const remainingPercent = computed(() => (remainingSpace.value / totalSpace.value) * 100);

function handleAddResource() {
  modalOpenAdd.value = true;

  onClearFolder();
}

async function loadData() {
  try {
    loading.value = true;
    const response = await getResourceLogicStorage();

    // response.data 数据如下：
    // {"status":200,"message":"操作成功","data":{"total":1921725720,"used":192183836,"infos":[{"type":"system","used":192172572},{"type":"other","used":3072},{"type":"movie","used":8192},{"type":"series","used":4096}]}}
    // system-系统；other-其他；movie-电影；series-电视剧

    if (response.data.status === 200) {
      const storage = response.data.data;
      totalSpace.value = storage.total;
      usedSpace.value = storage.used;

      storage.infos.forEach((info) => {
        switch (info.type) {
          case 'system':
            systemUsage.value = info.used;
            break;
          case 'movie':
            moviesUsage.value = info.used;
            break;
          case 'series':
            seriesUsage.value = info.used;
            break;
          case 'other':
            othersUsage.value = info.used;
            break;
          // 如果有其他类型可以继续添加case
        }
      });
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  // console.log('[onMounted AddResourceView.vue]');
  loadData();
});

const fileInput = ref();
const folderInput = ref();

const allItems = ref([]);

const fileInputKey = ref(parseInt(Math.random() * 1000));

const onClearFolder = () => {
  allItems.value = [];
  fileInput.value.value = '';
  folderInput.value.value = '';

  fileInputKey.value += 1;
};

const clickFolderInput = (type) => {
  if (type === 'folderInput') {
    folderInput.value.click();
  } else {
    fileInput.value.click();
  }
};

const handleChangeDropFile = (list) => {
  allItems.value = list;
};
const handleFileInputChange = (event, type) => {
  const files = Array.from(event.target.files);
  // console.log('files', files);

  let list;

  if (type === 'folderInput') {
    list = organizeFiles(files);
  } else {
    list = files.map((item) => ({
      type: 'file',
      nextId: getNextId(),
      name: item.name,
      path: '',
      file: item,
    }));
  }

  allItems.value = list;
};
</script>

<style scoped>
.disk-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
  font-family: Arial, sans-serif;
  .disk-info {
    font-size: 24px;
    margin-bottom: 10px;
  }
  .progress-bar {
    display: flex;
    width: 100%;
    height: 30px;
    line-height: 30px;
    background-color: #ddd;
    border-radius: 5px;
    overflow: hidden;
  }
  .progress {
    height: 100%;
  }
  .labels {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 5px;
  }
  .label {
    display: flex;
    align-items: center;
  }
  .label span {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    display: inline-block;
  }
  .label.system span {
    background-color: #f44336;
  }
  .label.movies span {
    background-color: #ff9800;
  }
  .label.series span {
    background-color: #4caf50;
  }
  .label.others span {
    background-color: #9e9e9e;
  }
  .label.remaining span {
    background-color: #b0bec5;
  }
}
.add-resource {
  display: flex;
  justify-content: center;
  margin-top: 96px;
}
</style>
