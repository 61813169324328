<template>
  <a-layout class="main-layout-wrapper" :class="{'is-collapsed': collapsed}">
    <a-drawer
      v-if="isMobileDevice"
      width="var(--sider-width)"
      rootClassName="drawer-wrapper"
      theme="dark"
      placement="left"
      :closable="false"
      :open="!collapsed"
      @close="collapsed = !collapsed"
    >
      <Navbar
        v-model:openKeys="openKeys"
        v-model:selectedKeys="selectedKeys"
        :menuList="menuList"
        :collapsed="collapsed"
        :onClickMenu="onClickMenu"
      />
    </a-drawer>
    <Navbar
      v-else
      v-model:openKeys="openKeys"
      v-model:selectedKeys="selectedKeys"
      :menuList="menuList"
      :collapsed="collapsed"
      :onClickMenu="onClickMenu"
    />
    <a-layout style="overflow: auto">
      <a-layout-header theme="light" class="main-header">
        <div class="collapsed-button">
          <MenuUnfoldOutlined v-if="collapsed" @click="() => (collapsed = !collapsed)" />
          <MenuFoldOutlined v-else @click="() => (collapsed = !collapsed)" />
        </div>
        <!-- <a
          style="margin-left: auto"
          class="ant-dropdown-link"
          @click.prevent="visibleUploadList = !visibleUploadList"
        >
          <a-button type="text">
            <a-badge :dot="taskList.length > 0" status="error" style="line-height: 1">
              <SwapOutlined style="color: #000; transform: rotate(90deg)" />
            </a-badge>
          </a-button>
        </a> -->
        <a-dropdown>
          <a class="ant-dropdown-link" @click.prevent style="margin-left: auto">
            {{ roleName }}<template v-if="roleName">：</template>{{ userInfo && userInfo.nickname }}
            <DownOutlined />
          </a>
          <template #overlay>
            <a-menu>
              <a-menu-item @click="onClickProfile"
                ><UserOutlined style="padding-right: 12px" />个人设置</a-menu-item
              >
              <a-menu-divider />
              <a-menu-item @click="onClickLogout"
                ><LogoutOutlined style="padding-right: 12px" />退出登录</a-menu-item
              >
            </a-menu>
          </template>
        </a-dropdown>
      </a-layout-header>
      <a-breadcrumb class="breadcrumb-wrapper" :class="{'is-empty': routePaths.length === 0}">
        <a-breadcrumb-item v-for="(item, index) in routePaths" :key="item.name">
          <template v-if="routePaths.length > 1 && item.name === RouteNames.DeviceListView">
            <!-- 基础详情 -->
            <RouterLink :to="item.path">
              {{ item?.meta?.title }}
            </RouterLink>
          </template>
          <template v-else-if="index === 0 || index === routePaths.length - 1">{{
            item?.meta?.title
          }}</template>
          <RouterLink v-else :to="item.path">
            {{ item?.meta?.title }}
          </RouterLink>
        </a-breadcrumb-item>
      </a-breadcrumb>
      <router-view class="page-container" />
    </a-layout>
  </a-layout>
  <DialogProfile title="个人设置" v-model:visible="profileVisible"></DialogProfile>
  <UploadList v-model:visible="visibleUploadList"></UploadList>
</template>
<script setup>
import {computed, nextTick, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {useRouter, useRoute, RouterLink} from 'vue-router';
import * as RouteNames from '@/router/names.js';
import {routes} from '@/router/index.js';
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons-vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {handleLogout} from '@/utils/logout';
import {getRoleName} from '@/router/roles';
import DialogProfile from '@/components/DialogProfile/index.vue';
import {Modal} from 'ant-design-vue';
import UploadList from '@/components/UploadList/index.vue';
import EventBus from '@/utils/EventBus';
import Navbar from './Navbar.vue';

const userStore = useUserStore();
const router = useRouter();
const route = useRoute();

const {userInfo, isMobileDevice} = storeToRefs(userStore);

const collapsed = ref(false);

const selectedKeys = ref([]);
const openKeys = ref([]);
const menuList = ref([]);
const menuAllList = ref([]);

const profileVisible = ref(false);

const roleName = computed(() => getRoleName(userInfo.value?.role));

const visibleUploadList = ref(false);

const filterHiddenRoutes = (routes) => {
  return routes.reduce((filtered, route) => {
    if (!route.meta.hidden) {
      const filteredChildren = route.children ? filterHiddenRoutes(route.children) : [];
      filtered.push({...route, children: filteredChildren});
    }
    return filtered;
  }, []);
};

watch(
  userInfo,
  (data) => {
    const children = routes.find((item) => item.name === RouteNames.Layout)?.children || [];

    menuList.value = filterRoutesByRole(data?.role, filterHiddenRoutes(children));

    menuAllList.value = filterRoutesByRole(data?.role, children);
  },
  {immediate: true},
);

const routePaths = ref([]);

watch(
  route,
  (value) => {
    // 根据value.name 匹配 menuList，返回一个 routes tree
    const current = findRoutePath(value.name, menuAllList.value);

    if (current.length === 1 && current[0].name === RouteNames.SettingsView) {
      // 个人设置，则不显示
      routePaths.value = [];
    } else {
      routePaths.value = current;
    }

    // 左侧菜单
    nextTick(() => {
      const lastVisitedRoute = sessionStorage.getItem('lastVisitedRoute');

      if ([RouteNames.LoginView, RouteNames.TwoFactorView].includes(lastVisitedRoute)) {
        selectedKeys.value = [RouteNames.DeviceListView];
        openKeys.value = [];
      } else if (RouteNames.DeviceDetailView.includes(lastVisitedRoute)) {
        selectedKeys.value = [RouteNames.DeviceListView];
        openKeys.value = [];
      } else {
        const arr = findParentName(lastVisitedRoute, menuAllList.value);

        if (arr.length > 1) {
          openKeys.value = arr.slice(0, 1);
          selectedKeys.value = arr.slice(arr.length - 1);
        } else {
          openKeys.value = arr;
          selectedKeys.value = [lastVisitedRoute];
        }
      }
    });
  },
  {immediate: true},
);

const onClickProfile = () => {
  // profileVisible.value = true;
  router.push({name: RouteNames.SettingsView});
};

const onClickLogout = () => {
  Modal.confirm({
    title: '退出登录',
    // icon: createVNode(ExclamationCircleOutlined),
    content: '您确定要退出登录吗',
    okText: '确认',
    cancelText: '取消',
    centered: true,
    onOk() {
      handleLogout();
    },
    onCancel() {},
  });
};

const onClickMenu = (item) => {
  router.push({name: item.key});
};

function findRoutePath(name, nodeList) {
  for (const node of nodeList) {
    if (node.name === name) {
      return [node];
    }
    if (node.children) {
      const childPath = findRoutePath(name, node.children);
      if (childPath.length > 0) {
        return [node, ...childPath];
      }
    }
  }
  return [];
}

function findParentName(name, nodeList) {
  for (const node of nodeList) {
    if (node.name === name) {
      return [];
    }
    if (Array.isArray(node.children) && node.children.length > 0) {
      const result = findParentName(name, node.children);
      if (result !== null) {
        return [node.name, ...result];
      }
    }
  }
  return null;
}

function filterRoutesByRole(role, routes) {
  if (!role) {
    return [];
  }
  // 创建一个新数组，用于存放符合条件的路由
  const filteredRoutes = [];

  // 遍历给定的路由数组
  routes.forEach((route) => {
    // 如果路由没有meta字段，或者meta.roles数组中包含给定的角色
    if (!route.meta || (route.meta && route.meta.roles.includes(role))) {
      // 深拷贝当前路由对象
      const filteredRoute = {...route};

      // 如果当前路由有子路由，递归调用filterRoutesByRole处理子路由
      if (route.children && route.children.length > 0) {
        filteredRoute.children = filterRoutesByRole(role, route.children);
      }

      // 将处理过的路由添加到filteredRoutes数组中
      filteredRoutes.push(filteredRoute);
    }
  });

  // 返回筛选后的路由数组
  return filteredRoutes;
}
function onShowUploadList(visible) {
  visibleUploadList.value = visible;
}
function onSetCollapsed(value) {
  collapsed.value = value;
}
onMounted(() => {
  EventBus.on('ShowUploadList', onShowUploadList);
  EventBus.on('SetCollapsed', onSetCollapsed);
});
onBeforeUnmount(() => {
  EventBus.off('ShowUploadList', onShowUploadList);
  EventBus.off('SetCollapsed', onSetCollapsed);
});
</script>

<style scoped lang="scss">
.main-layout-wrapper {
  display: flex;
  flex-flow: row nowrap;
  height: 100vh;
  overflow: auto;
}

.main-header {
  display: flex;
  gap: 20px;
  background-color: #fff;
  padding: 0 var(--page-padding);
  align-items: center;
  .collapsed-button {
    font-size: 20px;
  }

  .ant-dropdown-link {
    color: #000;
  }
  .ant-dropdown-link:first-child {
    margin-left: auto;
  }
}

.breadcrumb-wrapper {
  padding: 16px var(--page-padding);
  &.is-empty {
    padding: 12px 0;
  }
}

.main-layout-content {
  flex: 1;
  margin: 0px var(--page-padding) var(--page-padding) var(--page-padding);
  background-color: #f5f5f5;
  display: flex;
  flex-flow: column nowrap;
  height: inherit;
}

::v-deep(.page-container) {
  box-sizing: border-box;
  padding: var(--page-padding);
  flex: 1 1 calc(100% - var(--page-padding) - var(--page-padding));
  width: calc(100% - var(--page-padding) - var(--page-padding));
  background-color: #fff;
  margin: 0 var(--page-padding) var(--page-padding) var(--page-padding);
  &.sync-task-wrapper {
    width: calc(100% - var(--page-padding) - var(--page-padding));
  }
}

::v-deep(.page-content) {
  // 48px 为左右外边距
  // 修复table超出的问题
  width: calc(
    100vw - var(--sider-width) - var(--page-padding) - var(--page-padding) - var(--page-padding) - var(
        --page-padding
      )
  );
  // overflow: auto;
  // height: 100%;
}
</style>

<style lang="scss">
.drawer-wrapper .ant-drawer-body {
  padding: 0;
  background-color: #001529;
}
</style>
