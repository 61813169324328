<template>
  <a-modal
    width="600px"
    :open="dialogVisible"
    :maskClosable="false"
    :closable="false"
    :footer="null"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <div class="submission-page">
      <a-result
        status="success"
        title="提交成功"
        class="submission-page__result"
        style="padding: 0"
      >
        <template #extra> </template>
      </a-result>
      <div class="submission-page__resource-title">资源标题：{{ item.title }}</div>
      <div class="submission-page__resource-id">资源 ID：{{ item.id }}</div>
      <div class="submission-page__details">
        <!-- <div v-if="item.count" class="submission-page__details-section">
          <div class="submission-page__details-title">发布的详情描述：</div>
          <div class="submission-page__details-subtitle">副本数量：{{ item.count }}</div>
          <div class="submission-page__details-info">
            原本副本数量 —> 现在副本的数量。具体进度在同步任务中查看
          </div>
        </div> -->

        <div
          v-if="Array.isArray(item.publishChannel) && item.publishChannel.length > 0"
          class="submission-page__details-section"
        >
          <div class="submission-page__details-title">上架的频道：</div>
          <a-table
            style="width: inherit"
            size="small"
            bordered
            :pagination="false"
            :dataSource="item.publishChannel"
            :columns="[
              {
                title: '频道ID',
                dataIndex: 'id',
                key: 'id',
              },
              {
                title: '频道名称',
                dataIndex: 'name',
                key: 'name',
              },
            ]"
          />
        </div>
        <div
          v-if="Array.isArray(item.unpublishChannel) && item.unpublishChannel.length > 0"
          class="submission-page__details-section"
        >
          <div class="submission-page__details-title">下架的频道：</div>
          <a-table
            style="width: inherit"
            size="small"
            bordered
            :pagination="false"
            :dataSource="item.unpublishChannel"
            :columns="[
              {
                title: '频道ID',
                dataIndex: 'id',
                key: 'id',
              },
              {
                title: '频道名称',
                dataIndex: 'name',
                key: 'name',
              },
            ]"
          />
        </div>
      </div>
      <div class="submission-page__actions" style="display: flex; justify-content: center">
        <a-button type="primary" @click="handleCancel">返回媒资库</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
import {computed} from 'vue';
// import dayjs from 'dayjs';
const props = defineProps({
  visible: {type: Boolean, default: false},
  item: {type: Object, default: () => ({})},
});
const emits = defineEmits(['update:visible', 'success']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

// const current = computed(() => {
//   if (props.item.status === 'seeding') {
//     return 1;
//   }
//   return 0;
// });

const handleOk = async () => {};

const handleCancel = () => {
  dialogVisible.value = false;

  emits('success');
};
</script>

<style scoped lang="scss">
.submission-page__resource-title {
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
}

.submission-page__resource-id {
  margin-bottom: 40px;
}

::v-deep(.ant-result-title) {
  color: #000;
  font-weight: bold;
}

.submission-page__steps {
  // 步骤进度条，不显示禁止选中按钮
  ::v-deep(.ant-steps-item-disabled) {
    cursor: default;
  }
}

.submission-page__details-title {
  font-weight: bold;
  margin-bottom: 16px;
}
.submission-page__details-section {
  margin-bottom: 12px;
}
.submission-page__actions {
  padding: 20px 0;
}
</style>
