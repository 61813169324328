<template>
  <a-modal
    width="400px"
    :open="dialogVisible"
    :title="`是否要删除${item.title}${item.year ? `（${item.year}）` : ''}`"
    destroyOnClose
    :maskClosable="true"
    @ok="handleOk"
    @cancel="handleCancel"
  >
    <div class="content-removal">
      <div class="content-removal__section">
        <template v-if="options.length > 0">
          <div class="content-removal__title">该内容会从下列频道下架</div>
          <div class="content-removal__channel"><span>频道ID</span> <span>频道名称</span></div>
          <div class="content-removal__channel" v-for="item in options" :key="item.id">
            <span>id:{{ item.id }}</span>
            <span>{{ item.name }}</span>
          </div>
        </template>

        <!-- <div class="content-removal__channel"><span>id:123</span> <span>欢哥的墨鱼时刻</span></div>
        <div class="content-removal__channel"><span>id:xxx</span> <span>频道名称</span></div>
        <div class="content-removal__channel">频道5位预留， ID/名称对齐</div> -->
      </div>
      <div class="content-removal__confirmation">你还要继续吗？</div>
    </div>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk" :disabled="loading"
        >确定</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import {computed, watch, ref} from 'vue';
import {getResourceExists} from '@/apis/media';
import {postResourceRemove} from '@/apis/media';
import {message} from 'ant-design-vue';

const props = defineProps({
  visible: {type: Boolean, default: false},
  item: {type: Object, default: () => ({})},
});
const emits = defineEmits(['update:visible', 'success']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const options = ref([]);

const handleOk = async () => {
  try {
    const response = await postResourceRemove({id: props.item.id});

    if (response.data.status === 200) {
      message.success('删除成功');

      emits('success');
    } else {
      throw response;
    }

    console.log('[response]', response);
    dialogVisible.value = false;
  } catch (error) {
    console.error(error);

    message.error(error?.data?.message || '删除资源失败');
  }
};

const handleCancel = () => {
  dialogVisible.value = false;
};

watch(dialogVisible, (visible) => {
  if (visible) {
    getResourceExists({id: props.item.id})
      .then((response) => {
        if (response.data.status === 200) {
          if (!response.data.data.exists || response.data.data.channels.length === 0) {
            options.value = [];
          } else {
            options.value = response.data.data.channels;
          }
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.error(err);
        options.value = [];
      });
  } else {
    options.value = [];
  }
});
</script>

<style scoped lang="scss">
.content-removal__section {
  color: #f00;
  margin-bottom: 20px;
}
</style>
