<template>
  <div class="sync-task-wrapper">
    <div>
      <div class="page-header">
        <a-button type="primary" @click="handleNewChannel({type: 'create', record: {}})"
          >新建频道</a-button
        >
      </div>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <div v-if="column.key === 'channel'" style="white-space: nowrap">
            <span class="channel-type" v-if="getChannelType(record)">{{
              getChannelType(record)
            }}</span>
            <span v-if="record.id > -1">（{{ record.id }}）</span>
            <span style="white-space: normal;">{{ record.name }}</span>
          </div>

          <template v-if="column.key === 'master_nickname'">
            <div style="min-width: 120px">{{ record.master_nickname }}</div>
          </template>
          <template v-if="column.key === 'status'">
            {{ getTextByValue(record.status) }}
          </template>

          <template v-if="column.key === 'create_time'">
            <span style="white-space: nowrap;">{{ dayjs(record.create_time).format('YYYY-MM-DD HH:mm') }}</span>
          </template>

          <template v-if="column.key === 'action'">
            <a-space v-if="record.status !== 'closed'" style="white-space: nowrap">
              <a-typography-link type="link" class="btn-link" @click="showConfirm('edit', record)">
                编辑
              </a-typography-link>
              <a-typography-link
                type="danger"
                class="btn-link"
                @click="showConfirm('deactivate', record)"
                >注销</a-typography-link
              >
            </a-space>
            <template v-else>-</template>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
    <NewChanelModal
      v-model:visible="visibleNewChannel"
      :type="currentModalType"
      :item="currentModalItem"
      @success="fetchData"
    />
  </div>
</template>

<script setup>
import {ref, h, onMounted, onUnmounted, computed, createVNode} from 'vue';
import dayjs from 'dayjs';
import {getLinkChannelListCreate, postLinkChannelClose} from '@/apis/channel';
import usePagination from '@/composable/usePagination';
import NewChanelModal from './components/NewChanelModal.vue';
import {getChannelType, channelStatuses, getTextByValue} from './utils/index.js';

import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {message, Modal} from 'ant-design-vue';
const {pagination} = usePagination(getList);

const loading = ref(false);

const query = ref({
  status: [], //
  sort: undefined, // create_time
  order_joined_count: undefined, // desc | asc
  order_resource_count: undefined, // desc | asc
});

const currentModalType = ref('create'); // create 创建频道， edit 编辑频道
const currentModalItem = ref({});

const visibleNewChannel = ref(false);

const handleNewChannel = ({type, record}) => {
  currentModalType.value = type;
  currentModalItem.value = record;

  visibleNewChannel.value = true;
};

const columns = computed(() => [
  {
    title: '频道',
    key: 'channel',
    dataIndex: 'channel',
    fixed: 'left',
  },
  {
    title: '频道主',
    key: 'master_nickname',
    dataIndex: 'master_nickname',
    align: 'center',
  },
  {
    title: '频道状态',
    key: 'status',
    dataIndex: 'status',
    align: 'center',
    filters: [...channelStatuses],
    filterMultiple: false,
    filteredValue: query.value.status,
    onFilter: (value, record) => record.status.includes(value),
  },

  {
    title: '加入频道人数',
    key: 'joined_count',
    dataIndex: 'joined_count',
    align: 'center',
    sortOrder: query.value.order_joined_count,
    sorter: (a, b) => a.joined_count - b.joined_count,
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: '频道资源数',
    key: 'resource_count',
    dataIndex: 'resource_count',
    align: 'center',
    sortOrder: query.value.order_resource_count,
    sorter: (a, b) => a.resource_count - b.resource_count,
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: '申请时间',
    key: 'create_time',
    dataIndex: 'create_time',
    align: 'center',
    width: 200,
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 100,
    minWidth: 100,
    maxWidth: 100,
  },
]);

const data = ref([]);

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;
    const response = await getLinkChannelListCreate({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // status: query.value.status,
      status: query.value?.status.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data?.total || 0,
    };

    // const records = response.data?.data?.records || [];
    const records = response.data?.data?.records || [];

    data.value = records;
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

const showConfirm = (type, record) => {
  if (type === 'edit') {
    handleNewChannel({type, record});
  } else if (type === 'deactivate') {
    const nameText = record.name ? `（${record.name}）` : '';
    let confirmModal = null;

    confirmModal = Modal.confirm({
      title: '注销频道',
      icon: createVNode(ExclamationCircleOutlined),
      content: h('div', {}, [
        h(
          'p',
          {style: 'color: #f00'},
          `请确认是否要注销 ${record.id}${nameText}，该操作无法恢复，请谨慎确认`,
        ),
        h('p', {}, `加入频道人数：${record.joined_count}人！`),
        h('p', {}, `频道资源数：${record.resource_count}部！`),
      ]),
      okText: '确定',
      okButtonProps: {danger: true, loading: loading.value},
      centered: true,
      async onOk() {
        loading.value = true;
        confirmModal.update({
          okButtonProps: {danger: true, loading: true},
        });
        try {
          const response = await postLinkChannelClose({id: record.id});
          if (response.data.status === 200) {
            message.success('注销频道成功');
            confirmModal.destroy();
          } else {
            throw response;
          }
        } catch (err) {
          console.error(err);
          message.error('注销频道失败');
          confirmModal.update({
            okButtonProps: {danger: true, loading: false},
          });
          throw err;
        } finally {
          loading.value = false;

          fetchData();
        }
      },
      onCancel() {},
    });
  }
};

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.status = filters.status || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;

    if (sorter.field === 'joined_count') {
      query.value.order_joined_count = sorter.order;
      query.value.order_resource_count = undefined;
    } else {
      query.value.order_joined_count = undefined;
      query.value.order_resource_count = sorter.order;
    }
    fetchData();
  }
};

onMounted(() => {
  fetchData();
});

onUnmounted(() => {});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
}
.channel-type {
  background-color: #555;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
}
</style>
