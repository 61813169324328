<template>
  <div class="sync-task-wrapper">
    <div>
      <div class="page-header">
        <div>
          <span>设置操作的默认选项：</span>
          <a-select
            v-model:value="defaultAgreement"
            @change="handleChange"
            placeholder="请选择"
            style="width: 180px"
          >
            <a-select-option v-for="item in agreementOptions" :key="item.value" :value="item.value">
              {{ item.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="ant-table-striped overflow-x__auto"
        :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <div v-if="column.key === 'user_nickname'">
            <span>{{ record.user_id }}</span>
            <span>{{ record.user_nickname }}</span>
          </div>
          <div v-if="column.key === 'channel'" style="white-space: nowrap">
            <span class="channel-type" v-if="getChannelType(record) === '私密'">{{
              getChannelType(record)
            }}</span>
            <span v-if="record.channel_id > -1">（{{ record.channel_id }}）</span>
            <span style="white-space: normal">{{ record.name }}</span>
          </div>

          <template v-if="column.key === 'status'">
            {{ getTextByValue(record.status) }}
          </template>

          <template v-if="column.key === 'nickname'">
            <div v-if="column.key === 'nickname'">
              <template v-if="record.status === 'approved'">
                <span
                  v-if="!record.isEdit"
                  class="channel-nickname"
                  @click="handleClickRename(record, column)"
                  >{{ record.rename || '-'
                  }}<FormOutlined v-if="record.status === 'approved'" class="hidden-container" />
                </span>
                <a-input
                  v-else
                  :class="{'is-edit': record.isEdit}"
                  :readonly="!record.isEdit"
                  :data-apply_id="record.id"
                  :maxlength="32"
                  class="hidden-input-border"
                  style="width: 100%; box-sizing: border-box"
                  v-model:value="record.rename"
                  @keydown.enter="handleKeyDown(record, column)"
                  @blur="handleKeyDown(record, column)"
                />
              </template>
              <template v-else>-</template>
            </div>
          </template>

          <template v-if="column.key === 'create_time'">
            <span style="white-space: nowrap">{{ record.create_time ? dayjs(record.create_time).format('YYYY-MM-DD') : '-' }}</span>
          </template>
          <template v-if="column.key === 'reason'">
            <span style="word-break: break-all">{{ record.reason }}</span>
          </template>
          <template v-if="column.key === 'audit_time'">
           <span style="white-space: nowrap;">{{ record.audit_time ? dayjs(record.audit_time).format('YYYY-MM-DD') : '-' }}</span>
          </template>
          <template v-if="column.key === 'audit_by'">
            {{ auditMap[record.audit_by] || '-' }}
          </template>

          <template v-if="column.key === 'action'">
            <a-dropdown-button
              v-if="record.status === 'waiting'"
              @click="handleButtonClick(defaultAgreement, record)"
            >
              <span>{{ defaultButtonData.label }}</span>
              <template #overlay>
                <a-menu @click="handleMenuClick($event, record)">
                  <a-menu-item :key="7">同意（7）天</a-menu-item>
                  <a-menu-item :key="30">同意（30）天</a-menu-item>
                  <a-menu-item :key="365">同意（365）天</a-menu-item>
                  <a-menu-item :key="-1">同意永久</a-menu-item>
                  <a-menu-item key="custom">
                    <div @click.stop.prevent class="custom-day">
                      同意<a-input-number
                        @blur="handleAgreeBlur($event, record)"
                        :min="1"
                        style="width: 60px"
                      />天
                    </div>
                  </a-menu-item>
                  <a-menu-item key="reject">拒绝</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown-button>
            <template v-else-if="record.status === 'expired'"
              >已失效<template v-if="record.addition">（{{ record.addition }}）</template>
            </template>
            <template v-else-if="record.status === 'approved'"
              >同意加入<template v-if="record.addition"
                >（{{ record.addition }}）</template
              ></template
            >
            <template v-else-if="record.status === 'rejected'">拒绝加入</template>
            <template v-else>-</template>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
    <NewChanelModal
      v-model:visible="visibleNewChannel"
      :type="currentModalType"
      :item="currentModalItem"
      @success="fetchData"
    />
  </div>
</template>

<script setup>
import {ref, onMounted, h, onUnmounted, computed, nextTick, createVNode} from 'vue';
import dayjs from 'dayjs';
import {
  getLinkChannelAuditorList,
  getLinkChannelWithin,
  getLinkChannelUserApplyList,
  getLinkChannelUserApplyApprove,
  postLinkChannelUserUpdateNickname,
  getLinkChannelUserApplyReject,
} from '@/apis/channel';
import usePagination from '@/composable/usePagination';
import NewChanelModal from './components/NewChanelModal.vue';
import {getChannelType, getTextByValue} from './utils/index.js';
import {FormOutlined, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {message, Modal} from 'ant-design-vue';

const {pagination} = usePagination(getList);

const agreementOptions = ref([
  {label: '同意（7）天', value: 7},
  {label: '同意（30）天', value: 30},
  {label: '同意（365）天', value: 365},
  {label: '同意（永久）天', value: -1},
  {label: '拒绝', value: 'reject'},
]);

let thatNum = localStorage.getItem('defaultAgreement');

let DEFAULT_AGREEMENT = 7;

if (thatNum === 'reject') {
  DEFAULT_AGREEMENT = thatNum;
} else if (Number.isInteger(Number(thatNum))) {
  DEFAULT_AGREEMENT = Number(thatNum);
} else {
  DEFAULT_AGREEMENT = 7;
}

if (!agreementOptions.value.find((item) => item.value === DEFAULT_AGREEMENT)) {
  DEFAULT_AGREEMENT = agreementOptions.value[0].value;
}

const defaultAgreement = ref(DEFAULT_AGREEMENT);

const defaultButtonData = computed(() => {
  return (
    agreementOptions.value.find((item) => item.value === defaultAgreement.value) ||
    agreementOptions.value[0]
  );
});

const query = ref({
  action: [], // pending、completed
  channel: [], //
  audit_by: [], //
  sort: undefined, // create_time
  order_joined_count: undefined, // desc | asc
  order_resource_count: undefined, // desc | asc
});

const loading = ref(false);

const currentModalType = ref('create'); // create 创建频道， edit 编辑频道
const currentModalItem = ref({});

const visibleNewChannel = ref(false);

const channelOptions = ref([{text: '频道1', value: '1'}]);

const auditorOptions = ref([]);

const auditMap = computed(() => {
  const obj = {};
  auditorOptions.value.forEach((item) => {
    obj[item.value] = item.text;
  });
  return obj;
});

const columns = computed(() => [
  {
    title: '申请人',
    key: 'user_nickname',
    dataIndex: 'user_nickname',
    align: 'center',
  },
  {
    title: '频道内的昵称',
    key: 'nickname',
    dataIndex: 'nickname',
    align: 'center',
    width: 250,
  },
  {
    title: '申请留言',
    key: 'reason',
    dataIndex: 'reason',
  },
  {
    title: '申请加入的频道',
    key: 'channel',
    dataIndex: 'channel',
    align: 'center',
    filters: channelOptions.value.length > 0 ? [...channelOptions.value] : [],
    filterMultiple: false,
    filteredValue: query.value.channel,
  },
  {
    title: '申请时间',
    key: 'create_time',
    dataIndex: 'create_time',
    align: 'center',
  },
  {
    title: '审核人',
    key: 'audit_by',
    dataIndex: 'audit_by',
    align: 'center',
    filters: auditorOptions.value.length > 0 ? [...auditorOptions.value] : [],
    filterMultiple: false,
    filteredValue: query.value.audit_by,
  },
  {
    title: '审核时间',
    key: 'audit_time',
    dataIndex: 'audit_time',
    align: 'center',
  },
  {
    title: '操作/状态',
    key: 'action',
    fixed: 'right',
    align: 'center',
    filters: [
      {text: '未操作', value: 'pending'},
      {text: '已操作', value: 'completed'},
    ],
    filterMultiple: false,
    filteredValue: query.value.action,
    width: 200,
  },
]);

const data = ref([]);

const handleChange = (value) => {
  console.log('handle change', value);

  localStorage.setItem('defaultAgreement', value);
};

// 重命名的
const handleKeyDown = (record) => {
  if (!record?.rename?.trim() || record?.rename?.trim() === record?.nickname?.trim()) {
    record.isEdit = false;
    data.value = [...data.value];

    return;
  }

  record.nickname = record.rename;

  postLinkChannelUserUpdateNickname({
    channel_id: record.channel_id,
    user_id: record.user_id,
    nickname: record?.rename?.trim(),
  })
    .then((response) => {
      if (response.data.status === 200) {
        message.success('修改频道内的昵称成功');
        getList();
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      message.error(err?.data?.message || '修改频道内的昵称失败');
      getList();
    })
    .finally(() => {
      record.isEdit = false;
    });
};
// 点击重命名
const handleClickRename = (record) => {
  // 非同意的不可以操作
  if (record.status !== 'approved') {
    return;
  }
  record.isEdit = true;
  data.value = [...data.value];

  nextTick(() => {
    // 获取具有指定 data-device_uid 属性值的 input 元素
    const inputElement = document.querySelector(`input[data-apply_id="${record.id}"]`);

    // 检查元素是否存在
    if (inputElement) {
      // 将焦点设置到该元素上
      inputElement?.focus();
      // 选中该元素的内容
      inputElement.select();
    } else {
      console.log('没有找到匹配的 input 元素');
    }
  });
};

const handleButtonClick = async (days, record) => {
  localStorage.setItem('defaultAgreement', days);
  handleMenuClick({key: days}, record);
};

const handleAgreeBlur = (event, record) => {
  if (!event.target.value?.trim()) {
    return;
  }
  const days = Number(event.target.value?.trim());

  if (Number.isInteger(days)) {
    handleMenuClick({key: days}, record);
  } else {
    message.error('请输入整数');
  }
};
const handleMenuClick = async ({key: days}, record) => {
  if (days === 'custom') {
    return;
  }
  if (days === 'reject') {
    Modal.confirm({
      title: `拒绝加入 ${record.user_nickname}（用户id：${record.id}）`,
      icon: createVNode(ExclamationCircleOutlined),
      closable: true,
      content: h('div', {}, [h('p', {style: ''}, `确定该成员 拒绝加入 “${record.name}” 频道`)]),
      okText: '确定',
      centered: true,
      onOk() {
        getLinkChannelUserApplyReject({id: record.id})
          .then((response) => {
            if (response.data.status === 200) {
              message.success('拒绝加入成功');
              getList();
            } else {
              throw response;
            }
          })
          .catch((error) => {
            console.error([error]);
            message.error(error?.data?.message || '拒绝加入失败');

            getList();
          });
      },
      onCancel() {},
    });

    return;
  }
  Modal.confirm({
    title: `同意加入 ${record.user_nickname}（用户id：${record.id}）`,
    icon: createVNode(ExclamationCircleOutlined),
    closable: true,
    content: h('div', {}, [
      h(
        'p',
        {style: ''},
        `确定该成员 ${days === -1 ? '同意加入（永久）' : `同意加入（${days}）天`} “${record.name}” 频道`,
      ),
    ]),
    okText: '确定',
    centered: true,
    onOk() {
      getLinkChannelUserApplyApprove({id: record.id, days})
        .then((response) => {
          if (response.data.status === 200) {
            message.success(days === -1 ? '同意加入（永久）成功' : `同意加入（${days}）天成功`);
            getList();
          } else {
            throw response;
          }
        })
        .catch((error) => {
          console.error([error]);
          message.error(
            error?.data?.message || days === -1
              ? '同意加入（永久）失败'
              : `同意加入（${days}）天失败`,
          );
          getList();
        });
    },
    onCancel() {},
  });
};

const getAuditorOptions = () => {
  return getLinkChannelAuditorList({current: 1, size: 100})
    .then((response) => {
      if (response.data.status === 200) {
        auditorOptions.value = (response?.data?.data?.records || []).map((item) => ({
          text: item.name,
          value: item.id,
        }));
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      auditorOptions.value = [];
    });
};

const getChannelOptions = () => {
  return getLinkChannelWithin({current: 1, size: 100})
    .then((response) => {
      if (response.data.status === 200) {
        channelOptions.value = (response?.data?.data?.records || []).map((item) => ({
          text: item.name,
          value: item.id,
        }));
      } else {
        throw response;
      }
    })
    .catch((err) => {
      channelOptions.value = [];
      console.error([err?.message]);
    });
};

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;

    let handle;
    if (query.value?.action.join('') === 'completed') {
      handle = true;
    } else if (query.value?.action.join('') === 'pending') {
      handle = false;
    } else {
      handle = undefined;
    }
    const response = await getLinkChannelUserApplyList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // action: query.value?.action.join('') || undefined,
      auditor_id: query.value?.audit_by.join('') || undefined,
      channel_id: query.value?.channel.join('') || undefined,
      handle,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data?.total || 0,
    };

    const records = (response.data?.data?.records || []).map((item) => ({
      ...item,
      rename: item.nickname,
    }));

    data.value = records;
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.channel = filters.channel || [];
    query.value.audit_by = filters.audit_by || [];
    query.value.action = filters.action || [];

    fetchData();
  }
};

onMounted(() => {
  fetchData();
  getAuditorOptions();
  getChannelOptions();
});

onUnmounted(() => {});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;
}
.channel-type {
  background-color: #555;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
}
</style>

<style lang="scss">
.table-striped td {
  background-color: #fafafa;
}
.custom-day {
  display: flex;
  align-items: center;
}
.channel-nickname {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  min-width: 200px;
}
</style>
