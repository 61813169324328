<template>
  <a-modal v-model:open="dialogVisible" :title="title" v-bind="$attrs" destroyOnClose>
    <a-form :label-col="labelCol" :wrapper-col="wrapperCol" autocomplete="off">
      <a-form-item label="用户名" :colon="false">
        {{ userInfo.username }}
      </a-form-item>
      <a-form-item label="昵称" :colon="false">
        {{ userInfo.nickname }}
        <a-button type="link" title="修改昵称" @click="handleOpenUpdateNick" style="outline: none"
          ><FormOutlined
        /></a-button>
      </a-form-item>
      <a-form-item label="手机号" :colon="false">
        {{ userInfo.phone }}
        <a-button
          type="link"
          title="修改手机号"
          @click="handleOpenUpdatePhone"
          style="outline: none"
          ><FormOutlined
        /></a-button>
      </a-form-item>
      <a-form-item label="角色" :colon="false">
        {{ getRoleName(userInfo.role) }}
      </a-form-item>
      <a-form-item label="密码" :colon="false">
        <a-button type="link" @click="handleOpenChangePassword" style="padding-left: 0"
          >修改密码</a-button
        >
      </a-form-item>
      <a-form-item label="登录保护" :colon="false">
        <a-switch v-model:checked="userInfo.verify" @change="onChangeVerify" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button type="primary" @click="handleOk">确认</a-button>
    </template>
  </a-modal>

  <a-modal
    v-model:open="updateNickNameVisible"
    width="400px"
    title="修改昵称"
    destroyOnClose
    :mask-closable="false"
  >
    <a-form
      style="padding: 40px 0px 20px 0px"
      ref="updateNicknameRef"
      layout="vertical"
      :model="nickNameForm"
      name="updateNicknameRef"
      autocomplete="on"
    >
      <a-form-item label="" name="nickname" :rules="[{required: true, message: '请输入昵称'}]">
        <a-input v-model:value="nickNameForm.nickname" @keydown.enter="handleNickNameOk" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="handlePassNickNameCancel">取消</a-button>
      <a-button type="primary" @click="handleNickNameOk">确认</a-button>
    </template>
  </a-modal>
  <a-modal
    v-model:open="updatePhoneVisible"
    width="400px"
    title="修改手机号"
    destroyOnClose
    :mask-closable="false"
  >
    <a-form
      ref="updatePhoneRef"
      layout="vertical"
      :model="phoneForm"
      name="updatePhoneRef"
      autocomplete="on"
    >
      <a-form-item
        label="手机号"
        name="phone"
        :rules="[{required: false, message: '请输入新的手机号'}]"
      >
        <a-input v-model:value="phoneForm.phone" @keydown.enter="handlePhoneOk" readonly />
      </a-form-item>
      <a-form-item
        label="新的手机号"
        name="newPhone"
        :rules="[
          {required: true, message: '请输入新的手机号'},
          {pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号'},
        ]"
      >
        <a-input v-model:value="phoneForm.newPhone" @keydown.enter="handlePhoneOk" allowClear />
      </a-form-item>
      <a-form-item
        label="验证码"
        name="code"
        :rules="[
          {required: true, message: '请输入验证码'},
          {pattern: /^\d{6}$/, message: '验证码必须是6位数字'},
        ]"
      >
        <div class="verification-input">
          <a-input v-model:value="phoneForm.code" @keydown.enter="handlePhoneOk" />
          <a-button class="code-btn" type="link" @click="sendCode" :disabled="isCounting">{{
            isCounting ? `${countdown}s` : hasBeforeSend ? '重新发送' : '获取验证码'
          }}</a-button>
        </div>
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="handlePhoneCancel">取消</a-button>
      <a-button type="primary" @click="handlePhoneOk">确认</a-button>
    </template>
  </a-modal>
  <a-modal
    v-model:open="changePasswordVisible"
    width="400px"
    title="修改密码"
    destroyOnClose
    :mask-closable="false"
  >
    <a-form
      ref="changePasswordRef"
      layout="vertical"
      :model="formState"
      name="changePassword"
      autocomplete="off"
    >
      <a-form-item
        label="旧密码"
        name="oldPassword"
        :rules="[{required: true, message: '请输入旧密码'}]"
      >
        <a-input-password v-model:value="formState.oldPassword" />
      </a-form-item>
      <a-form-item label="新密码" name="newPassword" :rules="passwordRules">
        <a-input-password v-model:value="formState.newPassword" />
      </a-form-item>
      <a-form-item label="确认密码" name="confirmPassword" :rules="confirmPasswordRules">
        <a-input-password v-model:value="formState.confirmPassword" />
      </a-form-item>
    </a-form>
    <template #footer>
      <a-button @click="handlePasswordCancel">取消</a-button>
      <a-button type="primary" @click="handlePasswordOk">确认</a-button>
    </template>
  </a-modal>
</template>
<script setup>
import {ref, computed} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {getRoleName} from '@/router/roles.js';
import {getUserProfile, updateUserProfile} from '@/apis/users';
import {message} from 'ant-design-vue';
import {FormOutlined} from '@ant-design/icons-vue';
import useNickName from './useNickName';
import usePassowrd from './usePassword';
import usePhoneUpdate from './usePhoneUpdate';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);
// 定义接收的 props 和 emits
const props = defineProps({
  visible: {type: Boolean, default: false},
  title: {type: String, default: ''},
});
const emit = defineEmits(['visible']);
const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
});
const loading = ref(false);

const labelCol = {style: {width: '150px'}};
const wrapperCol = {span: 14};

const handleOk = () => {
  dialogVisible.value = false;
};

const onChangeVerify = async (checked) => {
  loading.value = true;
  await updateUserProfile({nickname: userInfo.value.nickname, verify: checked})
    .then((response) => {
      if (response.data.status === 200) {
        message.success(checked ? '开启二次验证成功' : '关闭二次验证成功');
      } else {
        throw response;
      }
    })
    .catch((error) => {
      message.error(error?.data.message || '开启二次验证失败');
    })
    .finally(() => {
      loading.value = false;
    });

  const {
    data: {data: profileData},
  } = await getUserProfile();

  userStore.setUserInfo(profileData);
};

const {
  formState,
  changePasswordRef,
  changePasswordVisible,
  handleOpenChangePassword,
  handlePasswordCancel,
  handlePasswordOk,
  passwordRules,
  confirmPasswordRules,
} = usePassowrd();

const {
  updateNickNameVisible,
  updateNicknameRef,
  nickNameForm,
  handleOpenUpdateNick,
  handlePassNickNameCancel,
  handleNickNameOk,
} = useNickName(loading);
const {
  updatePhoneVisible,
  updatePhoneRef,
  phoneForm,
  handleOpenUpdatePhone,
  handlePhoneCancel,
  handlePhoneOk,
  sendCode,
  countdown,
  isCounting,
  hasBeforeSend,
} = usePhoneUpdate(loading);
</script>
<style lang="scss" scoped>
.verification-input {
  position: relative;
  .code-btn {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
  }
}
</style>
