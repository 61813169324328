<template>
  <div class="login-container">
    <div class="up-form">
      <a-form
        :model="formState"
        name="UpOwnerApplicationFormView"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
        :label-col="{style: {width: '108px'}}"
      >
        <a-form-item
          label="联系人"
          name="name"
          :rules="[{required: true, message: '请输入联系人'}]"
        >
          <a-input
            v-model:value="formState.name"
            placeholder="请输入联系人"
            style="min-width: 320px"
          />
        </a-form-item>

        <a-form-item
          label="紧急联系电话"
          name="phone"
          :rules="[
            {required: true, message: '请输入联系电话'},
            {pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码'},
          ]"
        >
          <a-input
            v-model:value="formState.phone"
            placeholder="请输入有效的手机号码"
            style="min-width: 320px"
          />
        </a-form-item>
        <a-form-item
          label="申请理由"
          name="reason"
          :rules="[{required: false, message: '请输入申请理由'}]"
        >
          <a-textarea
            v-model:value="formState.reason"
            placeholder="请输入至少五个字符"
            :maxlength="2048"
            style="min-width: 320px"
          />
        </a-form-item>

        <a-form-item
          label="协议"
          name="agreement"
          :label-col="{style: {width: '108px', visibility: 'hidden'}}"
        >
          <a-checkbox v-model:checked="formState.agreement">我已经阅读并同意遵守</a-checkbox>
          <a
            href="https://doc.hificloud.net/yingqushe/agreement.html"
            target="_blank"
            rel="noopener noreferrer"
            >《社区公约》</a
          >
        </a-form-item>

        <a-form-item label="提交" :label-col="{style: {width: '108px', visibility: 'hidden'}}">
          <a-button class="submit-btn" :disabled="!isFormValid" type="primary" html-type="submit"
            >提交</a-button
          >
        </a-form-item>
      </a-form>
    </div>

    <PageFooter />
  </div>
</template>

<script setup>
import {reactive, computed} from 'vue';
import PageFooter from '@/components/PageFooter/index.vue';
import {useRoute, useRouter} from 'vue-router';
import * as RouteNames from '@/router/names.js';
import {message} from 'ant-design-vue';
import {postLinkAuthApply} from '@/apis/users';

const router = useRouter();

const route = useRoute();

const formState = reactive({
  name: '',
  phone: '',
  reason: '',
  agreement: false,
});

const onFinish = async (values) => {
  // console.log('Success:', values);

  try {
    const response = await postLinkAuthApply({
      token: route.query.temp_token,
      name: values.name,
      phone: values.phone,
      reason: values.reason,
    });

    if (response.data.status === 200) {
      router.push({name: RouteNames.UpApplicationProgressView, query: {...route.query}});
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.data?.message || '申请提交失败');
  }
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};

const isFormValid = computed(() => {
  const phonePattern = /^1[3-9]\d{9}$/;
  return formState.name && phonePattern.test(formState.phone) && formState.agreement;
});
</script>

<style scoped lang="scss">
.login-container {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;

  background: #f0f2f5 url('@/assets/background.svg') no-repeat 50%;
  background-size: 100%;
}

.up-form {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  padding: var(--page-padding);
  box-sizing: border-box;
}

.submit-btn {
  width: 100px;
}
</style>
<style lang="scss">
.is-mobile .submit-btn {
  width: 100%;
}
</style>
