<template>
  <div v-if="selectedRowKeys.length > 0">
    <a-space style="white-space: nowrap">
      <a-button @click="showConfirm('approved', selectedRowKeys)">同意</a-button>
      <a-button @click="showConfirm('reject', selectedRowKeys)">拒绝</a-button>
    </a-space>
    <a-alert type="info" class="alert-wrapper" show-icon>
      <template #description>
        <div style="display: flex; align-items: center">
          <span
            >已选择
            <a-typography-link type="link">{{ selectedRowKeys.length || 0 }}</a-typography-link>
            项</span
          >
          <a-typography-link @click="handleClear" style="margin-left: 16px" type="link"
            >清空</a-typography-link
          >
        </div>
      </template>
      <template #icon><InfoCircleFilled /></template>
    </a-alert>
  </div>

  <a-table
    rowKey="id"
    :row-selection="rowSelection"
    :loading="pageLoading"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    @change="onTableChange"
    class="overflow-x__auto"
  >
    <template #headerCell="{column}">
      <span style="white-space: nowrap">{{ column.title }}</span>
    </template>
    <template #bodyCell="{column, record}">
      <template v-if="column.key === 'reason'">
        <div class="text-overflow" :title="record.reason">{{ record.reason }}</div>
      </template>
      <template v-if="column.key === 'create_time'">
        <span style="white-space: nowrap">{{
          dayjs(record.create_time).format('YYYY/MM/DD HH:mm:ss')
        }}</span>
      </template>
      <template v-if="column.key === 'status'">
        <span style="white-space: nowrap">{{ statusObj[record.status] }}</span>
      </template>
      <template v-if="column.key === 'action'">
        <a-space v-if="record.status === 'examine'" style="white-space: nowrap">
          <a-typography-link
            type="link"
            class="btn-link"
            @click="showConfirm('approved', [record.id])"
            >同意</a-typography-link
          >
          <a-typography-link
            type="link"
            class="btn-link"
            @click="showConfirm('reject', [record.id])"
            >拒绝</a-typography-link
          >
        </a-space>
        <template v-else>-</template>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import {computed, createVNode, ref, watch} from 'vue';
import dayjs from 'dayjs';
import {InfoCircleFilled, ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {postLinkAuditMasterApply} from '@/apis/review.js';

const props = defineProps({
  pageLoading: {type: Boolean, default: false},
  dataSource: {type: Array, default: () => []},
  query: {type: Object, default: () => ({})},
  onTableChange: {type: Function, default: () => {}},
  fetchData: {type: Function, default: () => {}},
});

const statusArray = [
  {text: '同意', value: 'approved'},
  {text: '拒绝', value: 'reject'},
  {text: '未处理', value: 'examine'},
];

const selectedRowKeys = ref([]); // Check here to configure the default column

const rowSelection = ref({
  selectedRowKeys: selectedRowKeys.value,
  hideDefaultSelections: true,
  onChange: (changableRowKeys) => {
    selectedRowKeys.value = changableRowKeys;
  },
  getCheckboxProps: (record) => ({
    disabled: ['approved', 'reject'].includes(record.status),
    id: record.id,
  }),
});

watch(selectedRowKeys, (newKeys) => {
  rowSelection.value.selectedRowKeys = newKeys;
});

const handleClear = () => {
  selectedRowKeys.value = [];
};

const statusObj = {};

statusArray.forEach((item) => {
  statusObj[item.value] = item.text;
});

const columns = computed(() =>
  [
    {
      title: 'ID',
      key: 'id',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '申请人',
      key: 'name',
      align: 'center',
      width: 250,
    },
    {
      title: '联系方式',
      key: 'phone',
      align: 'center',
      width: 250,
    },
    {
      title: '频道内容简介',
      key: 'reason',
      align: 'center',
      width: 250,
    },

    {
      title: '状态',
      key: 'status',
      align: 'center',
      filters: statusArray,
      filterMultiple: false,
      filteredValue: props.query.status,
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
    {
      title: '申请时间',
      key: 'create_time',
      align: 'center',
      sorter: (a, b) => new Date(a.create_time).getTime() - new Date(b.create_time).getTime(),
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const loading = ref(false);

const showConfirm = (type, records) => {
  let confirmModal = null;
  const title = type === 'approved' ? '同意' : '拒绝';
  const titleText = title + 'UP主申请';
  confirmModal = Modal.confirm({
    title: titleText,
    icon: createVNode(ExclamationCircleOutlined),
    content: `你确定该UP主 ${title} 申请吗？`,
    okText: '确定',
    okButtonProps: {loading: loading.value},
    centered: true,
    async onOk() {
      loading.value = true;
      confirmModal.update({
        okButtonProps: {loading: true},
      });
      try {
        const response = await postLinkAuditMasterApply({id: records, status: type});
        if (response.data.status === 200) {
          message.success(titleText + '成功');
          confirmModal.destroy();
        } else {
          throw response;
        }
      } catch (err) {
        console.error(err);
        message.error(err?.data?.message || titleText + '失败');
        confirmModal.update({
          okButtonProps: {loading: false},
        });
        throw err;
      } finally {
        loading.value = false;

        props.fetchData();
      }
    },
    onCancel() {},
  });
};
</script>

<style scoped lang="scss">
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
@media only screen and (min-width: 1200px) {
  .text-overflow {
    max-width: 250px;
  }
}
@media only screen and (min-width: 1400px) {
  .text-overflow {
    max-width: 300px;
  }
}
@media only screen and (min-width: 1700px) {
  .text-overflow {
    max-width: 350px;
  }
}
</style>

<style lang="scss">
.alert-wrapper {
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  font-size: 14px !important;
  align-items: center;
  padding: 8px 16px;
  .ant-alert-description {
    font-size: 14px;
    line-height: 1em;
  }
  .ant-alert-icon {
    font-size: 16px;
  }
}
</style>
