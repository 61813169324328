<template>
  <div>
    <!-- <div class="page-header">
      <a-input-search
        v-model:value="searchText"
        placeholder="输入用户名检索"
        enter-button
        allow-clear
        @search="getList"
        style="max-width: 460px"
      />
    </div> -->
    <div class="enable-verification__wrapp">
      <a-button type="primary" @click="openModal()" style="margin-right: auto"
        >新建设备子账户</a-button
      >

      <div class="enable-verification__inner">
        <a-switch
          style="width: 36px"
          v-model:checked="verify"
          size="small"
          @change="handleChangeVerify"
          checked-children="开"
          un-checked-children="关"
        />
        <span>设备子账户登录时，是否需要手机：UP主的手机号码，进行登录验证码确认</span>
      </div>
    </div>

    <a-table
      :loading="loading"
      class="page-content"
      :columns="columns"
      :data-source="data"
      :pagination="false"
    >
      <template #headerCell="{column}">
        <span style="white-space: nowrap">
          {{ column.title }}
        </span>
      </template>

      <template #bodyCell="{column, record}">
        <template v-if="column.key === 'username'">
          {{ record.username }}
        </template>
        <template v-if="column.key === 'nickname'">
          {{ record.nickname }}
        </template>
        <template v-if="column.key === 'status'">
          {{ record.enable ? '允许访问' : '禁止访问' }}
        </template>
        <template v-if="column.key === 'action'">
          <a-space>
            <a-popconfirm
              :title="`你确定${record.enable ? '禁止访问' : '恢复访问'}吗`"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onConfirm('set_permission', record)"
              @cancel="onCancel"
            >
              <template #icon><QuestionCircleOutlined style="color: red" /></template>
              <a-typography-link v-if="record.enable" type="link" class="btn-link"
                >禁止访问</a-typography-link
              >
              <a-typography-link v-else type="link" class="btn-link">恢复访问</a-typography-link>
            </a-popconfirm>
            <a-popconfirm
              :title="`你确定密码重置吗`"
              ok-text="确定"
              cancel-text="取消"
              @confirm="onConfirm('reset_password', record)"
              @cancel="onCancel"
            >
              <template #icon><QuestionCircleOutlined style="color: red" /></template>
              <a-typography-link type="link" class="btn-link">密码重置</a-typography-link>
            </a-popconfirm>
            <!-- <a-typography-link type="link" class="btn-link" @click="copyAccountInfo(record)"
              >复制账户密码</a-typography-link
            > -->
          </a-space>
        </template>
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <a-pagination
        v-model:current="pagination.current"
        v-model:page-size="pagination.pageSize"
        :total="pagination.total"
        :show-total="pagination.showTotal"
        :defaultPageSize="pagination.defaultPageSize"
        showSizeChanger
        :pageSizeOptions="pagination.pageSizeOptions"
        @change="pagination.onChange"
      />
    </div>

    <CopyUsernamePasswordModal
      v-model:visible="modalCopyOpen"
      :profile="profile"
      :copyAccountInfo="copyAccountInfo"
    />

    <AddUserModal :inputData="inputData" v-model:visible="modalOpen" @success="onSuccess" />
  </div>
</template>
<script setup>
import CopyUsernamePasswordModal from './CopyUsernamePasswordModal.vue';
import AddUserModal from './AddUserModal.vue';
import {nextTick, onMounted, ref, watch} from 'vue';

import useResize from '@/composable/useResize.js';
import {message} from 'ant-design-vue';
import {QuestionCircleOutlined} from '@ant-design/icons-vue';
import {
  getLinkRamList,
  postLinkRamUpdate,
  postLinkRamResetPassword,
  postSwitchRamVerify,
} from '@/apis/config';
// import {getRoleName} from '@/router/roles';
import usePagination from '@/composable/usePagination';

const {width} = useResize();

const modalOpen = ref(false);
const modalCopyOpen = ref(false);
const loading = ref(false);
const profile = ref(); // 创建用户成功或者重置密码后的信息
const inputData = ref({
  isEdit: false, // false 创建，true 编辑
});

const {pagination} = usePagination(getList);

const openModal = async (data = {}) => {
  inputData.value = data;
  await nextTick();
  modalOpen.value = true;
};

const handleChangeVerify = async (value) => {
  if (loading.value) {
    return;
  }
  let response;
  loading.value = true;
  try {
    response = await postSwitchRamVerify({});
    await getList();
  } catch (error) {
    console.error([error]);
  } finally {
    loading.value = false;
  }

  if (response?.data?.status === 200) {
    if (value) {
      message.info('子账号登录验证已开启');
    } else {
      message.info('子账号登录验证已关闭');
    }
  }
};

const onConfirm = async (type, record) => {
  switch (type) {
    case 'set_permission': {
      try {
        const response = await postLinkRamUpdate({
          id: record.id,
          enable: !record.enable,
        });
        getList();
        if (response.data.status !== 200) {
          throw response;
        }
        message.success(record.enable ? '设置禁止访问成功' : '设置恢复访问成功');
      } catch (error) {
        message.error(record.enable ? '设置禁止访问失败' : '设置恢复访问失败');
        console.error([error]);
      }
      break;
    }
    case 'reset_password': {
      try {
        const passwordResetResponse = await postLinkRamResetPassword({id: record.id});
        if (passwordResetResponse.data.status === 200) {
          message.success('重置密码成功');
          profile.value = {...record, ...passwordResetResponse.data.data};
          modalCopyOpen.value = true;
        } else {
          throw passwordResetResponse;
        }
        await getList();
      } catch (error) {
        message.error('重置密码失败');
        console.error([error]);
      }
      break;
    }

    default:
      break;
  }
};
const onCancel = () => {};
const columns = ref(
  [
    {
      title: '子账户ID',
      key: 'id',
    },
    {
      title: '用户名',
      key: 'username',
    },
    {
      title: '昵称',
      key: 'nickname',
    },
    {
      title: '状态',
      key: 'status',
    },
    {
      title: '操作',
      key: 'action',
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const data = ref([]);
const verify = ref(false);

watch(
  data,
  (myValue) => {
    localStorage.setItem('persons', JSON.stringify(myValue));
  },
  {immediate: true, deep: true},
);

async function getList() {
  try {
    loading.value = true;
    const response = await getLinkRamList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
    });

    pagination.value = {
      ...pagination.value,
      total: response?.data?.data?.total || 0,
    };

    verify.value = response?.data?.data?.verify;

    data.value = (response?.data?.data?.records || []).map((item, index) => {
      return {...item, index: index + 1};
    });

    // data.value.push({
    //   id: 1,
    //   username: '123',
    //   nickname: '啊是的请问',
    //   enable: true,
    //   mark: 'aAsd',
    // });
  } catch (error) {
    console.error('[PersonnelConfigurationView.vue error]', [error]);
    data.value = [];
  } finally {
    loading.value = false;
  }
}

const onSuccess = (data) => {
  modalCopyOpen.value = true;
  profile.value = data;
  getList();
};

// const onClickUsername = (record) => {
//   openModal({
//     isEdit: true,
//     username: record.username,
//     nickname: record.nickname,
//     role: record.role,
//     password: record.password,
//     ...record
//   });
// };

// eslint-disable-next-line
const copyAccountInfo = (record) => {
  modalCopyOpen.value = false;
  // copyText(
  //   `请登录访问 ${window.location.origin}/login ,用户名：${record?.username} ,密码：${record.password}`,
  // );
};

watch(
  width,
  (value) => {
    let actionWidth = 250;
    if (value >= 1400) {
      actionWidth = 400;
    } else if (value >= 1200) {
      actionWidth = 300;
    } else if (value >= 1000) {
      actionWidth = 250;
    }

    columns.value = columns.value.map((item) => {
      if (item.key == 'action') {
        return {...item, width: actionWidth};
      }
      return item;
    });
  },
  {immediate: true},
);

onMounted(() => {
  getList();
});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
}
.btn-link {
  white-space: nowrap;
}

.enable-verification__wrapp {
  display: flex;
  align-items: center;
  flex-flow: row wrap;
  gap: 16px;
  margin-bottom: 16px;
}

.enable-verification__inner {
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  font-size: 14px;
  color: #7b7b7b;
  gap: 12px;
}
</style>
