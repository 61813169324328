<template>
  <div class="material-edit">
    <div class="material-info">
      <div>文件名：{{ materialDetailInfo?.filename || '' }}</div>
      <div>文件大小：{{ bytes(materialDetailInfo?.size || 0) }}</div>
    </div>

    <div class="material-form__wrapper">
      <div class="material-form__form">
        <a-form
          :model="formState"
          ref="formRef"
          name="basic"
          autocomplete="off"
          :label-col="{style: {width: '140px'}}"
          labelAlign="left"
        >
          <a-form-item label="类型" name="type" :rules="[{required: true, message: '请选择'}]">
            <a-select
              :disabled="loading"
              v-model:value="formState.type"
              placeholder="请选择"
              class="form-auto-width add-resource__select"
              @change="handleTypeChange"
            >
              <a-select-option value="movie" class="add-resource__select-option"
                >电影</a-select-option
              >
              <a-select-option value="other" class="add-resource__select-option"
                >其他</a-select-option
              >
            </a-select>
          </a-form-item>
          <a-form-item label="标题" name="title" :rules="[{required: true, message: '请输入标题'}]">
            <template v-if="formState.type === 'other'">
              <a-input
                :disabled="loading"
                v-model:value="formState.title"
                class="form-auto-width"
                allowClear
                placeholder="请输入标题"
              ></a-input>
            </template>
            <template v-else>
              <a-input
                v-if="isKeyDownEnter && hitOption"
                :disabled="loading"
                v-model:value="hitOption.title"
                class="form-auto-width"
                allowClear
                placeholder="请输入标题"
                ref="editInputEnter"
                @keydown.enter="handleKeyDownEnter"
                @blur="handleKeyDownBlur"
              ></a-input>

              <a-select
                v-else
                :disabled="loading"
                v-model:value="formState.title"
                show-search
                placeholder="请输入标题"
                class="form-auto-width"
                :default-active-first-option="false"
                allowClear
                :show-arrow="true"
                :filter-option="false"
                :not-found-content="notFoundContent"
                @keydown.enter="handleKeyDownEnter"
                @search="handleSearch"
                @change="handleChange"
              >
                <a-select-option
                  v-for="option in options"
                  :key="option.ycb_mv_id"
                  :value="option.ycb_mv_id"
                  >{{ option.title }}{{ option.year ? `（${option.year}）` : '' }}</a-select-option
                >
              </a-select>
            </template>
          </a-form-item>
          <a-form-item
            label="简介"
            name="description"
            class="form-auto-width"
            :rules="[
              {required: true, message: '请输入简介'},
              {min: 5, message: '简介至少需要5个字符'},
              {max: 500, message: '简介不能超过500个字符'},
            ]"
          >
            <a-textarea
              :disabled="loading"
              v-model:value="formState.description"
              style="min-height: 100px"
              show-count
              auto-size
              :maxlength="500"
            ></a-textarea>
          </a-form-item>
          <a-form-item
            label="海报图片"
            name="image_large_url"
            :rules="[{required: true, message: '请上传海报图片'}]"
            class="form-item-poster"
          >
            <div>
              <a-input v-show="false" v-model:value="formState.image_large_url"></a-input>

              <div class="upload-container">
                <UploadImage
                  v-for="item in showPosterHorizontalImages"
                  :key="item.id"
                  :src="item.url"
                  :showCroper="true"
                  :disabled="loading"
                  @clear="handleClearImage(item)"
                  @change="handleChangeImage($event, item)"
                />
              </div>
            </div>
          </a-form-item>
          <a-form-item
            label="影片海报"
            name="poster"
            :rules="[{required: true, message: '请上传影片海报'}]"
            class="form-item-poster"
          >
            <div>
              <a-input v-show="false" v-model:value="formState.poster"></a-input>
              <div class="upload-container">
                <UploadImage
                  ref="posterImageRef"
                  :src="formState.poster"
                  :disabled="loading"
                  imgStyle="height: auto;min-height: 103px"
                  @clear="handleClearImage('posterVerticalImage')"
                  @change="handleChangeImage($event, 'posterVerticalImage')"
                />
              </div>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <MaterialPreview
        class="material-form__preview"
        :title="previewTitle"
        :description="formState.description"
        :movieImage="formState.poster"
        :imageList="posterHorizontalImages"
      />
    </div>

    <div class="material-buttons">
      <a-space size="large">
        <a-button :disabled="loading" @click="handleCancel" style="min-width: 120px">取消</a-button>
        <a-button
          @click="handleSubmit"
          style="min-width: 120px"
          :disabled="loading || !enableSubmitBtn"
          type="primary"
          >上传媒资库</a-button
        >
      </a-space>
    </div>
  </div>
</template>
<script setup>
import {MaterialEditSuccess, MaterialLibrary} from '@/router/names';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import MaterialPreview from './components/MaterialPreview.vue';
import {
  getLinkMaterialDetail,
  getMvsMvFilepath,
  getMvsMvKeyword,
  postLinkMaterial,
} from '@/apis/media';
import UploadImage from './components/UploadImage.vue';
import {message} from 'ant-design-vue';
import bytes from 'bytes';

const formDefaultData = {
  type: '', // 类型，可取值 1电影,2其他 number
  title: '', // 标题
  description: '', // 简介
  poster: '', // 海报
  image_large_url: [],
};

const formState = ref({...formDefaultData});

const loading = ref(false);

const router = useRouter();
const route = useRoute();

const options = ref([]);

const formRef = ref();

const hitOption = ref();

const posterHorizontalImages = ref([
  {id: 1, url: ''},
  {id: 2, url: ''},
  {id: 3, url: ''},
  {id: 4, url: ''},
]); // 海报横图

const searchController = ref(); // 控制搜索海报

const spinning = ref(false);

const editInputEnter = ref();

const isKeyDownEnter = ref(false);

const materialDetailInfo = ref(null); // 默认的详情数据

const posterImageRef = ref();

const showPosterHorizontalImages = computed(() => {
  const result = posterHorizontalImages.value.reduce(
    (acc, item) => {
      if (item.url) {
        acc.uploaded.push(item);
      } else {
        acc.empty.push(item);
      }
      return acc;
    },
    {uploaded: [], empty: []},
  );
  return [...result.uploaded, ...result.empty.slice(0, 1)];
});

const previewTitle = computed(() => {
  if (formState.value.type === 'movie') {
    if (hitOption.value) {
      const title = hitOption.value.title;
      const year = hitOption.value.year;
      const text = year ? `${title}(${year})` : title;
      return text?.trim();
    }
    return undefined;
  } else {
    const text = formState.value.title;
    return text ? text?.trim() : undefined;
  }
});

const enableSubmitBtn = computed(() => {
  const dataValue = formState.value;
  console.log('dataValue', dataValue);
  for (const item of Object.keys(dataValue)) {
    if (!dataValue[item]) {
      return false;
    }
    if (item === 'description' && (dataValue[item]?.length < 5 || dataValue[item]?.length > 500)) {
      return false;
    }
    if (
      item === 'image_large_url' &&
      (!Array.isArray(dataValue[item]) ||
        (Array.isArray(dataValue[item]) && dataValue[item].length === 0))
    ) {
      return false;
    }
  }
  return true;
});

watch(
  hitOption,
  (current) => {
    posterHorizontalImages.value = getPosterImages(current?.image_large || []);
  },
  {immediate: true},
);
watch(
  posterHorizontalImages,
  (arr) => {
    formState.value = {...formState.value, image_large_url: arr.filter((item) => item.url)};
  },
  {immediate: true},
);

function getPosterImages(imageArray = []) {
  const defaultArray = [
    {id: 1, url: ''},
    {id: 2, url: ''},
    {id: 3, url: ''},
    {id: 4, url: ''},
  ];
  if (Array.isArray(imageArray)) {
    return defaultArray.map((item, index) => {
      item.url = imageArray[index] || '';
      return item;
    });
  }
  return defaultArray;
}

const handleClearImage = (current) => {
  if (current === 'posterVerticalImage') {
    formState.value = {...formState.value, poster: ''};
    nextTick(() => {
      formRef.value?.validateFields(['poster'])?.catch(() => {});
    });
    return;
  }
  posterHorizontalImages.value = posterHorizontalImages.value.map((item) => {
    if (item.id === current?.id) {
      return {...item, url: ''};
    }
    return item;
  });
  nextTick(() => {
    formRef.value?.validateFields(['image_large_url'])?.catch(() => {});
  });
};

const handleChangeImage = (url, current) => {
  if (current === 'posterVerticalImage') {
    formState.value.poster = url;

    nextTick(() => {
      formRef.value?.validateFields(['poster'])?.catch(() => {});
    });
    return;
  }

  posterHorizontalImages.value = posterHorizontalImages.value.map((item) => {
    if (item.id === current?.id) {
      return {...item, url: url};
    }
    return item;
  });

  nextTick(() => {
    formRef.value?.validateFields(['image_large_url'])?.catch(() => {});
  });
};

const handleKeyDownBlur = () => {
  isKeyDownEnter.value = false;
};

const handleKeyDownEnter = () => {
  if (hitOption.value && hitOption.value.title) {
    isKeyDownEnter.value = !isKeyDownEnter.value;
    nextTick(() => {
      const element = editInputEnter.value?.input;
      element?.focus();
    });
  }
};

const handleSearch = (inputValue) => {
  // console.log('inputValue', inputValue);
  spinning.value = true;

  searchController.value?.abort();

  // 创建 AbortController 实例
  const controller = new AbortController();

  searchController.value = controller;

  return getMvsMvKeyword(
    {
      keyword: inputValue?.trim() || '',
      current: 1,
      size: 100,
    },
    controller,
  )
    .then((response) => {
      const info = response.data.info;
      options.value = info.record;
      // console.log('[options.value]', options.value);
      return info;
    })
    .catch((err) => {
      // options.value = [];
      console.error('[getMvsMvKeyword err]', err);
    })
    .finally(() => {
      spinning.value = false;
    });
};

function removeFileExtension(filename) {
  const lastDotPosition = (filename || '')?.lastIndexOf('.');

  // 如果有后缀才去掉
  if (lastDotPosition > 0) {
    return filename.substring(0, lastDotPosition);
  }

  // 如果没有后缀，返回原文件名称
  return filename;
}

const handleTypeChange = (value) => {
  hitOption.value = null;
  options.value = [];

  if (value === 'other') {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: removeFileExtension(formState.value?.filename || ''),
    };

    nextTick(() => {
      formRef.value?.clearValidate();
    });
  } else if (value === 'movie') {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: '',
    };

    nextTick(() => {
      formRef.value?.clearValidate();
    });

    loading.value = true;
    // 搜索一次
    getMvsMvFilepath({filepath: formState.value.filename})
      .then((response) => {
        if (response.data.status === 200) {
          const arr = response.data.info ? [response.data.info] : [];

          if (arr.length > 0) {
            options.value = arr;
            nextTick(() => {
              formState.value.title = arr[0].ycb_mv_id;

              handleChange(arr[0].ycb_mv_id);
            });
          }
        }
      })
      .catch((err) => {
        console.error([err]);
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: '',
    };

    nextTick(() => {
      formRef.value?.clearValidate();
    });
  }
};

const handleChange = async (value) => {
  const item = options.value.find((item) => item.ycb_mv_id === value);

  if (item) {
    hitOption.value = item;

    formState.value = {
      ...formState.value,
      description: item.summary?.trim() || '',
      poster: item.poster,
    };
  }

  await nextTick();

  formRef.value.validate().catch((err) => {
    console.log(err);
  });
};

async function loadData() {
  try {
    loading.value = true;
    const response = await getLinkMaterialDetail({id: route.query.id});
    if (response.data.status === 200) {
      const result = response.data.data;

      formState.value = {
        poster: result.cover_url,
        description: result.summary?.trim() || '',
        title: result.title,
        type: result.type,
        image_large_url: result.image_large_url,
        filename: result.filename,
      };

      posterHorizontalImages.value = getPosterImages(result?.image_large_url || []);

      materialDetailInfo.value = result;

      if (formState.value.type === 'movie') {
        handleTypeChange('movie');
      }
    } else {
      throw response;
    }
  } catch (error) {
    message.info(error?.data?.message || '获取数据失败');
    console.error('[loadData error]', error);
  } finally {
    loading.value = false;
  }
}

const handleCancel = () => {
  router.push({name: MaterialLibrary});
};

const handleSubmit = async () => {
  try {
    // 表单校验，如果通过则继续，否则不继续
    await formRef.value.validate();

    const imageSize = posterImageRef.value?.getImageSize();
    console.log('posterImageRef', imageSize);

    const newItem = {
      cover_url_width: imageSize?.width,
      cover_url_height: imageSize?.height,
      id: Number(route.query.id),
      type: formState.value.type,
      title: formState.value.title,
      summary: formState.value?.description?.trim() || '',
      cover_url: formState.value.poster,
      image_large_url: posterHorizontalImages.value
        .filter((item) => item.url)
        .map((item) => item.url),
      // fac_mv_id: formState.value.fac_mv_id,
    };

    if (newItem.type === 'movie' && !hitOption.value) {
      message.error('请选择匹配影片标题');
      return;
    }

    if (newItem.type === 'movie') {
      newItem.fac_mv_id = hitOption.value.ycb_mv_id;
      newItem.title = hitOption.value.title;
      newItem.year = hitOption.value.year;
    }

    // console.log('[hitOption]', hitOption);

    // console.log('[handleSubmit newItem]', newItem);

    try {
      loading.value = true;

      // await new Promise((resolve) => setTimeout(resolve, 3000));

      const response = await postLinkMaterial(newItem);
      if (response.data.status === 200) {
        router.push({name: MaterialEditSuccess});
      } else {
        throw response;
      }
    } catch (error) {
      message.error(error?.data?.message || '提交失败');
      console.error('[handleSubmit error]', error);
    } finally {
      loading.value = false;
    }
  } catch (error) {
    console.error('表单校验不通过');
  }
};

onMounted(() => {
  loadData();
});
</script>
<style lang="scss" scoped>
.material-edit {
  background-color: #f5f5f5;
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: column;
}
.material-info {
  background-color: #fff;
  padding: 32px var(--page-padding);
  display: flex;
  gap: 40px;
  border-radius: 4px;
  margin-bottom: var(--page-padding);
}

.material-form__wrapper {
  display: flex;
  flex-flow: row nowrap;
  background-color: #fff;
  padding: var(--page-padding);
  border-radius: 4px;
  margin-bottom: 64px;
  gap: 16px;
  flex: 1;

  .material-form__form {
    width: 100%;
  }

  .material-form__preview {
    flex: 1;
    display: flex;
    justify-content: flex-start;
  }
}

@media screen and (max-width: 1000px) {
  .material-form__wrapper {
    flex-flow: row wrap;
    .material-form__form {
      width: 100%;
    }
    .material-preview {
      justify-content: flex-start;
    }
  }
}

@media screen and (min-width: 1000px) {
  .material-form__wrapper {
    flex-flow: row nowrap;
    .material-form__form {
      width: 100%;
    }
    .material-preview {
      justify-content: flex-end;
    }
  }
}

.add-resource__upload-btn {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #ededed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.form-item-poster:hover {
  .image-wrapper {
    border: 1px dashed #d9d9d9;

    .close-icon {
      display: block;
    }
  }
}

.add-resource__upload-btn {
  background-color: transparent;
  margin-bottom: 16px;
}

.image-wrapper {
  display: inline-flex;
  border: 1px dashed transparent;
  padding: 0;
  border-radius: 8px;
  user-select: none;
  position: relative;
  .close-icon {
    position: absolute;
    right: -12px;
    top: -12px;
    z-index: 222;
    cursor: pointer;
    display: none;
    transition: all ease 0.1s;
  }
}

.logo-summary {
  max-width: min(480px, 50vw);
}

.material-buttons {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  background-color: #fff;
  padding: var(--page-padding) 64px;
  border-radius: 4px;
  position: fixed;
  bottom: 0;
  left: calc(var(--sider-width) + var(--page-padding));
  right: calc(var(--page-padding) + 16px);
  box-shadow:
    0px 12px 32px 4px rgba(0, 0, 0, 0.04),
    0px 8px 20px rgba(0, 0, 0, 0.08);
}

.form-auto-width {
  width: 100%;
}

.upload-container {
  display: flex;
  flex-flow: row wrap;
  gap: 16px;
}
</style>
