<template>
  <a-modal
    width="680px"
    :open="dialogVisible"
    :title="`资源发布 — 资源ID: ${item.id}`"
    :closable="!loading"
    :maskClosable="false"
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <div>
      <a-alert v-show="errorMessage" :message="errorMessage" type="error" closable />
      <a-form ref="formRef" :model="formState" :rules="rules" autocomplete="off">
        <div class="form-top"></div>
        <div class="form-title">标题：{{ item.title }}</div>
        <div class="form-content">
          <div class="form-image">
            <img :src="item.cover_url" style="object-fit: cover; width: 100%; height: 100%" />
          </div>
          <div class="form-desc">
            {{ item.summary }}
          </div>
        </div>
        <div class="form-title" style="margin-top: 16px">请选择需要进行发布的频道：</div>
        <a-transfer
          listStyle="width: 540px;height:256px;"
          v-model:target-keys="targetKeys"
          v-model:selected-keys="selectedKeys"
          :rowKey="(record) => record.id"
          :data-source="options"
          :titles="['  未发布频道', '  已发布频道']"
          :render="(item) => item.name"
          @change="handleChange"
        />
      </a-form>
    </div>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button
        key="submit"
        type="primary"
        :loading="loading"
        @click="handleOk"
        :disabled="!isFormChanged || !formValid || loading"
        >确定</a-button
      >
    </template>
  </a-modal>
</template>

<script setup>
import {computed, ref, watch} from 'vue';
import {postResourcePost, getResourceExists} from '@/apis/media';
import {message} from 'ant-design-vue';
import {getLinkChannelDropdown} from '@/apis/channel';

const props = defineProps({
  visible: {type: Boolean, default: false},
  item: {type: Object, default: () => ({})},
});
const emits = defineEmits(['update:visible', 'success']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const errorMessage = ref('');

const loading = ref(false);

const formState = ref({
  count: undefined,
  channels: [],
});

const initialFormState = ref({});

watch(
  () => formState.value.channels,
  (newChannels) => {
    if (newChannels.includes('all')) {
      formState.value.channels = options.value
        .filter((option) => option.id !== 'all')
        .map((option) => option.id);
    }
  },
  {deep: true},
);

const validateCount = (rule, value, callback) => {
  if (value === undefined || value === null) {
    callback(new Error('请输入副本数量'));
  } else if (value <= 0) {
    callback(new Error('副本数量必须大于0'));
  } else {
    callback();
  }
};

const rules = {
  count: [{required: true, validator: validateCount, trigger: 'blur'}],
  channels: [{required: false, message: '请选择上传频道', trigger: 'change'}],
};

const options = ref([]);

const formRef = ref(null);

const formValid = computed(() => formState.value.count > 0);

const isFormChanged = computed(() => {
  return (
    formState.value.count !== initialFormState.value.count ||
    JSON.stringify(formState.value.channels) !== JSON.stringify(initialFormState.value.channels)
  );
});

const targetKeys = ref([]);
const selectedKeys = ref([]);

const publishChannel = ref([]);

const handleChange = (nextTargetKeys) => {
  formState.value = {
    ...formState.value,
    channels: nextTargetKeys,
  };
};

const handleOk = async () => {
  try {
    await formRef.value.validate();

    const response = await postResourcePost({
      id: props.item.id,
      replica_count: formState.value.count,
      channel_ids: formState.value.channels,
    });

    if (response.data.status === 200) {
      dialogVisible.value = false;

      const arr1 = options.value
        .filter((item) => publishChannel.value.includes(item.id))
        .filter((item) => !formState.value.channels.includes(item.id));
      const arr2 = options.value
        .filter((item) => !publishChannel.value.includes(item.id))
        .filter((item) => formState.value.channels.includes(item.id));

      emits('success', {...formState.value, unpublishChannel: arr1, publishChannel: arr2});
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.data?.message || '发布失败');
  }
};

const handleCancel = () => {
  dialogVisible.value = false;
};

watch(dialogVisible, async (visible) => {
  if (visible) {
    loading.value = true;
    let channelOptions = [];
    let channelValues = [];

    try {
      const dropdownRequest = getLinkChannelDropdown({current: 1, size: 100});
      const resourceRequest = getResourceExists({id: props.item.id});

      // 使用 Promise.all 同时等待两个请求
      const [dropdownResponse, resourceResponse] = await Promise.all([
        dropdownRequest,
        resourceRequest,
      ]);

      // 处理 getLinkChannelDropdown 的响应
      if (dropdownResponse.data.status === 200) {
        const records = dropdownResponse?.data?.data?.records;

        if (records?.length > 0) {
          channelOptions = [...dropdownResponse.data.data.records];
        } else {
          channelOptions = [];
        }
      } else {
        throw dropdownResponse;
      }

      // 处理 getResourceExists 的响应
      if (resourceResponse.data.status === 200) {
        if (
          !resourceResponse.data.data.exists ||
          resourceResponse.data.data.channels.length === 0
        ) {
          errorMessage.value = '';
          channelValues = [];
        } else {
          errorMessage.value = '';
          channelValues = [...resourceResponse.data.data.channels];
        }
      } else {
        throw resourceResponse;
      }
    } catch (error) {
      console.error(error);
      errorMessage.value = '获取频道数据失败';
      channelOptions = [];
      channelValues = [];
    } finally {
      loading.value = false;
    }

    // console.log('channelOptions', channelOptions);

    options.value = channelOptions;
    const channelIds = channelValues.map((item) => item.id);

    publishChannel.value = [...channelIds]; // 已经上架的频道

    formState.value = {
      count: props.item.replica_count,
      channels: [...channelIds],
    };
    initialFormState.value = {
      count: props.item.replica_count,
      channels: [...channelIds],
    };

    targetKeys.value = [...channelIds];
    selectedKeys.value = [];
  }
  formRef.value?.clearValidate()?.catch(() => {});
});
</script>

<style scoped lang="scss">
.form-top {
  display: flex;
  gap: 24px;
  padding-top: 20px;
}
.form-title {
  font-weight: bold;
  margin-bottom: 20px;
}

.form-content {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.form-content .form-image {
  background-color: #f5f5f5;
  flex: 1;
  height: 200px;
}
.form-content .form-desc {
  flex: 1;
}
</style>
