<template>
  <a-modal
    v-model:open="dialogVisible"
    :mask-closable="false"
    title="用户名和密码"
    centered
    width="400px"
  >
    <div style="padding: 16px 0">
      <p style="color: #7b7b7b">请及时保存密码，页面关闭后将无法再次获取信息。</p>
      <div style="display: flex; align-items: center">
        <span style="width: 42px">用户名</span>：
        <span style="color: #7b7b7b">{{ profile.username }}</span>
      </div>
      <div style="display: flex; align-items: center">
        <span style="width: 42px; display: flex; justify-content: space-between"
          ><span>密</span><span>码</span></span
        >：
        <span style="color: #7b7b7b">{{ profile.password }}</span>
      </div>
    </div>
    <template #footer>
      <!-- <a-button key="back" @click="handleCancel">取消</a-button> -->
      <a-button key="submit" type="primary" @click="handleOk">确定</a-button>
    </template>
  </a-modal>
</template>
<script setup>
import {computed} from 'vue';

const emit = defineEmits(['visible']);

// 定义接收的 props 和 emits
const props = defineProps({
  visible: {type: Boolean, default: false},
  title: {type: String, default: ''},
  profile: {type: Object, default: () => ({username: undefined, password: undefined})},
  copyAccountInfo: {type: Function, default: () => {}},
});
const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
});
// const profile = ref({
//   username: undefined,
//   password: undefined
// });

// const handleCancel = () => {
//   dialogVisible.value = false;
// };
const handleOk = () => {
  //   dialogVisible.value = false;
  props.copyAccountInfo(props.profile);
};
</script>
