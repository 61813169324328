// @/apis/config.js
import request from '@/utils/request.js';

// 配置运维 - 人员配置 - 切换子账户二次验证的开关
export function postSwitchRamVerify(params) {
  return request({
    method: 'post',
    url: '/v2/link/my/switch/ram/verify',
    params,
  });
}
// 配置运维 - 人员配置 - 子账户列表
export function getLinkRamList(params) {
  return request({
    method: 'get',
    url: '/v2/link/ram/list',
    params: {
      current: params.current,
      size: params.size,
    },
  });
}
// 配置运维 - 人员配置 - 新建设备子账户
export function postLinkRamCreate(data) {
  return request({
    method: 'post',
    url: '/v2/link/ram/create',
    data: {
      username: data.username,
      nickname: data.nickname,
      device_uid: data.device_uid,
      mark: data.mark,
    },
  });
}
// 配置运维 - 人员配置 - 禁止/解除禁止
export function postLinkRamUpdate(data) {
  return request({
    method: 'post',
    url: '/v2/link/ram/update',
    data: {
      id: data.id,
      enable: data.enable,
    },
  });
}
// 配置运维 - 人员配置 - 重置密码
export function postLinkRamResetPassword(data) {
  return request({
    method: 'post',
    url: '/v2/link/ram/reset/password',
    data: {
      ram_id: data.id,
    },
  });
}

// 配置运维 - 人员配置 - 设备列表下拉
export function getLinkRamDeviceDropdown(params) {
  return request({
    method: 'get',
    url: '/v2/link/device/dropdown',
    params: {
      current: params.current,
      size: params.size,
    },
  });
}
// 配置运维 - 运维配置 - 设备列表
export function getLinkRamDeviceList(params) {
  return request({
    method: 'get',
    url: '/v2/link/ram/device/list',
    params: {
      current: params.current,
      size: params.size,
    },
  });
}

// 配置运维 - 运维配置 - 指派运维人员
export function getLinkRamAssign(data) {
  return request({
    method: 'post',
    url: '/v2/link/ram/assign',
    data: {
      device_uid: data.device_uid,
      ram_id: data.ram_id, // 数组 []
    },
  });
}
