<template>
  <a-table
    rowKey="channel_resource_id"
    :loading="pageLoading"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    @change="onTableChange"
    class="overflow-x__auto"
  >
    <template #headerCell="{column}">
      <span v-if="column.key" style="white-space: nowrap">{{ column.title }}</span>
    </template>
    <template #bodyCell="{column, record}">
      <template v-if="column.key === 'channel_id'">
        <div>{{ record.channel_id }}</div>
        <div>{{ record.channel_name }}</div>
      </template>
      <template v-if="column.key === 'channel_master_id'">
        <div>{{ record.channel_master_id }}</div>
        <div>{{ record.channel_contact }}</div>
      </template>
      <template v-if="column.key === 'tag'">
        <div>{{ record.tag && record.tag.join(',') }}</div>
      </template>
      <template v-if="column.key === 'block_by_nickname'">
        <div>{{ record.block_by_nickname }}</div>
        <div>{{ record.block_by }}</div>
      </template>
      <template v-if="column.key === 'channel_create_time'">
        <span style="white-space: nowrap">{{
          record.channel_create_time
            ? dayjs(record.channel_create_time).format('YYYY/MM/DD HH:mm:ss')
            : ''
        }}</span>
      </template>
      <template v-if="column.key === 'action'">
        <a-space style="white-space: nowrap">
          <a-typography-link type="link" class="btn-link" @click="showConfirm('restore', record)"
            >恢复</a-typography-link
          >
          <!-- <a-typography-link type="link" class="btn-link" disabled>查看</a-typography-link> -->
        </a-space>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import {computed, createVNode, ref} from 'vue';
import dayjs from 'dayjs';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {postLinkAuditBlockChannelResource} from '@/apis/review.js';

const props = defineProps({
  pageLoading: {type: Boolean, default: false},
  dataSource: {type: Array, default: () => []},
  query: {type: Object, default: () => ({})},
  onTableChange: {type: Function, default: () => {}},
  fetchData: {type: Function, default: () => {}},
});

const columns = computed(() =>
  [
    {
      title: '频道',
      key: 'channel_id',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '频道主',
      key: 'channel_master_id',
      align: 'center',
    },
    {
      title: '粉丝数量',
      key: 'joined_count',
      align: 'center',
      sorter: (a, b) => a.joined_count - b.joined_count,
    },
    {
      title: '作品数量',
      key: 'resource_count',
      align: 'center',
      sorter: (a, b) => a.resource_count - b.resource_count,
    },
    {
      title: '举报的tag',
      key: 'tag',
      align: 'center',
    },
    {
      title: '处理人',
      key: 'block_by_nickname',
      align: 'center',
    },
    {
      title: '申请时间',
      key: 'channel_create_time',
      align: 'center',
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const loading = ref(false);

const showConfirm = (type, record) => {
  let confirmModal = null;
  const title = '恢复频道';
  const titleText = title;
  confirmModal = Modal.confirm({
    title: titleText,
    icon: createVNode(ExclamationCircleOutlined),
    content: `你确定 ${title} 吗？`,
    okText: '确定',
    okButtonProps: {loading: loading.value},
    centered: true,
    async onOk() {
      loading.value = true;
      confirmModal.update({okButtonProps: {loading: true}});
      try {
        const response = await postLinkAuditBlockChannelResource({channel_id: record.channel_id});
        if (response.data.status === 200) {
          message.success(titleText + '成功');
          confirmModal.destroy();
        } else {
          throw response;
        }
      } catch (err) {
        console.error(err);
        message.error(err?.data?.message || titleText + '失败');
        confirmModal.update({okButtonProps: {loading: false}});
        throw err;
      } finally {
        loading.value = false;

        props.fetchData();
      }
    },
    onCancel() {},
  });
};
</script>

<style lang="scss">
.alert-wrapper {
  margin-bottom: 16px;
  margin-top: 16px;
  display: flex;
  font-size: 14px !important;
  align-items: center;
  padding: 8px 16px;
  .ant-alert-description {
    font-size: 14px;
    line-height: 1em;
  }
  .ant-alert-icon {
    font-size: 16px;
  }
}
</style>
