class EventBus {
  constructor() {
    this.events = new Map();
  }

  on(eventName, callback, options = {priority: 0, once: false}) {
    if (!this.events.has(eventName)) {
      this.events.set(eventName, []);
    }

    this.events.get(eventName).push({callback, ...options});

    // Sort listeners based on priority, higher priority comes first
    this.events.set(
      eventName,
      this.events.get(eventName).sort((a, b) => b.priority - a.priority),
    );
  }

  off(eventName, callback) {
    if (this.events.has(eventName)) {
      const eventCallbacks = this.events.get(eventName);
      this.events.set(
        eventName,
        eventCallbacks.filter((listener) => listener.callback !== callback),
      );
    }
  }

  removeAllListeners(eventName) {
    if (this.events.has(eventName)) {
      this.events.delete(eventName);
    }
  }

  emit(eventName, ...args) {
    if (this.events.has(eventName)) {
      this.events.get(eventName).forEach((listener, index) => {
        try {
          listener.callback(...args);

          // Remove listener if it's a one-time event
          if (listener.once) {
            this.events.get(eventName).splice(index, 1);
          }
        } catch (error) {
          console.error(`Error in event '${eventName}' callback:`, error);
        }
      });
    }
  }
}

export default new EventBus();
