<template>
  <a-config-provider
    :locale="zhCN"
    :theme="{
      token: {
        borderRadius: 4,
      },
    }"
  >
    <a-spin
      :delay="300"
      :spinning="spinning"
      wrapperClassName="spin-container"
      style="background: transparent"
    >
      <RouterView />
    </a-spin>
  </a-config-provider>
</template>
<script setup>
import {postRefreshToken} from '@/apis/users.js';
import {useUserStore} from '@/stores/userStore';
import {useRouter, RouterView} from 'vue-router';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import {onMounted, ref, watch} from 'vue';
import * as RouteNames from '@/router/names.js';
import {storeToRefs} from 'pinia';
import {getMobileDevice, updateLoginState} from '@/utils/utils';
import {handleLogout} from '@/utils/logout';
import useResize from '@/composable/useResize.js';
import {getPathFromUrl} from '@/utils/utils.js';
import {CHANNEL_MANAGER} from '@/router/roles';
import EventBus from '@/utils/EventBus';

dayjs.locale('zh-cn');
const userStore = useUserStore();
const router = useRouter();

const {loginState} = storeToRefs(userStore);
const spinning = ref(false);

if (!loginState.value) {
  console.log('[app.vue 1]');

  const lastVisitedRoute = sessionStorage.getItem('lastVisitedRoute');

  // UpApplicationProgressView
  // UpOwnerApplicationFormView
  // 如果是这两个页面刷新则不处理
  if (
    ![RouteNames.UpApplicationProgressView, RouteNames.UpOwnerApplicationFormView].includes(
      lastVisitedRoute,
    ) ||
    window.location.pathname === '/'
  ) {
    router.push({name: RouteNames.LoginView});
    userStore.setUserInfo(null);
  }

  console.log('lastVisitedRoute', lastVisitedRoute);
} else if (loginState.value) {
  console.log('[app.vue 2]');

  spinning.value = true;
  // const lastVisitedRoute = sessionStorage.getItem('lastVisitedRoute');
  handleRefresh(loginState.value)
    .then(() => {
      // console.log('window.location.href', window.location.href);

      const pathname = getPathFromUrl(window.location.href);

      // console.log('pathname', pathname);

      let userInfo = window.localStorage.getItem('userInfo');

      try {
        if (userInfo) {
          userInfo = JSON.parse(userInfo);
        }
      } catch (error) {
        console.error('[handleRefresh parse error]', [error]);
      }

      if (userInfo?.role === CHANNEL_MANAGER) {
        router.replace({name: RouteNames.NewUserApplicationView});
        return;
      }

      if (pathname.startsWith('/login')) {
        // 如果输入登录页面，并刷新，那么跳转设备列表页面
        router.replace({name: RouteNames.DeviceListView}); // refreshToken失败，跳转到登录页面
      }
    })
    .catch((error) => {
      console.log('error', error);
      router.push({name: RouteNames.LoginView}); // refreshToken失败，跳转到登录页面
    })
    .finally(() => {
      spinning.value = false;
    });
} else {
  // console.log('[app.vue 3]');

  router.push({name: RouteNames.LoginView}); // refreshToken失败，跳转到登录页面
}

// 刷新token并且更新登录状态
async function handleRefresh(loginState) {
  return postRefreshToken({
    grant_type: 'refresh_token',
    refresh_token: loginState?.refresh_token,
  }).then(async (response) => {
    if (response?.data?.status !== 200) {
      throw response;
    }
    const loginState = response.data.data;

    return updateLoginState({userStore, loginState});
  });
}

let timeoutId = null;

watch(
  loginState,
  (state) => {
    clearTimeout(timeoutId);
    if (!state || (state && !state.expires_in)) {
      // console.log('not refresh');
      return;
    }
    timeoutId = setTimeout(
      () => {
        // console.log('refresh', state);

        handleRefresh(state).catch(() => {
          // 自动刷新token失败 退出登录
          handleLogout();
        });
      },
      state.expires_in * 1000 - 60 * 1000, // 提前一分钟
    );
  },
  {immediate: true},
);

const {update} = useResize(({width}) => {
  const isMobile = getMobileDevice();

  let isMobileDevice;
  if (isMobile) {
    isMobileDevice = true;
    document.body.classList.add('is-mobile');
    EventBus.emit('SetCollapsed', true);
  } else {
    if (width <= 640) {
      isMobileDevice = true;
      document.body.classList.add('is-mobile');
      EventBus.emit('SetCollapsed', true);
    } else {
      isMobileDevice = false;

      document.body.classList.remove('is-mobile');
      EventBus.emit('SetCollapsed', false);
    }
  }

  userStore.setMobileDevice(isMobileDevice);
});

onMounted(() => {
  update();
});
</script>
<style></style>
