<template>
  <div class="upload-image">
    <div v-if="!src" class="upload-image__btn" @click="triggerFileInput">
      <img ondragstart="return false;" style="width: 24px" src="@/assets/upload_plus.png" />
    </div>
    <div v-else class="image-wrapper">
      <img
        :style="imgStyle"
        ref="imageRef"
        class="upload-image__inner"
        :src="src"
        ondragstart="return false"
      />
      <img
        v-if="!disabled"
        @click="handleClear"
        class="close-icon"
        width="25px"
        height="25px"
        ondragstart="return false"
        src="@/assets/close.svg"
      />
    </div>
    <input
      :key="inputImageKey"
      type="file"
      ref="fileImageInput"
      accept="image/*"
      @change="handleImageChange"
      style="display: none"
    />

    <ModalCropper
      v-if="visibleImageCropper && showCroper"
      v-model:visible="visibleImageCropper"
      :src="posterBlobUrl"
      @success="handleUploadSuccess"
      :aspectRatio="aspectRatio"
      width="385px"
      height="200px"
      type="poster"
    />
  </div>
</template>
<script setup>
import {ref} from 'vue';
import ModalCropper from '@/components/ModalCropper/index.vue';
import {message} from 'ant-design-vue';
import {postLinkUploadIcon} from '@/apis/upload';

const props = defineProps({
  src: {type: String, default: ''},
  imgStyle: {type: String, default: ''},
  aspectRatio: {type: Number, default: 385 / 200},
  showCroper: {type: Boolean, default: false},
  disabled: {type: Boolean, default: false},
});

const emits = defineEmits(['clear', 'change']);

const posterBlobUrl = ref(''); // 上传文件的blobUrl

const inputImageKey = ref(''); // 刷新
const fileImageInput = ref(''); // input
const visibleImageCropper = ref(false); // 是否显示裁切图片

const imageRef = ref();

const triggerFileInput = () => {
  if (props.disabled) {
    return;
  }
  fileImageInput.value.click();
};

const handleUploadSuccess = (url) => {
  emits('change', url);
};

const handleImageChange = async (event) => {
  const file = event.target.files[0];
  if (file && file.size <= 1024 * 1024 * 100) {
    // Check file size <= 1MB
    if (file.type.startsWith('image/')) {
      if (props.showCroper) {
        const reader = new FileReader();
        reader.onload = (e) => {
          posterBlobUrl.value = e.target.result;

          visibleImageCropper.value = true;

          inputImageKey.value = Math.random() + Date.now();
        };
        reader.readAsDataURL(file);
      } else {
        // 直接上传显示回调
        const formData = new FormData();
        const fileExtension = file.name.split('.').pop(); // 获取文件后缀
        const fileName = `${Date.now()}.${fileExtension}`; // 生成新的文件名
        formData.append('file', file, fileName); // 将文件添加到 formData 中，'file' 是后台所接受的字段名

        try {
          const response = await postLinkUploadIcon(formData);
          console.log('Upload Success:', response.data);

          if (response.data.status === 200) {
            emits('change', response?.data?.data?.icon_url);
          } else {
            throw response;
          }
          // 处理成功响应
        } catch (error) {
          console.error('Upload Failed:', [error]);
          message.error(error?.data?.message || '上传失败');
          // 处理失败响应
        } finally {
          // 如果需要重新选择文件，可以重新生成 inputImageKey 的值
          inputImageKey.value = Date.now().toString();
        }
      }
    } else {
      message.error('仅支持JPEG、PNG、BMP、WEBP等格式的文件');
      inputImageKey.value = Math.random() + Date.now();
    }
  } else {
    message.error('图片大小不得超过10MB');
    inputImageKey.value = Math.random() + Date.now();
  }
};

const handleClear = () => {
  emits('clear');
};

defineExpose({
  getImageRef: () => imageRef.value,

  getImageSize() {
    const imgElement = imageRef.value;

    const imgWidth = imgElement.naturalWidth;
    const imgHeight = imgElement.naturalHeight;
    console.log('Image Width:', imgWidth);
    console.log('Image Height:', imgHeight);
    if (imgElement) {
      return {
        width: imgWidth,
        height: imgHeight,
      };
    }
    return null;
  },
});
</script>
<style lang="scss" scoped>
.upload-image {
  display: inline-block;

  .upload-image__inner {
    width: 385px * 0.6;
    height: 200px * 0.6;
    object-fit: cover;
  }
}
.upload-image__btn {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: transparent;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.upload-image:hover {
  .image-wrapper {
    border: 1px dashed #d9d9d9;

    .close-icon {
      display: block;
    }
  }
}

.image-wrapper {
  display: inline-flex;
  border: 1px dashed transparent;
  padding: 0;
  border-radius: 8px;
  user-select: none;
  position: relative;

  .close-icon {
    position: absolute;
    right: -12px;
    top: -12px;
    z-index: 222;
    cursor: pointer;
    display: none;
    transition: all ease 0.1s;
    background-color: #fff;
    border-radius: 50%;
  }
}
</style>
