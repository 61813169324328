<template>
  <div class="login-container">
    <div class="up-form">
      <h3 class="up-form__title">社区入驻申请</h3>
      <div class="up-form__txt">申请 ID：{{ authProgressData.id }}</div>
      <a-steps class="up-form__steps" progress-dot :current="current" :items="items"></a-steps>
      <div class="up-form__txt">{{ authProgressData.name }}</div>
      <div v-if="authProgressData.createTime" class="up-form__txt">
        {{ dayjs(authProgressData.createTime).format('YYYY-MM-DD HH:mm') }}
      </div>
      <div class="up-form__desc" style="padding: 0 36px">
        <p>尊敬的影趣社的UP主：</p>
        <p>欢迎您加入影趣社！我们非常高兴能够与您一起共同构建这个充满活力和创造力的社区。</p>
        <p>
          影趣社社区致力于提供一个开放、友好和富有创意的平台，让UP与粉丝之间建立紧密联系，共同分享知识、经验和乐趣。我们相信，您的加入将为社区带来新的思维和活力，为我们带来更多精彩内容和互动。
          作为社区团队，我们将竭诚支持您的创作和发展，提供优质的服务和资源，帮助您实现更多的成就和粉丝互动。
        </p>
        <p style="margin-bottom: 40px">
          我们都欢迎您在UGC社区中找到属于自己的舞台，展现才华，获得支持。
        </p>
        <p>再次欢迎加入影趣社！让我们共同努力，共同成长，共同书写属于我们的精彩故事！</p>
      </div>

      <div style="text-align: center">
        <a-button
          v-if="authProgressData.status === 'reject'"
          type="primary"
          style="min-width: 100px"
          @click="handleReApply"
          >重新申请</a-button
        >
        <a-button
          v-else
          :disabled="current !== 3"
          type="primary"
          style="min-width: 100px"
          @click="handleLogin"
          >欢迎入驻</a-button
        >
      </div>
      <div style="text-align: right">
        <a href="/agreement.html" target="_blank" rel="noopener noreferrer">《社区公约》</a>
      </div>
    </div>

    <PageFooter style="margin-top: 0" />
  </div>
</template>

<script setup>
import {ref, onMounted, computed} from 'vue';
import PageFooter from '@/components/PageFooter/index.vue';
import {useRoute, useRouter} from 'vue-router';
import * as RouteNames from '@/router/names.js';
import {getLinkAuthProgress, postLinkAuthJoin} from '@/apis/users';
import {message} from 'ant-design-vue';
import {useUserStore} from '@/stores/userStore';
import {updateLoginState} from '@/utils/utils';
import dayjs from 'dayjs';
const userStore = useUserStore();

const router = useRouter();
const route = useRoute();

// examine-初审;reexamine-复审;approved-通过

const authProgressData = ref({
  // progress: true,
  // id: 1,
  // status: 'approved',
  // name: '民养书',
  // createTime: '2024-07-10T14:45:52+08:00',
});

const items = computed(() => {
  if (authProgressData.value.status === 'reject') {
    return [
      {title: '创建单据', key: 'create'},
      {title: '初审', key: 'examine'},
      {title: '复审', key: 'reexamine'},
      {title: '审核失败', key: 'reject'},
    ];
  }

  return [
    {title: '创建单据', key: 'create'},
    {title: '初审', key: 'examine'},
    {title: '复审', key: 'reexamine'},
    {title: '完成', key: 'approved'},
  ];
});

const current = computed(() => {
  const index = items.value.findIndex((item) => item.key === authProgressData.value.status);

  return Math.max(0, index);
});

// 重新申请
const handleReApply = () => {
  router.replace({name: RouteNames.UpOwnerApplicationFormView, query: route.query});
};

const handleLogin = async () => {
  // router.push({name: RouteNames.LoginView});
  try {
    const progressResponse = await postLinkAuthJoin({
      token: route.query.temp_token,
    });

    if (progressResponse.data.status === 200) {
      const loginState = progressResponse.data.data;
      await updateLoginState({userStore, loginState});
      router.replace({name: RouteNames.DeviceListView});
    } else {
      throw progressResponse;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.data?.message || '登录失败');
  }
};

const loadData = async () => {
  try {
    const progressResponse = await getLinkAuthProgress({token: route.query.temp_token});

    if (progressResponse.data.status === 200) {
      if (!progressResponse.data.data.progress) {
        router.replace({name: RouteNames.UpOwnerApplicationFormView, query: route.query});
      } else {
        console.log('progressResponse.data.data', progressResponse.data.data);
        authProgressData.value = progressResponse.data.data;
      }
    } else {
      throw progressResponse;
    }
  } catch (error) {
    router.replace({name: RouteNames.LoginView});
    console.error(error);
  }
};

onMounted(() => {
  loadData();
});
</script>

<style scoped lang="scss">
.login-container {
  height: 100vh;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;

  background: #f0f2f5 url('@/assets/background.svg') no-repeat 50%;
  background-size: 100%;
}

.up-form {
  margin: auto auto auto auto;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  color: #7b7b7b;
  gap: 16px;
  .up-form__title {
    font-weight: bold;
    color: #000;
  }
  .up-form__title,
  .up-form__txt {
    padding: 0 38px;
  }

  .text-indent {
    text-indent: 1em;
  }
}

.up-form__steps.ant-steps-vertical {
  padding-left: 38px;
}

@media (max-width: 980px) {
  .up-form__desc {
    padding: 0 24px;
  }
}
</style>
