<template>
  <div>
    <div class="page-content">
      <a-tabs v-model:activeKey="activeKey" @tabClick="onTabClick">
        <a-tab-pane key="applyUp" :tab="`UP主申请(${paginationApplyUp.total})`">
          <TableApplyUp
            :pageLoading="pageLoading"
            :dataSource="dataSourceApplyUp"
            :query="queryApplyUp"
            :onTableChange="onTableChange"
            :fetchData="getListApplyUp"
          />
          <div class="pagination-wrapper">
            <a-pagination
              v-model:current="paginationApplyUp.current"
              v-model:page-size="paginationApplyUp.pageSize"
              :total="paginationApplyUp.total"
              :show-total="paginationApplyUp.showTotal"
              :defaultPageSize="paginationApplyUp.defaultPageSize"
              showSizeChanger
              :pageSizeOptions="paginationApplyUp.pageSizeOptions"
              @change="paginationApplyUp.onChange"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="reportChannel" :tab="`频道举报(${paginationReportChannel.total})`">
          <TableReportChannel
            :pageLoading="pageLoading"
            :dataSource="dataSourceReportChannel"
            :query="queryReportChannel"
            :onTableChange="onTableChange"
            :fetchData="getListReportChannel"
          />
          <div class="pagination-wrapper">
            <a-pagination
              v-model:current="paginationReportChannel.current"
              v-model:page-size="paginationReportChannel.pageSize"
              :total="paginationReportChannel.total"
              :show-total="paginationReportChannel.showTotal"
              :defaultPageSize="paginationReportChannel.defaultPageSize"
              showSizeChanger
              :pageSizeOptions="paginationReportChannel.pageSizeOptions"
              @change="paginationReportChannel.onChange"
            />
          </div>
        </a-tab-pane>
        <a-tab-pane key="reportResource" :tab="`资源举报(${paginationReportResource.total})`">
          <TableReportResource
            :pageLoading="pageLoading"
            :dataSource="dataSourceReportResource"
            :query="queryReportResource"
            :onTableChange="onTableChange"
            :fetchData="getListReportResource"
          />
          <div class="pagination-wrapper">
            <a-pagination
              v-model:current="paginationReportResource.current"
              v-model:page-size="paginationReportResource.pageSize"
              :total="paginationReportResource.total"
              :show-total="paginationReportResource.showTotal"
              :defaultPageSize="paginationReportResource.defaultPageSize"
              showSizeChanger
              :pageSizeOptions="paginationReportResource.pageSizeOptions"
              @change="paginationReportResource.onChange"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import TableApplyUp from './components/TableApplyUp.vue';
import useTableApplyUp from './components/useTableApplyUp.js';
import TableReportChannel from './components/TableReportChannel.vue';
import useTableReportChannel from './components/useTableReportChannel.js';
import TableReportResource from './components/TableReportResource.vue';
import useTableReportResource from './components/useTableReportResource.js';

const pageLoading = ref(false);

const {
  pagination: paginationApplyUp,
  dataSource: dataSourceApplyUp,
  fetchData: fetchDataApplyUp,
  query: queryApplyUp,
  getList: getListApplyUp,
} = useTableApplyUp(pageLoading);
const {
  pagination: paginationReportChannel,
  dataSource: dataSourceReportChannel,
  fetchData: fetchDataReportChannel,
  query: queryReportChannel,
  getList: getListReportChannel,
} = useTableReportChannel(pageLoading);
const {
  pagination: paginationReportResource,
  dataSource: dataSourceReportResource,
  fetchData: fetchDataReportResource,
  query: queryReportResource,
  getList: getListReportResource,
} = useTableReportResource(pageLoading);

const route = useRoute();
const router = useRouter();
const activeKey = ref(route.query.activeKey || 'applyUp');

function onTabClick(event) {
  activeKey.value = event;
  router.push({query: {...route.query, activeKey: event}});
  let fetchDataFunc = () => {};
  if (event === 'applyUp') {
    fetchDataFunc = fetchDataApplyUp;
  }
  if (event === 'reportChannel') {
    fetchDataFunc = fetchDataReportChannel;
  }
  if (event === 'reportResource') {
    fetchDataFunc = fetchDataReportResource;
  }
  fetchDataFunc();
}

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  let fetchDataFunc = () => {};
  if (activeKey.value === 'applyUp') {
    fetchDataFunc = fetchDataApplyUp;

    if (action === 'sort') {
      queryApplyUp.value.sort = sorter.field;
      queryApplyUp.value.order = sorter.order;
    }

    if (action === 'filter') {
      queryApplyUp.value.status = filters.status || [];
    }
  }
  if (activeKey.value === 'reportChannel') {
    fetchDataFunc = fetchDataReportChannel;

    if (action === 'sort') {
      queryReportChannel.value.sort = sorter.field;
      queryReportChannel.value.order = sorter.order;
    }

    if (action === 'filter') {
      queryReportChannel.value.report_cnt = filters.report_cnt || [];
      queryReportChannel.value.resource_cnt = filters.resource_cnt || [];
    }
  }
  if (activeKey.value === 'reportResource') {
    fetchDataFunc = fetchDataReportResource;

    if (action === 'sort') {
      queryReportResource.value.sort = sorter.field;
      queryReportResource.value.order = sorter.order;
    }
    if (action === 'filter') {
      queryReportResource.value.report_cnt = filters.report_cnt || [];
    }
  }

  fetchDataFunc();
};

onMounted(() => {
  getListApplyUp(true);
  getListReportChannel(true);
  getListReportResource(true);
});
</script>
<style lang="scss" scoped></style>
