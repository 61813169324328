<template>
  <div>
    <h3 class="form-title">基本设置：</h3>
    <a-row :gutter="{xs: 8, sm: 16, md: 24, lg: 32}">
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">设备DID：{{ device.device_uid }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">MAC：{{ device.mac }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">SN：{{ device.sn }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">硬件版本号：{{ device.hardware_version }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">固件版本号：{{ device.firmware_version }}</div>
      </a-col>
    </a-row>
    <a-divider></a-divider>
    <h3 class="form-title">用户信息：</h3>
    <a-row :gutter="{xs: 8, sm: 16, md: 24, lg: 32}">
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">用户ID：{{ master.id }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">用户昵称：{{ master.nickname }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">注册手机号：{{ master.phone }}</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">
          激活时间：{{
            master.create_time ? dayjs(master.create_time).format('YYYY/MM/DD HH:mm:ss') : ''
          }}
        </div>
      </a-col>
    </a-row>
    <a-divider></a-divider>

    <!-- <h3 class="form-title">
      <a-space size="large">
        <span>网络功能检测</span>
        <a-button type="primary">探测</a-button>
      </a-space>
    </h3>

    <a-row :gutter="{xs: 8, sm: 16, md: 24, lg: 32}">
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">中国电信：183.17.228.112</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">下载/Mbps：308.43</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box">上传/Mbps：115.56</div>
      </a-col>
      <a-col class="gutter-row" :xs="24" :sm="24" :md="12" :lg="8" :xl="8">
        <div class="gutter-box" style="color: #f00">
          同一局域网的设备告警: 设备ID（设备昵称）、 … (支持复制粘贴)
        </div>
      </a-col>
    </a-row> -->
    <h3 class="form-title">设备详情：</h3>
    <div>
      <h3>最近7天的设备网络传送数据统计</h3>
      <DeviceNetworkChart :trafficData="traffic" />
      <!-- <div style="display: flex; flex-direction: column; gap: 16px">
        <div>近一周在线时间： 123小时34分42秒</div>
        <div>占比： 78%</div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import DeviceNetworkChart from '@/components/DeviceNetworkChart/index.vue';
import {onMounted, ref} from 'vue';
import {postDeviceDetail} from '@/apis/device';
import {useRoute} from 'vue-router';
import {message} from 'ant-design-vue';
import dayjs from 'dayjs';

const route = useRoute();

const device = ref({});
const master = ref({});
const traffic = ref({});

const loadData = async () => {
  try {
    const response = await postDeviceDetail({device_uid: route.params.device_uid});
    if (response.data.status === 200) {
      const data = response.data.data;
      device.value = data.devices;
      master.value = data.master;
      traffic.value = data.traffic;
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.message?.data || '获取设备详情失败');
  }
};

onMounted(() => {
  loadData();
});
</script>

<style scoped lang="scss">
.gutter-row {
  margin-bottom: 16px;
}
.form-title {
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}
</style>
