// usePhoneUpdate.js
import {ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {getUserProfile, sendVerificationCode, validateVerificationCode} from '@/apis/users';
import {message} from 'ant-design-vue';

export default function usePhoneUpdate(loading) {
  const userStore = useUserStore();
  const {userInfo} = storeToRefs(userStore);
  const updatePhoneVisible = ref(false);
  const updatePhoneRef = ref();
  const phoneForm = ref({phone: undefined, newPhone: undefined, code: undefined});

  const countdown = ref(0);
  const isCounting = ref(false);
  const hasBeforeSend = ref(false);
  // 开始倒计时
  function startCountdown() {
    countdown.value = 60;
    isCounting.value = true;
    const timer = setInterval(() => {
      if (countdown.value > 0) {
        countdown.value--;
      } else {
        clearInterval(timer);
        isCounting.value = false;
      }
    }, 1000);
  }

  // 发送验证码
  async function sendCode() {
    if (!phoneForm.value.newPhone) {
      message.error('请输入手机号！');
      return;
    }
    if (isCounting.value) {
      message.error('请稍候，不要重复发送验证码');
      return;
    }
    hasBeforeSend.value = true;
    loading.value = true;
    try {
      const response = await sendVerificationCode({phone: phoneForm.value.newPhone});
      if (response.data.status === 200) {
        message.success('验证码已发送');
        startCountdown(); // 触发倒计时
      } else {
        throw new Error('发送失败，请稍后再试');
      }
    } catch (error) {
      message.error(error.message || '发送验证码失败');
    } finally {
      loading.value = false;
    }
  }

  function handleOpenUpdatePhone() {
    hasBeforeSend.value = false;
    updatePhoneVisible.value = true;
    phoneForm.value = {phone: userInfo.value.phone, newPhone: undefined, code: undefined};
  }

  function handlePhoneCancel() {
    updatePhoneVisible.value = false;
    hasBeforeSend.value = false;
  }
  function handlePhoneOk() {
    updatePhoneRef.value.validate().then(async () => {
      if (!hasBeforeSend.value) {
        message.error('请先获取验证码');
        return;
      }

      const data = {phone: phoneForm.value.newPhone, code: phoneForm.value.code};
      console.log('[phoneForm.value]', data);
      loading.value = true;
      await validateVerificationCode(data)
        .then((response) => {
          if (response.data.status === 200) {
            message.success('修改手机号成功');
            updatePhoneVisible.value = false;
          } else {
            throw response;
          }
        })
        .catch((error) => {
          message.error(error?.data.message || '修改手机号失败');
        })
        .finally(() => {
          loading.value = false;
        });

      const {
        data: {data: profileData},
      } = await getUserProfile();

      userStore.setUserInfo(profileData);
    });
  }

  return {
    updatePhoneVisible,
    updatePhoneRef,
    phoneForm,
    handleOpenUpdatePhone,
    handlePhoneCancel,
    handlePhoneOk,
    sendCode,
    countdown,
    isCounting,
    hasBeforeSend,
  };
}
