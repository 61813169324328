import {ref, onMounted, onBeforeUnmount} from 'vue';
import {debounce} from '@/utils/utils.js';

export default function useResize(cb = () => {}) {
  const width = ref(window.innerWidth);
  const height = ref(window.innerHeight);

  function update() {
    width.value = window.innerWidth;
    height.value = window.innerHeight;

    cb({
      width: width.value,
      height: height.value,
    });
  }

  const debounced = debounce(update, 0);

  onMounted(() => {
    window.addEventListener('resize', debounced);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', debounced);
  });

  return {width, height, update};
}
