<template>
  <div class="sync-task-wrapper">
    <div>
      <div class="page-header">
        <div style="display: flex; gap: 16px; align-items: center">
          <span>当前所在频道：</span>

          <span class="channel-type" v-if="getChannelType(channelInfo)">{{
            getChannelType(channelInfo)
          }}</span>
          <a-select
            v-model:value="defaultChannel"
            @change="handleChange"
            placeholder="请选择"
            style="width: 180px"
          >
            <a-select-option v-for="item in channelOptions" :key="item.id" :value="item.id">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <span>加入频道人数：{{ pagination.total || 0 }}人</span>
        </div>
      </div>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <div v-if="column.key === 'channelMembers'" style="text-align: center">
            <div>{{ record.id }}</div>
            <div>{{ record.nickname }}</div>
          </div>

          <div v-if="column.key === 'channel_nickname'">
            <span
              v-if="!record.isEdit"
              class="channel-nickname"
              @click="handleClickRename(record, column)"
              >{{ record.rename }}<FormOutlined class="hidden-container" />
            </span>
            <a-input
              v-else
              :readonly="!record.isEdit"
              :data-channel_user_id="record.id"
              :maxlength="32"
              class="hidden-input-border"
              style="width: 100%; box-sizing: border-box"
              v-model:value="record.rename"
              @keydown.enter="handleKeyDown(record, column)"
              @blur="handleKeyDown(record, column)"
            />
          </div>
          <template v-if="column.key === 'role'">
            <template v-if="userInfo.role !== CHANNEL_MASTER">
              <!-- UP主 -->
              {{ getRoleName(record.role) }}
            </template>
            <template v-else>
              <!-- 非UP主 -->
              <template v-if="record.role === CHANNEL_MASTER">{{
                getRoleName(record.role)
              }}</template>
              <a-dropdown v-else>
                <span @click.prevent>
                  {{ getRoleName(record.role) }}
                  <DownOutlined class="hidden-container" />
                </span>
                <template #overlay>
                  <a-menu @click="handleRoleMenuClick($event, record)">
                    <a-menu-item
                      v-for="role in roleDownMenus"
                      :key="role.value"
                      :disabled="role.value === record.role"
                      >{{ role.text }}</a-menu-item
                    >
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
          </template>

          <template v-if="column.key === 'expired_time'">
            <template v-if="record.expired_time">
              <template v-if="isPublicChannel">永久</template>

              <template v-else-if="record.role === CHANNEL_MASTER">{{
                getRemainingDays({expired_time: record.expired_time})
              }}</template>
              <a-dropdown v-else-if="!isPublicChannel">
                <span @click.prevent>
                  {{ getRemainingDays({expired_time: record.expired_time}) }}
                  <DownOutlined class="hidden-container" />
                </span>
                <template #overlay>
                  <a-menu @click="handleExpireMenuClick($event, record)">
                    <template v-for="item in getDownMenusList(record)" :key="item.value">
                      <template v-if="item.value === 'custom'">
                        <a-menu-item :key="item.value">
                          <div @click.stop.prevent class="custom-day">
                            设置剩余到期<a-input-number
                              v-model:value="record.expireDay"
                              :min="1"
                              style="width: 60px"
                              @blur="handleExpireBlur($event, record)"
                            />天
                          </div>
                        </a-menu-item>
                      </template>
                      <template v-else>
                        <a-menu-item :key="item.value">{{ item.text }}</a-menu-item>
                      </template>
                    </template>
                  </a-menu>
                </template>
              </a-dropdown>
            </template>
            <template v-else>-</template>
          </template>
          <template v-if="column.key === 'create_time'">
            {{ record.create_time ? dayjs(record.create_time).format('YYYY-MM-DD HH:mm') : '' }}
          </template>

          <template v-if="column.key === 'action'">
            <a-space v-if="record.role !== CHANNEL_MASTER" style="white-space: nowrap">
              <a-typography-link type="danger" class="btn-link" @click="showConfirm(record)">
                移出频道</a-typography-link
              >
            </a-space>
            <template v-else>-</template>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed, createVNode, h, nextTick} from 'vue';
import dayjs from 'dayjs';
import {
  getLinkChannelInfo,
  getLinkChannelUserList,
  getLinkChannelUserRemove,
  getLinkChannelWithin,
  postLinkChannelUserUpdateExpiredTime,
  postLinkChannelUserUpdateNickname,
  postLinkChannelUserUpdateRole,
} from '@/apis/channel';
import usePagination from '@/composable/usePagination';
import {message, Modal} from 'ant-design-vue';
import {DownOutlined, ExclamationCircleOutlined, FormOutlined} from '@ant-design/icons-vue';
import {useRoute} from 'vue-router';
import router from '@/router/index.js';
import {MemberManagementView} from '@/router/names.js';
import {getChannelKeyType, getChannelType, getRemainingDays} from '../utils';
import {CHANNEL_MANAGER, CHANNEL_MASTER} from '@/router/roles';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);

const {pagination} = usePagination(getList);

const loading = ref(false);

const route = useRoute();
const defaultChannel = ref(undefined);
const channelOptions = ref([]);

const query = ref({
  role: [], //
  sort: undefined, // create_time
  order_expired_time: undefined, // desc | asc
});

const channelInfo = ref({});

const isPublicChannel = computed(() => {
  const item = channelInfo.value;
  return getChannelKeyType(item) === 'public';
});

const roleOptions = [
  {text: '普通成员', value: 'user'},
  {text: '频道管理', value: CHANNEL_MANAGER},
  {text: 'UP主', value: CHANNEL_MASTER},
];
const roleDownMenus = roleOptions.filter((item) => item.value !== CHANNEL_MASTER);

const getRoleName = (role) => {
  const item = roleOptions.find((item) => item.value === role);

  return item?.text || '';
};

const columns = computed(() => [
  {
    title: '频道成员',
    key: 'channelMembers',
    dataIndex: 'channelMembers',
    fixed: 'left',
  },
  {
    title: '频道内的昵称',
    key: 'channel_nickname',
    dataIndex: 'channel_nickname',
    align: 'center',
    width: 250,
  },
  {
    title: '身份',
    key: 'role',
    dataIndex: 'role',
    align: 'center',
    filters: [...roleOptions],
    filterMultiple: false,
    filteredValue: query.value.role,
    onFilter: (value, record) => record.role.includes(value),
  },

  {
    title: '剩余到期时间',
    key: 'expired_time',
    dataIndex: 'expired_time',
    align: 'center',
    sortOrder: query.value.order_expired_time,
    sorter: (a, b) => new Date(a.expired_time).getTime() - new Date(b.expired_time).getTime(),
    sortDirections: ['ascend', 'descend'],
  },

  {
    title: '加入频道时间',
    key: 'create_time',
    dataIndex: 'create_time',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 200,
    minWidth: 200,
    maxWidth: 200,
  },
]);

const data = ref([]);

const expireDownMenus = ref([
  {text: '续期（7天）', value: 7},
  {text: '续期（30天）', value: 30},
  {text: '续期（365天）', value: 365},
  {text: '设置永久', value: -1},
  {text: 'custom', value: 'custom'},
]);

const getDownMenusList = (record) => {
  const days = getRemainingDays({expired_time: record.expired_time, showText: false});

  if (days >= 100 * 365) {
    return expireDownMenus.value.filter((item) => ['custom', -1].includes(item.value));
  }

  return expireDownMenus.value;
};

const getExpireName = (days) => {
  const item = expireDownMenus.value.find((item) => item.value === days);
  return item?.text || `续期（${days || 0}天）`;
};

const handleExpireBlur = (event, record) => {
  // console.log('event', event);
  if (!event.target.value?.trim()) {
    return;
  }
  const days = Number(event.target.value?.trim());

  if (Number.isInteger(days)) {
    if (days <= 0) {
      message.error('剩余到期天数需大于0');
      return;
    }
    handleExpireMenuClick({key: days, type: 'custom'}, record);
  } else {
    message.error('请输入整数');
  }
};

// 下拉选中过期时间
const handleExpireMenuClick = ({key: days, type}, record) => {
  if (days === 'custom') {
    return;
  }

  const showText = `确定该成员 ${type === 'custom' ? `设置剩余到期${days}天` : getExpireName(days)}？`;

  let currentDays;

  if (type === 'custom' || days === -1) {
    currentDays = days;
  } else {
    currentDays =
      Math.max(getRemainingDays({expired_time: record.expired_time, showText: false}), 0) + days;
  }

  Modal.confirm({
    title: `设置剩余到期时间 ${record.nickname}（用户id：${record.id}）`,
    icon: createVNode(ExclamationCircleOutlined),
    closable: true,
    content: h('div', {}, [h('p', {style: ''}, showText)]),
    okText: '确定',
    okButtonProps: {danger: true},
    centered: true,
    onOk() {
      postLinkChannelUserUpdateExpiredTime({
        channel_id: channelInfo.value.id,
        user_id: record.id,
        days: currentDays,
      })
        .then((response) => {
          if (response.data.status === 200) {
            message.success('设置剩余到期时间成功');
            getList();
          } else {
            throw response;
          }
        })
        .catch((err) => {
          console.error([err]);
          message.error(err?.data?.message || '设置用户过期时间失败');
          getList();
        });
    },
    onCancel() {},
  });
};

// 下拉选中角色的
const handleRoleMenuClick = ({key: role}, record) => {
  if (record.role === role) {
    return;
  }

  Modal.confirm({
    title: `身份修改 ${record.nickname}（用户id：${record.id}）`,
    icon: createVNode(ExclamationCircleOutlined),
    closable: true,
    content: h('div', {}, [
      h(
        'p',
        {style: ''},
        `确定该成员身份由“${getRoleName(record.role)}”修改为“${getRoleName(role)}”？`,
      ),
    ]),
    okText: '确定',
    okButtonProps: {danger: true},
    centered: true,
    onOk() {
      postLinkChannelUserUpdateRole({
        channel_id: channelInfo.value.id,
        user_id: record.id,
        role: role,
      })
        .then((response) => {
          if (response.data.status === 200) {
            message.success('身份调整成功');
            getList();
          } else {
            throw response;
          }
        })
        .catch((err) => {
          console.error([err]);
          message.error(err?.data?.message || '修改名角色失败');
          getList();
        });
    },
    onCancel() {},
  });
};

// 重命名的
const handleKeyDown = (record) => {
  if (!record?.rename?.trim() || record?.rename?.trim() === record?.channel_nickname?.trim()) {
    record.isEdit = false;
    return;
  }

  record.channel_nickname = record.rename;
  // data.value = [...data.value];

  postLinkChannelUserUpdateNickname({
    channel_id: channelInfo.value.id,
    user_id: record.id,
    nickname: record?.rename?.trim(),
  })
    .then((response) => {
      if (response.data.status === 200) {
        message.success('修改频道内的昵称成功');
        getList();
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      message.error(err?.data?.message || '修改频道内的昵称失败');
      getList();
    })
    .finally(() => {
      record.isEdit = false;
    });
};
// 点击重命名
const handleClickRename = (record) => {
  record.isEdit = true;
  data.value = [...data.value];

  nextTick(() => {
    // 获取具有指定 data-device_uid 属性值的 input 元素
    const inputElement = document.querySelector(`input[data-channel_user_id="${record.id}"]`);

    // 检查元素是否存在
    if (inputElement) {
      // 将焦点设置到该元素上
      inputElement?.focus();
      // 选中该元素的内容
      inputElement.select();
    } else {
      console.log('没有找到匹配的 input 元素');
    }
  });
};

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;
    const channelId =
      defaultChannel.value || (route.query.id ? Number(route.query.id) : undefined) || undefined;
    handleChannelInfo(channelId);

    const response = await getLinkChannelUserList({
      channel_id: channelId,
      current: pagination.value.current,
      size: pagination.value.pageSize,
      role: query.value?.role.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data?.total || 0,
    };

    // const records = response.data?.data?.records || [];
    const records = (response.data?.data?.records || []).map((item) => ({
      ...item,
      rename: item.channel_nickname,
    }));

    data.value = records;
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

// 移除频道二次确认
const showConfirm = (record) => {
  Modal.confirm({
    title: `移出频道 ${record.nickname}（用户id：${record.id}）`,
    icon: createVNode(ExclamationCircleOutlined),
    closable: true,
    content: h('div', {}, [
      h(
        'p',
        {style: ''},
        `确定该成员移出 ${channelInfo.value.name}（频道id：${channelInfo.value.id}）`,
      ),
      h('p', {style: ''}, '操作后不可撤销！'),
    ]),
    okText: '确定',
    okButtonProps: {danger: true},
    centered: true,
    async onOk() {
      getLinkChannelUserRemove({
        channel_id: channelInfo.value.id,
        user_id: record.id,
      })
        .then((response) => {
          if (response.data.status === 200) {
            message.success('移出频道成功');
            fetchData();
          } else {
            throw response;
          }
        })
        .catch((err) => {
          console.error([err]);
          message.error(err?.data?.message || '移出频道失败');
          fetchData();
        });
    },
    onCancel() {},
  });
};

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.role = filters.role || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;
    query.value.order_expired_time = sorter.order;
    fetchData();
  }
};

const handleChannelInfo = (channelId) => {
  getLinkChannelInfo({id: channelId})
    .then((response) => {
      if (response.data.status === 200) {
        channelInfo.value = response.data.data;
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      channelInfo.value = {};
      message.error('频道详情获取失败，请刷新重试');
    });
};

const handleChange = async (value) => {
  await router.push({name: MemberManagementView, query: {...route.query, id: value}});
  fetchData();
};

onMounted(() => {
  getList();

  getLinkChannelWithin({current: 1, size: 100})
    .then((response) => {
      if (response.data.status === 200) {
        channelOptions.value = response?.data?.data?.records || [];
      } else {
        throw response;
      }
    })
    .catch((err) => {
      channelOptions.value = [];
      console.error([err?.message]);
    })
    .finally(() => {
      let channelId = Number(route.query.id);
      channelId = Number.isNaN(channelId) ? undefined : channelId;
      defaultChannel.value = channelId;
    });
});

onUnmounted(() => {});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.channel-type {
  background-color: #555;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
}
.custom-day {
  display: flex;
  align-items: center;
}
.channel-nickname {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  margin: 0 auto;
  min-width: 200px;
}
</style>
