<template>
  <div class="sync-task-wrapper">
    <div class="page-content">
      <a-form ref="formRef" :model="formState" autocomplete="off">
        <a-form-item label="刷新频率" name="interval">
          <a-select
            v-model:value="formState.interval"
            style="max-width: 320px"
            @change="handleIntervalChange"
          >
            <a-select-option :value="0">不刷新</a-select-option>
            <a-select-option :value="3000">3秒</a-select-option>
            <a-select-option :value="15000">15秒</a-select-option>
            <a-select-option :value="60000">60秒</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <template v-if="column.key === 'createTime'">
            {{ dayjs(record.create_time).format('YYYY-MM-DD HH:mm:ss') }}
          </template>
          <template v-if="column.key === 'taskType'">
            <template v-if="record.taskType === 'seed'">做种</template>
            <template v-else-if="record.taskType === 'disaster'">容灾</template>
          </template>
          <template v-if="column.key === 'status'">
            {{ getDeviceStatusName(record.status) }}
          </template>
          <template v-if="column.key === 'progress'">
            <div style="display: flex; align-items: center">
              <a-progress
                style="margin-bottom: 0"
                :percent="record.progress.percent"
                :status="record.progress.status"
              />
            </div>
          </template>
          <template v-if="column.key === 'size'">
            {{ bytes(record.size) }}
          </template>
          <template v-if="column.key === 'create_time'">
            {{ dayjs(record.create_time).format('YYYY-MM-DD HH:mm') }}
          </template>
          <template v-if="column.key === 'speed'">
            {{ Number(record.speed) > 0 ? `${record.speed}MB/s` : '-' }}
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed} from 'vue';
import bytes from 'bytes';
import dayjs from 'dayjs';
import {getSyncSyncingList} from '@/apis/sync';
import usePagination from '@/composable/usePagination';
import {deviceStatusOptions, getDeviceStatusName} from '@/utils/constants';

const {pagination} = usePagination(getList);

const loading = ref(false);

const formState = ref({
  interval: 0,
});

const query = ref({
  status: [], // pending-未开始;syncing-同步中;success;failed
  taskType: [], // seed-做种;disaster-容灾
  sort: undefined, // create_time
  order: undefined, // desc | asc
});

const columns = computed(() => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
  },
  // {
  //   title: '源设备',
  //   key: 'sourceDevice',
  //   dataIndex: 'sourceDevice'
  // },

  {
    title: '任务',
    key: 'task',
    dataIndex: 'task',
  },
  {
    title: '类型',
    key: 'taskType',
    dataIndex: 'taskType',
    filters: [
      {text: '做种', value: 'seed'},
      {text: '容灾', value: 'disaster'},
    ],
    filterMultiple: false,
    filteredValue: query.value.taskType,
    onFilter: (value, record) => record.taskType.includes(value),
  },

  {
    title: '状态',
    key: 'status',
    dataIndex: 'status',
    filters: deviceStatusOptions.map((item) => ({...item})),
    filterMultiple: false,
    filteredValue: query.value.status,
    onFilter: (value, record) => record.status.includes(value),
  },
  {
    title: '进度',
    key: 'progress',
    dataIndex: 'progress',
    width: 200,
  },
  {
    title: '大小',
    key: 'size',
    dataIndex: 'size',
  },
  {
    title: '速度',
    key: 'speed',
    dataIndex: 'speed',
  },
  {
    title: '创建时间',
    key: 'create_time',
    dataIndex: 'create_time',
    sortOrder: query.value.order,
    sorter: (a, b) => a.create_time - b.create_time,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '剩余时间',
    key: 'remainingTime',
    dataIndex: 'remainingTime',
    align: 'center',
  },
]);

const data = ref([]);

let intervalId = null;

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    const response = await getSyncSyncingList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // status: query.value.status,
      status: query.value.status.join('') || undefined,
      type: query.value.taskType.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response?.data?.data?.total || [],
    };

    data.value = (response?.data?.data?.records || []).map((record) => {
      // const createTime = new Date(record.create_time).getTime();
      // const currentTime = Date.now();
      // const elapsedTime = (currentTime - createTime) / 1000; // 秒数
      const downloaded = record.downloaded;
      const size = record.size;

      // 计算进度百分比
      const percent = size > 0 ? Number(((downloaded / size) * 100).toFixed(2)) : 0;

      // 计算下载速度，单位是兆/秒
      let speedInMbPerSec = record.speed ? Number(record.speed) : 0;

      speedInMbPerSec = Number.isNaN(speedInMbPerSec) ? 0 : speedInMbPerSec;

      // 计算剩余时间
      const remainingTime = calculateRemainingTime(
        record.status,
        size,
        downloaded,
        speedInMbPerSec,
      );

      return {
        id: record.id,
        taskType: record.type,
        task: record.title,
        status: record.status,
        downloaded: record.downloaded,
        size: record.size,
        create_time: record.create_time,
        speed: record.speed,
        remainingTime,
        progress: {
          percent: percent,
          status: record.status === 'failed' ? 'exception' : 'active',
        },
      };
    });
  } catch (error) {
    console.error(error);

    data.value = [];
  }
}

// 计算剩余时间的方法
const calculateRemainingTime = (status, size, downloaded, speedInMBPerSec) => {
  if (status === 'failed' || speedInMBPerSec === 0) {
    return '-';
  }

  // 将字节转换成MB
  const sizeMB = size / (1024 * 1024);
  const downloadedMB = downloaded / (1024 * 1024);

  let remainingMB = sizeMB - downloadedMB;
  remainingMB = Math.max(remainingMB, 0); // 确保remainingMB不为负数

  let remainingSec = speedInMBPerSec > 0 ? remainingMB / speedInMBPerSec : 0;
  remainingSec = Math.max(remainingSec, 0); // 确保remainingSec不为负数

  if (remainingSec > 86400) {
    const days = Math.floor(remainingSec / 86400);
    const hours = Math.floor((remainingSec % 86400) / 3600);
    return `${days}天${hours}小时`;
  } else if (remainingSec > 3600) {
    const hours = Math.floor(remainingSec / 3600);
    const minutes = Math.floor((remainingSec % 3600) / 60);
    return `${hours}小时${minutes}分`;
  } else {
    const minutes = Math.floor(remainingSec / 60);
    const seconds = Math.floor(remainingSec % 60);

    if (minutes === 0 && seconds === 0) {
      return '-';
    }
    return `${minutes}分${seconds}秒`;
  }
};

const handleIntervalChange = (value) => {
  localStorage.setItem('refreshInterval', value);

  // 清除现有的定时器
  if (intervalId) {
    clearInterval(intervalId);
  }

  // 确保时间间隔是一个有效的数值
  const interval = parseInt(value);
  if (!isNaN(interval) && interval >= 3000) {
    intervalId = setInterval(getList, interval);
  } else {
    // 如果间隔不合法或者是0，不设置新的定时器
    console.warn('Interval is not set due to invalid value:', interval);
  }
};

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.status = filters.status || [];
    query.value.taskType = filters.taskType || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;
    fetchData();
  }
};

onMounted(() => {
  const storedInterval = localStorage.getItem('refreshInterval');
  const interval = parseInt(storedInterval);
  formState.value.interval = isNaN(interval) ? 0 : interval; // 如果转换失败则使用0
  handleIntervalChange(formState.value.interval);

  loading.value = true;
  getList().finally(() => {
    loading.value = false;
  });
});

onUnmounted(() => {
  if (intervalId) {
    clearInterval(intervalId);
  }
});
</script>

<style scoped lang="scss">
.sync-task-wrapper {
  width: 100%;
}
.fileInfo-wrapper {
  display: flex;
  flex-flow: row wrap;
}
</style>
