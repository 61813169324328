<template>
  <a-layout-sider
    :collapsed="collapsed"
    @update:collapsed="handleUpdateCollapsed"
    class="main-layout-sider"
    width="var(--sider-width)"
  >
    <div class="logo-wrapper">
      <img width="22" height="22" src="@/assets/logo.png" />
      <h1 v-show="!collapsed">影趣社创作中心</h1>
      <div class="version" v-if="version" :class="{'is-collapsed': collapsed}">
        <svg
          v-if="isBeta"
          t="1723519231020"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="1453"
          width="40"
          height="40"
        >
          <path
            d="M263.04 515.84H192v80h67.84a89.6 89.6 0 0 0 36.48-7.04 37.12 37.12 0 0 0 17.92-29.44 34.56 34.56 0 0 0-14.08-32.64c-3.84-7.68-18.56-10.88-37.12-10.88z m204.8-40.32a45.44 45.44 0 0 0-32.64 10.88 47.36 47.36 0 0 0-14.72 32.64h98.56a48 48 0 0 0-51.2-43.52z m-171.52 0A37.76 37.76 0 0 0 307.2 448a29.44 29.44 0 0 0-10.88-25.6c-7.68-7.04-18.56-7.04-36.48-7.04H192V486.4h64a56.32 56.32 0 0 0 36.48-10.88zM877.44 256H146.56A147.2 147.2 0 0 0 0 402.56V768h877.44A147.2 147.2 0 0 0 1024 621.44V402.56A147.2 147.2 0 0 0 877.44 256zM328.96 610.56a104.96 104.96 0 0 1-64 14.72H153.6V380.16h113.28a94.72 94.72 0 0 1 58.88 18.56 49.92 49.92 0 0 1 17.92 43.52 64 64 0 0 1-10.88 37.12 251.52 251.52 0 0 1-29.44 21.76 69.12 69.12 0 0 1 36.48 17.92 64 64 0 0 1 11.52 40.32 46.72 46.72 0 0 1-22.4 51.2z m102.4-21.76a58.24 58.24 0 0 0 33.28 14.72 81.92 81.92 0 0 0 32.64-7.68c7.68-3.2 10.88-10.88 14.72-21.76h36.48a59.52 59.52 0 0 1-25.6 40.32 82.56 82.56 0 0 1-58.24 21.76 73.6 73.6 0 0 1-64-25.6 89.6 89.6 0 0 1-25.6-69.12 87.04 87.04 0 0 1 25.6-64 73.6 73.6 0 0 1 64-25.6 80.64 80.64 0 0 1 64 29.44 117.76 117.76 0 0 1 21.76 72.96H420.48a72.96 72.96 0 0 0 10.88 36.48z m252.8-109.44h-40.32v102.4a15.36 15.36 0 0 0 0 10.88 16 16 0 0 0 11.52 3.2h25.6v29.44h-33.28a44.8 44.8 0 0 1-32.64-10.88 35.84 35.84 0 0 1-14.72-32.64v-102.4h-30.08V448h33.28v-38.4l36.48-14.72v58.88h40.32v25.6z m186.24 145.92h-32.64v-25.6a147.2 147.2 0 0 1-25.6 21.76 128 128 0 0 1-40.32 7.68 58.88 58.88 0 0 1-44.16-14.72 49.92 49.92 0 0 1-17.92-38.4 55.04 55.04 0 0 1 25.6-51.2 128 128 0 0 1 58.24-12.8h38.4v-3.84c0-25.6-14.72-36.48-40.32-36.48a56.32 56.32 0 0 0-29.44 7.68 26.88 26.88 0 0 0-14.72 25.6h-36.48a68.48 68.48 0 0 1 25.6-48 128 128 0 0 1 55.04-14.72 85.12 85.12 0 0 1 58.24 18.56 64 64 0 0 1 14.72 47.36zM746.24 576a25.6 25.6 0 0 0 7.04 17.92 30.08 30.08 0 0 0 21.76 7.68 49.92 49.92 0 0 0 40.32-14.72 46.72 46.72 0 0 0 16.64-34.56v-10.88h-38.4c-32.64 0-47.36 14.72-47.36 36.48z"
            p-id="1454"
            fill="#fff"
          ></path>
        </svg>
        <span>v{{ version }}</span>
      </div>
    </div>

    <a-menu
      :openKeys="openKeys"
      :selectedKeys="selectedKeys"
      @update:open-keys="handleUpdateOpenKeys"
      @update:selected-keys="handleUpdateSelectedKeys"
      mode="inline"
      theme="dark"
      @click="onClickMenu"
    >
      <template v-for="menuItem in menuList" :key="menuItem.name + 't'">
        <template v-if="Array.isArray(menuItem.children) && menuItem.children.length > 0">
          <a-sub-menu :key="menuItem.name">
            <template #title>
              <span>
                <component :is="menuItem?.meta?.icon"></component>
                <span>{{ menuItem?.meta?.title }}</span>
              </span>
            </template>
            <a-menu-item v-for="child in menuItem.children" :key="child.name">
              <span class="nav-text">{{ child?.meta?.title }}</span>
            </a-menu-item>
          </a-sub-menu></template
        >
        <template v-else>
          <a-menu-item :key="menuItem.name">
            <component :is="menuItem?.meta?.icon"></component>
            <span class="nav-text">{{ menuItem?.meta?.title }}</span>
          </a-menu-item>
        </template>
      </template>
    </a-menu>
  </a-layout-sider>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';

const userStore = useUserStore();

const {version} = storeToRefs(userStore);

defineProps({
  collapsed: {type: Boolean, default: false},
  openKeys: {type: Array, default: () => []},
  selectedKeys: {type: Array, default: () => []},
  menuList: {type: Array, default: () => []},
  onClickMenu: {type: Function, default: () => {}},
});
const emit = defineEmits(['update:open-keys', 'update:selected-keys']);

const isBeta = ref(false);

const handleUpdateCollapsed = (value) => {
  emit('update:collapsed', value);
};
const handleUpdateOpenKeys = (newOpenKeys) => {
  emit('update:open-keys', newOpenKeys);
};

const handleUpdateSelectedKeys = (newSelectedKeys) => {
  emit('update:selected-keys', newSelectedKeys);
};

onMounted(() => {
  const url = 'movie.hificloud.net';
  isBeta.value = window.location.hostname !== url;
});
</script>

<style scoped lang="scss">
.version {
  position: fixed;
  bottom: 16px;
  left: 26px;
  z-index: 11;
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.version.is-collapsed {
  width: 80px;
  left: 0px;
  gap: 0px;
  flex-direction: column;
  justify-content: center;
}

.main-layout-sider {
  overflow: auto;
  .logo-wrapper {
    height: 64px;
    color: #fff;
    display: inline-flex;
    align-items: center;
    gap: 12px;

    padding-left: 28px;

    h1 {
      white-space: nowrap;
      margin: 0;
      padding: 0;
    }
  }
}
</style>
