<template>
  <div class="sync-task-wrapper">
    <div class="page-content">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>

        <template #bodyCell="{column, record}">
          <template v-if="column.key === 'taskType'">
            <template v-if="record.taskType === 'seed'">做种</template>
            <template v-else-if="record.taskType === 'disaster'">容灾</template>
          </template>
          <template v-if="column.key === 'create_time'">
            {{ record.create_time ? dayjs(record.create_time).format('YYYY-MM-DD HH:mm:ss') : '' }}
          </template>
          <template v-if="column.key === 'complete_time'">
            {{ record.complete_time }}
          </template>
          <template v-if="column.key === 'size'">
            {{ bytes(record.size) }}
          </template>
          <template v-if="column.key === 'avg_speed'">
            {{ record.avg_speed ? `${record.avg_speed}MB/s` : '-' }}
          </template>
        </template>
      </a-table>

      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref, computed} from 'vue';
import bytes from 'bytes';
import dayjs from 'dayjs';

import {getSyncSyncedList} from '@/apis/sync';
import usePagination from '@/composable/usePagination';

import {calculateElapsedTime} from '@/utils/utils.js';

const {pagination} = usePagination(getList);

const loading = ref(false);

const query = ref({
  status: [], // pending-未开始;syncing-同步中;success;failed
  taskType: [], // seed-做种;disaster-容灾
  sort: undefined, // create_time
  order: undefined, // desc | asc
});

const columns = computed(() => [
  {
    title: 'ID',
    key: 'id',
    dataIndex: 'id',
    fixed: 'left',
  },
  // {
  //   title: '源设备',
  //   key: 'sourceDevice',
  //   dataIndex: 'sourceDevice'
  // },
  {
    title: '任务',
    key: 'task',
    dataIndex: 'task',
  },
  {
    title: '类型',
    key: 'taskType',
    dataIndex: 'taskType',
    filters: [
      {text: '做种', value: 'seed'},
      {text: '容灾', value: 'disaster'},
    ],
    filterMultiple: false,
    filteredValue: query.value.taskType,
    onFilter: (value, record) => record.taskType.includes(value),
  },
  {
    title: '大小',
    key: 'size',
    dataIndex: 'size',
  },
  {
    title: '平均速度',
    key: 'avg_speed',
    dataIndex: 'avg_speed',
  },
  {
    title: '创建者',
    key: 'create_by',
    dataIndex: 'create_by',
    align: 'center',
  },
  {
    title: '创建时间',
    key: 'create_time',
    dataIndex: 'create_time',
    sortOrder: query.value.order,
    sorter: (a, b) => a.create_time - b.create_time,
    sortDirections: ['ascend', 'descend'],
  },
  {
    title: '完成时间',
    key: 'complete_time',
    dataIndex: 'complete_time',
    align: 'center',
  },
]);

const data = ref([]);

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.status = filters.status || [];
    query.value.taskType = filters.taskType || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;
    fetchData();
  }
};

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;
    const response = await getSyncSyncedList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // status: query.value.status,
      // status: query.value.status.join('') || undefined,
      type: query.value.taskType.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data.total,
    };

    data.value = response.data.data.records.map((record) => {
      const downloaded = record.downloaded;
      const size = record.size;

      // 计算进度百分比
      const percent = size > 0 ? (downloaded / size) * 100 : 0;

      const complete_time = record.create_time
        ? calculateElapsedTime(new Date(record.create_time), record.cost_time)
        : '';

      return {
        id: record.id,
        taskType: record.type,
        task: record.title,
        status: record.status,
        downloaded: record.downloaded,
        size: record.size,
        create_time: record.create_time,
        speed: record.speed,
        avg_speed: record.avg_speed,
        create_by: record.create_by,
        complete_time,
        progress: {
          percent: percent,
          status: record.status === 'failed' ? 'exception' : 'active',
        },
      };
    });
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

onMounted(() => {
  getList(); // Initial load
});
</script>

<style scoped lang="scss">
.sync-task-wrapper {
  width: 100%;
}
</style>
