<template>
  <a-modal
    width="560px"
    centered
    :open="dialogVisible"
    title="图片裁切"
    :closable="!loading"
    :maskClosable="false"
    @cancel="handleCancel"
    destroyOnClose
  >
    <div class="image-cropper__container" :class="{'is-poster': type === 'poster'}">
      <div class="image-cropper__wrapper">
        <img v-if="inputUrl" ref="imageRef" :src="inputUrl" />
      </div>
      <div class="image-cropper__img-preview" :style="{flex: `0 0 ${width}`, width, height}"></div>
    </div>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button key="submit" type="primary" :loading="loading" @click="handleOk" :disabled="loading"
        >确定</a-button
      >
    </template>
  </a-modal>
</template>
<script setup>
import Cropper from 'cropperjs';
import {computed, nextTick, onMounted, ref, watch} from 'vue';
import {postLinkUploadIcon} from '@/apis/upload';
import {message} from 'ant-design-vue';
const props = defineProps({
  type: {String, default: 'logo'}, // logo / poster
  src: {type: String, default: '', required: true},
  visible: {type: Boolean, default: false},
  aspectRatio: {type: Number, default: 1},
  width: {type: String, default: '166px'},
  height: {type: String, default: '166px'},
});
const emits = defineEmits(['update:visible', 'success']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});
const loading = ref(false);

const cropperInstance = ref(null);

const imageRef = ref(null);

const inputUrl = computed(() => props.src);

const handleCancel = () => {
  dialogVisible.value = false;
};

const getImageBlob = (cropper) => {
  return new Promise((resolve) => {
    const option =
      props.type === 'poster'
        ? {width: 385 * 3, height: 200 * 3}
        : {width: 166 * 2, height: 166 * 2};
    const canvas = cropper?.getCroppedCanvas(option);
    canvas.toDataURL();
    canvas.toBlob((blob) => {
      resolve(blob);
    });
  });
};

const handleOk = async () => {
  loading.value = true;
  try {
    const cropper = cropperInstance.value;
    const blob = await getImageBlob(cropper);
    const formData = new FormData();
    formData.append('file', blob, Date.now() + '.png');

    const response = await postLinkUploadIcon(formData);

    if (response.data.status === 200) {
      const url = response?.data?.data?.icon_url;

      emits('success', url);
      handleCancel();
    } else {
      throw response;
    }
  } catch (error) {
    message.error(error?.data?.message || '上传图片失败');
    console.error([error]);
  } finally {
    loading.value = false;
  }
};

watch(dialogVisible, (visible) => {
  if (!visible) {
    cropperInstance.value?.destroy();
  }
});

watch(
  inputUrl,
  (value) => {
    if (!value) {
      return;
    }
    nextTick(() => {
      const image = imageRef.value;

      const cropper = new Cropper(image, {
        aspectRatio: props.aspectRatio,
        preview: '.image-cropper__img-preview',
      });

      cropperInstance.value = cropper;
    });
  },
  {
    immediate: true,
  },
);

onMounted(() => {});
</script>
<style lang="scss">
.is-mobile {
  .image-cropper__container {
    flex-flow: column wrap;
  }
}
.image-cropper__container {
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
}
.image-cropper__wrapper {
  flex: 0 0 300px;
  width: 300px;
  height: 300px;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');

  img {
    width: inherit;
    height: inherit;
  }
}
.image-cropper__container.is-poster {
  .image-cropper__wrapper {
    // flex: 0 0 385px;
    // width: 385px;
    // height: 200px;
    flex: 0 0 512px;
    width: 512px;
    height: 266px;
  }
}

.image-cropper__img-preview {
  flex: 0 0 166px;
  width: 166px;
  height: 166px;
  overflow: hidden;
  background-color: #f7f7f7;

  img {
    object-fit: cover;
  }
}
</style>
