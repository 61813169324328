import {getNextId} from '@/utils/utils';

export function organizeFiles(files) {
  const folderMap = new Map();

  files.forEach((file) => {
    const pathParts = (file.myRelativePath || file.webkitRelativePath).split('/');
    const fileName = pathParts.pop();
    const folderPath = pathParts.join('/');

    if (!folderMap.has(folderPath)) {
      folderMap.set(folderPath, {type: 'folder', nextId: getNextId(), path: folderPath, files: []});
    }

    folderMap.get(folderPath).files.push({
      type: 'file',
      nextId: getNextId(),
      name: fileName,
      path: file.myRelativePath || file.webkitRelativePath,
      file,
    });
  });

  let organizedFiles = [];
  for (let folder of folderMap.values()) {
    organizedFiles.push({type: 'folder', path: folder.path});
    organizedFiles = organizedFiles.concat(folder.files);
  }

  return organizedFiles;
}
