<template>
  <div class="footer">
    <div class="footer__links">
      <a class="footer__link" href="/privacy.html" target="_blank">隐私</a>
      <a class="footer__link" href="/agreement.html" target="_blank">条款</a>
    </div>
    <div class="footer__copyright">copyright©2024深圳市云存宝技术有限公司</div>
  </div>
</template>
<script setup></script>

<style lang="scss" scoped>
.footer {
  margin-top: auto;
  margin-bottom: 32px;
  text-align: center;
  .footer__links {
    display: flex;
    gap: 20px;
    justify-content: center;
    margin-bottom: 14px;
  }
}
</style>
