// @/utils/request.js

import axios from 'axios';
import {useUserStore} from '@/stores/userStore';

let isRefreshing = false; // 表示是否正在进行refreshToken请求
let failedQueue = []; // 保存等待的请求

const URL_REFRESH_TOKEN_PATH = '/v2/link/auth/access_token';

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// 创建实例时配置默认值
const instance = axios.create({
  baseURL: import.meta.env.VITE_API_SERVER_URL,
});

// 添加请求拦截器
instance.interceptors.request.use(
  async function (config) {
    const userStore = useUserStore();
    // 在发送请求之前做些什么
    const loginState = userStore.loginState;
    if (loginState?.access_token && config.url !== URL_REFRESH_TOKEN_PATH) {
      config.headers.Authorization = `${loginState.token_type} ${loginState.access_token}`;
    }

    if (config.url === URL_REFRESH_TOKEN_PATH) {
      isRefreshing = true;
    }

    if (isRefreshing && config.url !== URL_REFRESH_TOKEN_PATH) {
      return new Promise((resolve, reject) => {
        failedQueue.push({resolve, reject});
      }).then((token) => {
        config.headers.Authorization = `${loginState.token_type} ${token}`;
        return config;
      });
    }

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  },
);

// 添加响应拦截器
instance.interceptors.response.use(
  function (response) {
    if (response.config.url === URL_REFRESH_TOKEN_PATH) {
      isRefreshing = false;
      const loginState = response?.data?.data;
      processQueue(null, loginState?.access_token);
    }
    return response;
  },
  function (error) {
    console.log('error', error);

    if (error.config.url === URL_REFRESH_TOKEN_PATH) {
      isRefreshing = false;
      processQueue(error, null);
    }

    return Promise.reject(error);
  },
);

export default instance;
