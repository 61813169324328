// src/versionService.js
import {useUserStore} from '@/stores/userStore';
import axios from 'axios';

let currentVersion = null;

export const getVersion = async () => {
  try {
    const response = await axios.get('/version.json');
    return response.data.version;
  } catch (error) {
    console.error('Failed to fetch version:', error);
    return null;
  }
};

export const checkForUpdate = async () => {
  const userStore = useUserStore();
  const latestVersion = await getVersion();
  if (currentVersion && latestVersion && currentVersion !== latestVersion) {
    return true;
  }
  currentVersion = latestVersion;

  userStore.setVersion(currentVersion);
  return false;
};
