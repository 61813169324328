<template>
  <div>
    <div class="page-content">
      <h3 class="form-title">基本设置：</h3>
      <a-form layout="vertical" autocomplete="off">
        <a-form-item label="ID" :colon="false">
          <a-input type="text" :value="userInfo.id" readonly style="max-width: 400px" />
        </a-form-item>
        <a-form-item label="" :colon="false">
          <div style="display: flex; align-items: center; gap: 8px">
            <UserOutlined /> <span>{{ getRoleName(userInfo.role) }}</span>
          </div>
        </a-form-item>
        <a-form-item label="昵称" :colon="false">
          <a-input type="text" :value="userInfo.nickname" readonly style="max-width: 400px" />
          <a-button type="link" title="修改昵称" @click="handleOpenUpdateNick" style="outline: none"
            ><FormOutlined
          /></a-button>
        </a-form-item>
        <a-form-item label="密码" :colon="false">
          <a-button type="default" @click="handleOpenChangePassword" style="width: 200px"
            >修改密码</a-button
          >
        </a-form-item>
      </a-form>
      <h3 class="form-title">安全设置：</h3>
      <a-form layout="vertical" autocomplete="off">
        <a-form-item label="安全手机" :colon="true">
          <div
            style="
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              max-width: 400px;
            "
          >
            <span>登录时是否开启验证码验证</span
            ><a-switch
              :disabled="[CHANNEL_MANAGER, DEVICE_MANAGER].includes(userInfo.role)"
              v-model:checked="userInfo.verify"
              @change="onChangeVerify"
            />
          </div>
        </a-form-item>
        <a-form-item label="手机号" :colon="true">
          <a-input
            type="text"
            :value="userInfo.phone + '（需要短信验证）'"
            readonly
            style="max-width: 400px"
          />
          <a-button
            :disabled="[CHANNEL_MANAGER, DEVICE_MANAGER].includes(userInfo.role)"
            type="link"
            title="修改手机号"
            @click="handleOpenUpdatePhone"
            style="outline: none"
            ><FormOutlined
          /></a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-modal
      v-model:open="updateNickNameVisible"
      width="400px"
      title="修改昵称"
      destroyOnClose
      :mask-closable="false"
    >
      <a-form
        style="padding: 40px 0px 20px 0px"
        ref="updateNicknameRef"
        layout="vertical"
        :model="nickNameForm"
        name="updateNicknameRef"
        autocomplete="on"
      >
        <a-form-item label="" name="nickname" :rules="[{required: true, message: '请输入昵称'}]">
          <a-input v-model:value="nickNameForm.nickname" @keydown.enter="handleNickNameOk" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handlePassNickNameCancel">取消</a-button>
        <a-button type="primary" @click="handleNickNameOk">确认</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model:open="updatePhoneVisible"
      width="400px"
      title="修改手机号"
      destroyOnClose
      :mask-closable="false"
    >
      <a-form
        ref="updatePhoneRef"
        layout="vertical"
        :model="phoneForm"
        name="updatePhoneRef"
        autocomplete="on"
      >
        <a-form-item
          label="手机号"
          name="phone"
          :rules="[{required: false, message: '请输入新的手机号'}]"
        >
          <a-input v-model:value="phoneForm.phone" @keydown.enter="handlePhoneOk" readonly />
        </a-form-item>
        <a-form-item
          label="新的手机号"
          name="newPhone"
          :rules="[
            {required: true, message: '请输入新的手机号'},
            {pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号'},
          ]"
        >
          <a-input v-model:value="phoneForm.newPhone" @keydown.enter="handlePhoneOk" allowClear />
        </a-form-item>
        <a-form-item
          label="验证码"
          name="code"
          :rules="[
            {required: true, message: '请输入验证码'},
            {pattern: /^\d{6}$/, message: '验证码必须是6位数字'},
          ]"
        >
          <div class="verification-input">
            <a-input v-model:value="phoneForm.code" @keydown.enter="handlePhoneOk" />
            <a-button class="code-btn" type="link" @click="sendCode" :disabled="isCounting">{{
              isCounting ? `${countdown}s` : hasBeforeSend ? '重新发送' : '获取验证码'
            }}</a-button>
          </div>
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handlePhoneCancel">取消</a-button>
        <a-button type="primary" @click="handlePhoneOk">确认</a-button>
      </template>
    </a-modal>
    <a-modal
      v-model:open="changePasswordVisible"
      width="400px"
      title="修改密码"
      destroyOnClose
      :mask-closable="false"
    >
      <a-form
        ref="changePasswordRef"
        layout="vertical"
        :model="formState"
        name="changePassword"
        autocomplete="off"
      >
        <a-form-item
          label="旧密码"
          name="oldPassword"
          :rules="[{required: true, message: '请输入旧密码'}]"
        >
          <a-input-password v-model:value="formState.oldPassword" />
        </a-form-item>
        <a-form-item label="新密码" name="newPassword" :rules="passwordRules">
          <a-input-password v-model:value="formState.newPassword" />
        </a-form-item>
        <a-form-item label="确认密码" name="confirmPassword" :rules="confirmPasswordRules">
          <a-input-password v-model:value="formState.confirmPassword" />
        </a-form-item>
      </a-form>
      <template #footer>
        <a-button @click="handlePasswordCancel">取消</a-button>
        <a-button type="primary" @click="handlePasswordOk">确认</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import {DEVICE_MANAGER, CHANNEL_MANAGER, getRoleName} from '@/router/roles.js';
import {getUserProfile, postUpdateSwitchVerify} from '@/apis/users';
import {message} from 'ant-design-vue';
import {UserOutlined, FormOutlined} from '@ant-design/icons-vue';
import useNickName from './useNickName';
import usePassowrd from './usePassword';
import usePhoneUpdate from './usePhoneUpdate';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);
// 定义接收的 props 和 emits
defineProps({
  title: {type: String, default: ''},
});

const loading = ref(false);

const onChangeVerify = async (checked) => {
  loading.value = true;
  await postUpdateSwitchVerify({verify: checked})
    .then((response) => {
      if (response.data.status === 200) {
        message.success(checked ? '开启二次验证成功' : '关闭二次验证成功');
      } else {
        throw response;
      }
    })
    .catch((error) => {
      message.error(error?.data.message || '开启二次验证失败');
    })
    .finally(() => {
      loading.value = false;
    });

  const {
    data: {data: profileData},
  } = await getUserProfile();

  userStore.setUserInfo(profileData);
};

const {
  formState,
  changePasswordRef,
  changePasswordVisible,
  handleOpenChangePassword,
  handlePasswordCancel,
  handlePasswordOk,
  passwordRules,
  confirmPasswordRules,
} = usePassowrd();

const {
  updateNickNameVisible,
  updateNicknameRef,
  nickNameForm,
  handleOpenUpdateNick,
  handlePassNickNameCancel,
  handleNickNameOk,
} = useNickName(loading);
const {
  updatePhoneVisible,
  updatePhoneRef,
  phoneForm,
  handleOpenUpdatePhone,
  handlePhoneCancel,
  handlePhoneOk,
  sendCode,
  countdown,
  isCounting,
  hasBeforeSend,
} = usePhoneUpdate(loading);

onMounted(async () => {
  const {
    data: {data: profileData},
  } = await getUserProfile();

  userStore.setUserInfo(profileData);
});
</script>
<style lang="scss" scoped>
.verification-input {
  position: relative;
  .code-btn {
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 2;
    transform: translateY(-50%);
  }
}

.form-title {
  font-weight: bold;
  color: #000;
}
</style>
