<template>
  <a-modal
    width="900px"
    :open="dialogVisible"
    :maskClosable="false"
    :closable="!loading"
    :footer="null"
    title="导入资源"
    centered
    @ok="handleOk"
    @cancel="handleCancel"
    destroyOnClose
  >
    <a-spin :spinning="loading && current === 0">
      <div class="add-resource__steps">
        <a-steps
          v-model:current="current"
          disabled
          :items="[
            {
              title: '选择资源',
              disabled: true,
            },
            {
              title: '确认资源',
              disabled: true,
            },
            {
              title: '导入完成',
              disabled: true,
            },
          ]"
        >
        </a-steps>
      </div>
      <div class="add-resource__form" :class="{'is-type-other': formState.type === 'other'}">
        <a-form
          :model="formState"
          ref="formRef"
          name="basic"
          autocomplete="off"
          :label-col="{style: {width: '80px'}}"
          labelAlign="left"
        >
          <div class="add-resource__mode-wrap">
            <a-form-item
              label="上传模式"
              name="mode"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-select
                v-model:value="formState.mode"
                placeholder="请选择"
                allowClear
                style="width: 320px"
                class="add-resource__select"
                :disabled="current !== 0"
              >
                <!-- <a-select-opt-group>
                <template #label>
                  <span class="add-resource__select-label">网络链接</span>
                </template>
                <a-select-option value="miniprogram" class="add-resource__select-option"
                  >小程序链接</a-select-option
                >
                <a-select-option value="link" class="add-resource__select-option"
                  >下载链接</a-select-option
                >
              </a-select-opt-group> -->
                <a-select-opt-group>
                  <template #label>
                    <span class="add-resource__select-label">本地</span>
                  </template>
                  <a-select-option value="local" class="add-resource__select-option"
                    >本地文件</a-select-option
                  >
                </a-select-opt-group>
              </a-select>
            </a-form-item>
          </div>
          <div v-if="current === 0 && formState.mode === 'local'" class="add-resource__node-wrap">
            <a-form-item
              label="加速节点"
              name="node"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-select
                v-model:value="formState.node"
                placeholder="请选择"
                allowClear
                style="width: 320px"
                class="add-resource__select"
                @change="onChangeDevice"
                notFoundContent="未发现局域网设备"
              >
                <a-select-option
                  v-for="device in sameDevices"
                  :key="device.device_uid"
                  class="add-resource__select-option"
                >
                  {{ device.device_sid }}{{ device.name ? `（${device.name}）` : '' }}
                </a-select-option>
                <!-- <a-select-option value="63ecc21539e8c9442ff56826" class="add-resource__select-option"
                >63ecc21539e8c9442ff56826（李钟意）</a-select-option
              > -->
              </a-select>
            </a-form-item>
            <a-form-item
              label="设备信息"
              :label-col="{style: {width: '80px', visibility: 'hidden'}}"
            >
              <div v-if="fastDeviceInfo" class="add-resource__node-info">
                <div class="add-resource__node-info-text">
                  {{ fastDeviceInfo.did
                  }}<template v-if="fastDeviceOption.name">（{{ fastDeviceOption.name }}）</template
                  >剩余磁盘空间：
                </div>
                <div
                  v-for="sataItem in fastDeviceInfo.sata_info"
                  :key="sataItem.uuid"
                  class="add-resource__node-info-text"
                >
                  {{ sataItem.name }}：{{ bytes(sataItem.size - sataItem.usedSize) }}
                </div>
                <div v-if="formState.folderName" class="add-resource__node-info-text">
                  待进行任务需要{{ bytes(totalSize) }}空间
                </div>
              </div>
            </a-form-item>
            <a-form-item
              label="文件"
              name="folderName"
              :rules="[{validator: validateFolderName}]"
              :label-col="{style: {width: '80px'}}"
            >
              <!-- :label-col="{style: {width: '120px', visibility: 'hidden', display: 'none'}}" -->
              <a-input v-show="false" v-model:value="formState.folderName"></a-input>
              <div
                @drop="dropHandler"
                @dragover="dragOverHandler"
                class="add-resource__upload-area"
                @click="onClickUploadType"
              >
                <img src="@/assets/upload_space.png" class="add-resource__upload-img" />
                <div class="add-resource__upload-text">点击或将文件/文件夹拖拽到这里上传</div>
              </div>
              <div v-if="allItems.length > 0" class="add-resource__upload-item">
                <img src="@/assets/upload_link.png" class="add-resource__upload-img" />
                <div class="add-resource__upload-info">
                  <div>{{ formState.name }}</div>
                  <div>
                    总大小：{{ bytes(totalSize)
                    }}<template v-if="folderCounter > 0">，{{ folderCounter }}个文件夹</template
                    >，{{ allItems.length - folderCounter }}个文件
                  </div>
                </div>
                <div @click="handleClearFolder" class="add-resource__upload-remove">
                  <CloseOutlined />
                </div>
              </div>
            </a-form-item>
          </div>
          <template v-if="current === 0 && formState.mode === 'miniprogram'">
            <a-form-item
              label="链接"
              name="link"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-input
                v-model:value="formState.link"
                placeholder="请输入小程序链接"
                style="max-width: 420px"
              ></a-input>
            </a-form-item>
          </template>
          <template v-if="current === 1 && formState.mode === 'local'">
            <a-form-item
              label="类型"
              name="type"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-select
                :disabled="loading"
                v-model:value="formState.type"
                placeholder="请选择"
                style="width: 320px"
                class="add-resource__select"
                @change="handleTypeChange"
              >
                <a-select-option value="movie" class="add-resource__select-option"
                  >电影</a-select-option
                >
                <a-select-option value="other" class="add-resource__select-option"
                  >其他</a-select-option
                >
              </a-select>
            </a-form-item>
            <a-form-item
              label="副本数量"
              name="count"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请输入'}]"
            >
              <a-input-number
                :disabled="loading"
                v-model:value="formState.count"
                :min="3"
                :max="10"
              />
            </a-form-item>

            <a-form-item
              label="标题"
              name="title"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请输入标题'}]"
            >
              <template v-if="formState.type === 'other'">
                <a-input
                  :disabled="loading"
                  v-model:value="formState.title"
                  style="width: 320px"
                  allowClear
                  placeholder="请输入标题"
                ></a-input>
              </template>
              <template v-else>
                <a-select
                  :disabled="loading"
                  v-model:value="formState.title"
                  show-search
                  placeholder="请输入标题"
                  style="width: 320px"
                  :default-active-first-option="false"
                  allowClear
                  :show-arrow="true"
                  :filter-option="false"
                  :not-found-content="notFoundContent"
                  @search="handleSearch"
                  @change="handleChange"
                >
                  <a-select-option
                    v-for="option in options"
                    :key="option.ycb_mv_id"
                    :value="option.ycb_mv_id"
                    >{{ option.title
                    }}{{ option.year ? `（${option.year}）` : '' }}</a-select-option
                  >
                </a-select>
              </template>
            </a-form-item>

            <a-form-item
              label="简介"
              name="description"
              :label-col="{style: {width: '80px'}}"
              :rules="[
                {required: true, message: '请输入简介'},
                {min: 5, message: '简介至少需要5个字符'},
                {max: 500, message: '简介不能超过500个字符'},
              ]"
            >
              <a-textarea
                :disabled="loading"
                v-model:value="formState.description"
                style="width: 320px"
                show-count
                :maxlength="500"
                auto-size
              ></a-textarea>
            </a-form-item>
            <a-form-item
              label="海报"
              name="poster"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请上传海报'}]"
              class="form-item-poster"
            >
              <div>
                <a-input v-show="false" v-model:value="formState.poster"></a-input>
                <div
                  v-if="!formState.poster"
                  class="add-resource__upload-btn"
                  @click="triggerFileInput"
                >
                  <img
                    ondragstart="return false;"
                    style="width: 24px"
                    src="@/assets/upload_plus.png"
                  />
                </div>

                <div v-else class="image-wrapper">
                  <img
                    style="width: 385px; object-fit: cover"
                    :src="formState.poster"
                    ondragstart="return false"
                    ref="postImageRef"
                  />
                  <img
                    @click="handleClearImage"
                    class="close-icon"
                    width="25px"
                    height="25px"
                    ondragstart="return false"
                    src="@/assets/close.svg"
                  />
                </div>
              </div>

              <div v-if="formState.type === 'other' && !formState.poster" class="logo-summary">
                请上传海报，文件大小不得超过10MB。选择清晰、高质量的图片，文件格式支持JPEG、PNG、BMP、WEBP等常见格式。
              </div>

              <ul class="upload-wrap">
                <li v-for="item in taskList" :key="item.tid" class="upload-folder">
                  <div class="upload-content">
                    <div class="upload-title">{{ item.name }}</div>
                    <div class="upload-progress">
                      <a-progress
                        :percent="item.progress"
                        :showInfo="false"
                        size="small"
                        :status="item.status === 'uploaded' ? '' : 'active'"
                      />
                    </div>
                    <div
                      class="upload-status"
                      :class="{uploadError: item.status === 'uploadError'}"
                    >
                      <template v-if="item.status === 'uploadError'">上传失败</template>
                      <template v-else-if="item.formattedTxt">
                        <div style="display: flex; flex-flow: row nowrap">
                          <span
                            style="text-align: right"
                            :style="{minWidth: getWidth(item.formattedUploaded.value)}"
                            >{{ item.formattedUploaded.value }}</span
                          >
                          <span>{{ item.formattedUploaded.unit }}</span>
                          <span> / </span>
                          <span>{{ item.formattedTotal.value }}{{ item.formattedTotal.unit }}</span>
                          <span style="width: 20px"></span>
                          <span
                            :style="{minWidth: getWidth(item.formattedSpeed.value)}"
                            style="text-align: right"
                            >{{ item.formattedSpeed.value }}</span
                          >
                          <span>{{ item.formattedSpeed.unit }}</span>
                          <span>/s</span>
                          <template v-if="item.formattedProgress">
                            <span>(</span>
                            <span style="text-align: right; min-width: 18px">{{
                              item.formattedProgress
                            }}</span>
                            <span>%)</span>
                          </template>
                          <template v-if="item.formattedRemaining">
                            <span style="padding: 0 8px">-</span>
                            <!-- <span>{{ item.formattedRemaining }}</span> -->
                            <span
                              v-html="item.formattedRemaining"
                              style="display: inline-flex"
                            ></span>
                          </template>
                        </div>

                        <!-- {{ item.formattedTxt }} -->
                      </template>
                    </div>
                  </div>
                  <!-- <div class="button-group">
                  <a-button type="text" class="ignore-drag" @click="onCancelTask(item)"
                    ><CloseOutlined
                  /></a-button>
                </div> -->
                </li>
              </ul>
            </a-form-item>
          </template>
          <template v-if="current === 1 && formState.mode === 'miniprogram'">
            <a-form-item
              label="副本数量"
              name="count"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-input-number v-model:value="formState.count" :min="1" />
            </a-form-item>
            <div>合计选中1个文件，共计23.48GB</div>
            <a-form-item
              name="resource"
              :label-col="{style: {width: '80px'}}"
              :rules="[{required: true, message: '请选择'}]"
            >
              <a-checkbox-group v-model:value="formState.resource" style="width: 100%">
                <a-row>
                  <a-col :span="8">
                    <a-checkbox value="A">A</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="B">B</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="C">C</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="D">D</a-checkbox>
                  </a-col>
                  <a-col :span="8">
                    <a-checkbox value="E">E</a-checkbox>
                  </a-col>
                </a-row>
              </a-checkbox-group>
            </a-form-item>
          </template>
          <template v-if="current === 2">
            <h2>
              发布到 {{ getDeviceSid(formState.node)
              }}<template v-if="fastDeviceOption.name">（{{ fastDeviceOption.name }}）</template>
              合计{{ bytes(totalSize) }}
              内容：
            </h2>

            <div>资源ID | 标题</div>
            <div>{{ formState.resource_id }}：{{ hitOption.title }}</div>
            <!-- <div>资源ID：蔡琴无损音乐大全集</div> -->

            <h2>
              创建下列同步任务，具体进展请到<RouterLink :to="{name: TaskListView}"
                >【同步任务】</RouterLink
              >中去查看：
            </h2>

            <template v-if="createSyncInfo && createSyncInfo.sync_id">
              <div>任务ID | 任务名称</div>
              <div>{{ createSyncInfo.sync_id }}：{{ createSyncInfo.title }}</div>
            </template>
          </template>
        </a-form>
      </div>
      <div class="add-resource__next">
        <a-space size="large">
          <a-button
            :style="{visibility: current > 0 && current < 2 ? 'visible' : 'hidden'}"
            type="default"
            :disabled="loading"
            @click="handlePrev"
            >上一步</a-button
          >
          <a-button
            v-if="current < 2"
            type="primary"
            :loading="loading"
            :disabled="loading"
            @click="handleNext"
            >下一步</a-button
          >

          <a-button type="primary" v-if="current >= 2" @click="handleNextOk">确定</a-button>
        </a-space>

        <input
          :key="inputImageKey"
          type="file"
          ref="fileImageInput"
          accept="image/*"
          @change="handleImageChange"
          style="display: none"
        />
      </div>
    </a-spin>
  </a-modal>

  <ModalCropper
    v-if="visibleImageCropper"
    v-model:visible="visibleImageCropper"
    :src="formState.posterBlobUrl"
    @success="handleUploadSuccess"
    :aspectRatio="385 / 200"
    width="385px"
    height="200px"
    type="poster"
  />
</template>

<script setup>
import {computed, nextTick, ref, watch, createVNode, h} from 'vue';
import {ExclamationCircleOutlined, CloseOutlined} from '@ant-design/icons-vue';
import bytes from 'bytes';
import {getNextId, replaceSpecialChars, getCurrentFormattedTime} from '@/utils/utils';
import ModalCropper from '@/components/ModalCropper/index.vue';
import {
  getMvsMvKeyword,
  startUploadTask,
  uploadFileUsingPOST,
  createFolder,
  checkUploadUsingHEAD,
  continueUploadUsingPATCH,
  endUploadTask,
  getResourceSizeCheck,
  getAuthXDevice,
  getDeviceSameWanIp,
  getResourceCreate,
  getResourceRunningTaskInfo,
  getResourceRemoveRunningTask,
  getMvsMvFilepath,
} from '@/apis/media';
import {getFastDeviceInfo} from '@/apis/device';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';
import TaskManager from '@/utils/TaskManager';
// import EventBus from '@/utils/EventBus';
import {message, Modal} from 'ant-design-vue';
import useSelectFileOrFolder from '@/composable/useSelectFileOrFolder';
import useDropFile from '@/composable/useDropFile';
import {TaskListView} from '@/router/names.js';

const {showConfirm} = useSelectFileOrFolder();

const props = defineProps({
  visible: {type: Boolean, default: false},
  allItems: {type: Array, default: () => []},
});

const emits = defineEmits([
  'update:visible',
  'clickFolderInput',
  'clickFileInput',
  'success',
  'clearFolder',
  'changeDropFile', // 拖放文件的时候会触发
]);

const {dragOverHandler, dropHandler} = useDropFile((files) => {
  // 拖放成功后 把数据传到父组件
  emits('changeDropFile', files);
});

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const userStore = useUserStore();

const {taskList} = storeToRefs(userStore);

const current = ref(0);
const formRef = ref();

const formDefaultData = {
  mode: 'local', // 模式 可取值 "local"
  node: undefined, // 加速节点
  folderName: undefined, // 文件
  resource_id: '', // 资源id，选择文件后接口返回
  type: 'movie', // 类型，可取值 1电影,2其他 number
  count: 3, // 副本数量 3-10
  title: '', // 标题
  description: '', // 简介
  poster: '', // 海报
  posterBlobUrl: '', // 本地选取的blob海报地址
};

const sameDevices = ref([]); // 相同网络下的设备

const wanIP = ref(''); // 外网ip

const formState = ref({...formDefaultData});

const options = ref([]);
const notFoundContent = ref('暂无数据');

const loading = ref(false);

const hitOption = ref({}); // 选中的影片

const fastDeviceOption = ref(null); // select 选中的加速节点对象
const fastDeviceInfo = ref(null); // 获取到的设备详情

const createSyncInfo = ref(null); // 创建任务后 同步信息

// function onCancelTask(item) {
//   item.cancelUpload();

//   taskList.value = taskList.value.filter((child) => item !== child);
// }

const inputImageKey = ref(''); // 刷新
const fileImageInput = ref(''); // 刷新
const visibleImageCropper = ref(false); // 是否显示裁切图片

const postImageRef = ref(); // 获取图片信息的

const getDeviceSid = (device_uid) => {
  return sameDevices.value.find((item) => item.device_uid === device_uid)?.device_sid;
};

const triggerFileInput = () => {
  if (formState.value.type === 'other') {
    fileImageInput.value.click();
  }
};

const handleUploadSuccess = (url) => {
  formState.value = {...formState.value, poster: url};
};

const handleImageChange = (event) => {
  const file = event.target.files[0];
  if (file && file.size <= 1024 * 1024 * 100) {
    // Check file size <= 1MB
    if (file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        formState.value.posterBlobUrl = e.target.result;

        visibleImageCropper.value = true;

        inputImageKey.value = Math.random() + Date.now();
      };
      reader.readAsDataURL(file);
    } else {
      message.error('仅支持JPEG、PNG、BMP、WEBP等格式的文件');
      inputImageKey.value = Math.random() + Date.now();
    }
  } else {
    message.error('图片大小不得超过10MB');
    inputImageKey.value = Math.random() + Date.now();
  }
};

const handleClearImage = () => {
  formState.value = {
    ...formState.value,
    poster: undefined,
  };
};

const getWidth = (str, gap = 1) => {
  return `${((str.length || 1) - gap) * 10}px`;
};

const onClickUploadType = async () => {
  try {
    const types = [
      {label: '文件', value: 'fileInput'},
      {label: '文件夹', value: 'folderInput'},
    ];

    const type = await showConfirm(types);

    emits('clickFolderInput', type);
  } catch (error) {
    console.error(error);
  }
};

const onChangeDevice = async (did) => {
  // console.log('event', event);
  try {
    loading.value = true;
    const item = sameDevices.value.find((item) => item.device_uid === did);

    // console.log('item', item);

    fastDeviceOption.value = item;

    // console.log('[fastDeviceOption.value]', fastDeviceOption.value);

    if (item) {
      const response = await getFastDeviceInfo(item.lan_ip);

      if (response.data.status === 200) {
        fastDeviceInfo.value = response.data.data;
      } else {
        throw response;
      }
      // console.log('response', response);
    } else {
      // 没有获取到设备信息

      fastDeviceInfo.value = null;
    }
  } catch (error) {
    console.error('[onChangeDevice error]', [error]);
  } finally {
    loading.value = false;
  }
};

const handleTypeChange = (value) => {
  if (value === 'other') {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: formState.value?.folderName || '',
    };
  } else if (value === 'movie') {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: '',
    };

    loading.value = true;
    // 搜索一次
    getMvsMvFilepath({filepath: formState.value?.folderName})
      .then((response) => {
        if (response.data.status === 200) {
          const arr = response.data.info ? [response.data.info] : [];

          if (arr.length > 0) {
            options.value = arr;
            nextTick(() => {
              formState.value.title = arr[0].ycb_mv_id;

              handleChange(arr[0].ycb_mv_id);
            });
          }
        }
      })
      .catch((err) => {
        console.error([err]);
      })
      .finally(() => {
        loading.value = false;
      });
  } else {
    formState.value = {
      ...formState.value,
      description: '',
      poster: '',
      title: '',
    };
  }
};

const handleChange = async (value) => {
  const item = options.value.find((item) => item.ycb_mv_id === value);
  // console.log('handleChange', value, item);

  if (item) {
    hitOption.value = item;
    formState.value = {
      ...formState.value,
      description: (item.summary || '')?.trim(),
      poster: item.poster,
    };
  }

  // console.log('formState.value', formState.value);

  await nextTick();

  formRef.value.validate().catch((err) => {
    console.log(err);
  });
};

const handleOk = async () => {};

const handleCancel = () => {
  dialogVisible.value = false;
};

const handlePrev = () => {
  current.value -= 1;
};

const handleNext = async () => {
  try {
    // 表单校验，如果通过则继续，否则不继续
    await formRef.value.validate();

    if (current.value === 1) {
      loading.value = true;
      await uploadFiles(props.allItems).finally(() => {
        loading.value = false;
        emits('success');
      });
    }

    if (current.value === 0) {
      handleTypeChange('movie');
    }

    current.value += 1;
  } catch (error) {
    if (current.value === 0 && sameDevices.value.length === 0) {
      // console.log(1111111111111111);

      getDeviceSameWanIp()
        .then((response) => {
          if (response.data.status === 200) {
            const {wan_ip, devices} = response.data.data;

            wanIP.value = wan_ip;
            sameDevices.value = [...devices];
          } else {
            throw response;
          }
        })
        .catch((err) => {
          wanIP.value = '';
          sameDevices.value = [];
          console.error('[getDeviceSameWanIp]', [err]);
        });
    }

    console.error(error);
  }
};
const handleNextOk = () => {
  dialogVisible.value = false;
};

const formatBytes = (str) => {
  const match = str.match(/(\d+\.?\d*)([a-zA-Z]+)/);
  if (match) {
    return {value: parseFloat(match[1]).toFixed(2), unit: match[2]};
  }
  return {value: '', unit: ''};
};
async function uploadFolder({item, xDevice, deviceId, tid, controller}) {
  const param = {
    xDevice,
    path: replaceSpecialChars(item.path),
    tid,
    lanIp: fastDeviceOption.value.lan_ip,
  };
  console.log(getCurrentFormattedTime(), '[createFolder start]', param, item.path);
  const folderResponse = await createFolder(deviceId, param, controller);
  console.log(getCurrentFormattedTime(), '[createFolder ok]', folderResponse);
}
async function uploadFile({item, task, xDevice, deviceId, tid, startTime, controller}) {
  const file = item.file;
  const param = {
    xDevice,
    path: replaceSpecialChars(item.path),
    tid,
    lanIp: fastDeviceOption.value.lan_ip,
  };
  console.log(getCurrentFormattedTime(), '[uploadFileUsingPOST start]', [deviceId, param]);
  const postResponse = await uploadFileUsingPOST(deviceId, param, controller);
  if (postResponse.data.status !== 201) {
    console.error(getCurrentFormattedTime(), '[uploadFileUsingPOST error]', [
      (deviceId, param, postResponse),
    ]);
    throw postResponse;
  }
  console.log(getCurrentFormattedTime(), '[uploadFileUsingPOST ok]', [
    deviceId,
    param,
    postResponse,
  ]);

  const chunkSize = 1024 * 1024 * 99; // 99MB per chunk
  let offset = 0;

  while (offset < file.size) {
    console.log(getCurrentFormattedTime(), '[checkUploadUsingHEAD start]', offset, deviceId, param);
    const headResponse = await checkUploadUsingHEAD(deviceId, param, controller);

    console.log(getCurrentFormattedTime(), '[checkUploadUsingHEAD ok]', offset, [headResponse]);

    const uploadOffset2 = headResponse.headers['upload-offset'];

    const chunk = file.slice(offset, offset + chunkSize);
    let lastUploaded = 0; // 保存上一次上传回调时的已上传数据量

    console.log(
      getCurrentFormattedTime(),
      '[continueUploadUsingPATCH start]',
      [offset, offset + chunkSize],
      param,
      uploadOffset2,
    );

    const patchResponse = await continueUploadUsingPATCH(
      deviceId,
      chunk,
      {...param, uploadOffset: uploadOffset2 || 0},
      (event) => {
        if (event.lengthComputable) {
          const chunkUploaded = event.loaded - lastUploaded;
          const totalUploaded = Math.min(task.uploadedSize + chunkUploaded, totalSize.value);
          lastUploaded = event.loaded; // 更新已上传数据量

          const elapsed = (Date.now() - startTime) / 1000;
          const speed = totalUploaded / elapsed;
          const remaining = parseInt((totalSize.value - totalUploaded) / speed, 10);
          const progress = (totalUploaded / totalSize.value) * 100;

          task.uploadedSize = totalUploaded;
          task.progress = progress;
          task.total = totalSize.value;
          task.speed = speed;
          task.remaining = remaining;
          task.formattedUploaded = formatBytes(
            bytes(totalUploaded, {decimalPlaces: 2, unitSeparator: ''}),
          );
          task.formattedTotal = formatBytes(
            bytes(totalSize.value, {decimalPlaces: 2, unitSeparator: ''}),
          );
          task.formattedSpeed = formatBytes(
            bytes(speed, {decimalPlaces: 2, unitSeparator: ''}) + '/s',
          );
          task.formattedRemaining = formatRemainingTime(remaining);
          task.formattedProgress = progress.toFixed(0);
          task.formattedTxt = `${task.formattedUploaded}/${task.formattedTotal} (${task.formattedProgress}%) - ${task.formattedSpeed} ${task.formattedRemaining}`;

          // console.log('task.formattedTxt', task.formattedTxt);

          userStore.setTaskList([...taskList.value]);
        }
      },
      controller,
    );

    console.log(getCurrentFormattedTime(), '[continueUploadUsingPATCH ok]', [patchResponse]);

    offset += chunk.size;
    // uploadedSize += chunk.size;
  }
}
async function uploadFiles(allItems) {
  const userStore = useUserStore();
  const startTime = Date.now();

  const taskManager = new TaskManager(10);

  // 创建 AbortController 实例
  const controller = new AbortController();

  const task = {
    id: getNextId(),
    tid: '',
    folderName: formState.value.folderName,
    uploadedSize: 0, // 已经上传的大小
    totalSize: totalSize.value,
    progress: 0,
    speed: '0B/s',
    remaining: 'N/A',
    title: formState.value?.title,
    coverUrl: formState.value?.poster,
    status: 'uploading',
    formattedTxt: '',
    controller,
    cancelUpload: () => controller?.abort(),
  };

  const deviceId = formState.value.node;

  // console.log('deviceId', deviceId);
  // formState.value.deviceId;

  // console.log('[fastDeviceOption.value]', fastDeviceOption.value);

  // console.log('formState.value.resource_id', formState.value.resource_id);

  const startUploadParam = {
    lanIp: fastDeviceOption.value.lan_ip,
    xDevice: '',
    // type: formState.value.type,
    name: replaceSpecialChars(formState.value.name),
    replica_count: formState.value.count,
    resource_id: formState.value.resource_id,
    // movieId: selectedPoster.value?.ycb_mv_id || undefined,
    // coverUrl: selectedPoster.value?.poster || undefined,
    // userId: userInfo.value.id,
    total_size: totalSize.value,
  };

  // dialogVisible.value = false;
  // EventBus.emit('ShowUploadList', true);

  try {
    console.log(
      getCurrentFormattedTime(),
      '[getAuthXDevice start]',
      deviceId,
      startUploadParam,
      formState.value.name,
    );
    const xDeviceResponse = await getAuthXDevice({device_uid: deviceId}, controller);
    if (xDeviceResponse.data.status !== 200) {
      console.error(getCurrentFormattedTime(), '[getAuthXDevice error]', deviceId, [
        xDeviceResponse,
      ]);
      throw xDeviceResponse;
    }

    console.log(getCurrentFormattedTime(), '[getAuthXDevice ok]', deviceId, [xDeviceResponse]);

    const xDevice = xDeviceResponse.data.data.x_device;

    const map = {
      [0]: '任务启动',
      [1]: '任务结束',
      [10]: '通知切片成功',
      [11]: '切片完成，但未与云端同步',
      [20]: '与云端同步完成',
      [30]: '与云端同步失败',
    };

    const runningTaskResponse = await getResourceRunningTaskInfo(
      deviceId,
      {lanIp: fastDeviceOption.value.lan_ip, xDevice},
      controller,
    );

    if (runningTaskResponse.data.status !== 200) {
      console.error(
        getCurrentFormattedTime(),
        '[getResourceRunningTaskInfo error]',
        {deviceId, lanIp: fastDeviceOption.value.lan_ip, xDevice},
        [runningTaskResponse],
      );

      throw runningTaskResponse;
    }

    console.log(
      getCurrentFormattedTime(),
      '[getResourceRunningTaskInfo ok]',
      {deviceId, lanIp: fastDeviceOption.value.lan_ip, xDevice},
      [runningTaskResponse],
    );

    const runningArray = runningTaskResponse?.data?.data?.info || [];

    if (runningArray.length > 0) {
      await new Promise((resolve, reject) => {
        const allowDelete = runningArray
          .map((item) => item.task_status)
          .every((status) => status === 0);
        return Modal.confirm({
          title: `查询到${runningArray.length}个任务正在进行，${allowDelete ? '需删除任务后继续上传' : '请稍后尝试上传'}`,
          icon: createVNode(ExclamationCircleOutlined),
          closable: true,
          content: h('div', {}, [
            h('p', {}, allowDelete ? '确定删除下面任务：' : '任务状态如下：'),
            ...runningArray.map((item) =>
              h(
                'p',
                {style: 'color: #f00'},
                `tid: ${item.tid}${map[item.task_status] ? `，状态：${map[item.task_status]}` : ''}`,
              ),
            ),
            allowDelete ? h('p', {style: ''}, '操作后不可撤销！') : undefined,
          ]),
          cancelText: '取消上传',
          okText: '确定删除',
          okButtonProps: allowDelete ? {danger: true} : {style: 'display: none'},
          centered: true,
          onOk() {
            resolve();
          },
          onCancel() {
            const err = new Error('上传已经取消');
            err.name = 'AbortError';
            reject(err);
          },
        });
      });
    }

    for (const item of runningArray) {
      const removeTaskResponse = await getResourceRemoveRunningTask(
        deviceId,
        {tid: item.tid, lanIp: fastDeviceOption.value.lan_ip, xDevice},
        controller,
      );
      if (removeTaskResponse.data.status !== 200) {
        console.error(getCurrentFormattedTime(), '[getResourceRemoveRunningTask error]', [
          removeTaskResponse,
        ]);
        throw removeTaskResponse;
      }

      console.log(getCurrentFormattedTime(), '[getResourceRemoveRunningTask ok]', [
        removeTaskResponse,
      ]);
    }

    userStore.setTaskList([task]);

    // const xDevice = undefined;

    // console.log('startUploadParam', startUploadParam);

    console.log(getCurrentFormattedTime(), '[startUploadTask start]', {
      ...startUploadParam,
      xDevice,
    });

    const startResponse = await startUploadTask(
      deviceId,
      {...startUploadParam, xDevice},
      controller,
    );

    if (startResponse?.data.status !== 200) {
      console.log(getCurrentFormattedTime(), '[startUploadTask error]', [startResponse]);

      throw startResponse;
    }

    console.log(getCurrentFormattedTime(), '[startUploadTask ok]', [startResponse]);

    const tid = startResponse?.data?.data?.tid;
    task.tid = tid;

    const queue = [];

    // 先创建文件夹
    await uploadFolder({
      lanIp: fastDeviceOption.value.lan_ip,
      item: {path: startUploadParam.name},
      task,
      xDevice,
      deviceId,
      tid,
      startTime,
      controller,
    });

    // console.log('[allItems]', allItems);

    const thatItmes = allItems.map((item) => ({
      ...item,
      path: item.path
        ? item.path // 文件夹上传
        : startUploadParam.name + '/' + item.file.name, // 文件上传
    }));

    // console.log('[thatItmes]', thatItmes);

    for (let item of thatItmes) {
      if (item.type === 'folder') {
        const promise = taskManager.add(
          item.nextId,
          () => uploadFolder({item, task, xDevice, deviceId, tid, startTime, controller}),
          item,
        );
        queue.push(promise);
        await promise;
      } else if (item.type === 'file') {
        const promise = taskManager.add(
          item.nextId,
          () => uploadFile({item, task, xDevice, deviceId, tid, startTime, controller}),
          item,
        );
        queue.push(promise);
      }
    }

    await Promise.all(queue);

    const endResponse = await endUploadTask(
      deviceId,
      {xDevice, tid, lanIp: fastDeviceOption.value.lan_ip},
      controller,
    );
    console.log(
      getCurrentFormattedTime(),
      '[endUploadTask ok]',
      {xDevice, tid, lanIp: fastDeviceOption.value.lan_ip},
      [endResponse],
    );
    task.status = 'uploaded';
    userStore.setTaskList([...taskList.value]);
    emits('success');

    // console.log('formState.value.title', formState.value.title);
    // console.log('hitOption.value', hitOption.value);

    const newItem = {
      id: formState.value.resource_id, // 检查空间时返回的资源id
      type: formState.value.type, // 类型
      title: formState.value.type === 'other' ? formState.value.title : hitOption.value.title, // 标题
      cover_url: formState.value.poster, // 封面地址
      summary: formState.value.description?.trim(), // 简介
      size: totalSize.value, // 文件大小
      replica_count: formState.value.count, // 副本数量
      fac_mv_id: formState.value.type === 'movie' ? hitOption.value.ycb_mv_id : undefined, // 媒资id，影片时从媒资库匹配出的mv_id
      year: formState.value.type === 'movie' ? hitOption.value.year : undefined, // 媒资id，影片时从媒资库匹配出的mv_id
    };

    // console.log('newItem', newItem);

    // 获取图片宽高
    const imgElement = postImageRef.value;
    const imgWidth = imgElement?.naturalWidth;
    const imgHeight = imgElement?.naturalHeight;
    newItem.cover_url_width = imgWidth;
    newItem.cover_url_height = imgHeight;

    const resourceResponse = await getResourceCreate(newItem);

    if (resourceResponse.data.status !== 200) {
      console.error(getCurrentFormattedTime(), '[getResourceCreate error]', [resourceResponse]);

      throw resourceResponse;
    }

    console.log(getCurrentFormattedTime(), '[getResourceCreate ok]', [resourceResponse]);

    createSyncInfo.value = resourceResponse.data.data;

    // console.log('[resourceResponse]', resourceResponse);

    message.success('上传成功');
  } catch (error) {
    createSyncInfo.value = null;
    // console.log('controller1', controller);

    console.error(getCurrentFormattedTime(), '[上传失败 error]', [error]);

    taskManager.clearAllTasks();

    if (error.name === 'AbortError') {
      message.error('上传已取消');
    } else {
      message.error(error?.data?.message || '上传失败，请稍后重试');
    }
    task.status = 'uploadError';
    userStore.setTaskList([...taskList.value]);

    throw error;
  }
  console.log(getCurrentFormattedTime(), 'All allItems uploaded successfully');
}
function formatRemainingTime(seconds) {
  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);
  const secs = Math.floor(seconds % 60);

  const style = 'display:inline-block;min-width: 18px;text-align: center;';

  const hrsStr = hrs > 0 ? `<span style="${style}">${hrs}</span>小时` : '';
  const minsStr = mins > 0 ? `<span style="${style}">${mins}</span>分` : '';
  const secsStr = secs > 0 ? `<span style="${style}">${secs}</span>秒` : '';

  const remaining = `${hrsStr}${minsStr}${secsStr}`.trim();
  return remaining ? `剩余${remaining}` : '';
}

// 窗口打开的时候重置表单
const handleResetForm = () => {
  current.value = 0;
  formState.value = {...formDefaultData};

  fastDeviceInfo.value = null;

  userStore.setTaskList([]);

  createSyncInfo.value = null;

  getDeviceSameWanIp()
    .then((response) => {
      if (response.data.status === 200) {
        const {wan_ip, devices} = response.data.data;

        wanIP.value = wan_ip;
        sameDevices.value = [...devices];
      } else {
        throw response;
      }
    })
    .catch((err) => {
      wanIP.value = '';
      sameDevices.value = [];
      console.error('[getDeviceSameWanIp]', [err]);
    });
};

watch(dialogVisible, (visible) => {
  if (visible) {
    handleResetForm();
  } else {
    formState.value = {...formDefaultData};
    userStore.setTaskList([]);
    emits('clearFolder');

    Modal.destroyAll();
  }
  nextTick(() => {
    formRef.value?.clearValidate()?.catch(() => {});
  });
});

const totalSize = computed(() =>
  props.allItems.reduce((total, acc) => {
    return total + (acc.type === 'file' ? acc.file.size : 0);
  }, 0),
);

const folderCounter = computed(
  () => props.allItems.length - props.allItems.filter((item) => item.type === 'file').length,
);

const validateFolderName = async (rule, value) => {
  // console.log('[validateFolderName value]', value);
  if (!value) {
    return Promise.reject('请选择文件或文件夹');
  }

  if (totalSize.value === 0) {
    return Promise.reject('文件或文件夹为空，请移除文件后重新选择');
  }

  if (totalSize.value > 0) {
    try {
      const res = await getResourceSizeCheck({
        size: totalSize.value,
        replica_count: formState.value.count,
      });

      if (res.data.status === 200) {
        formState.value.resource_id = res.data.data.resource_id;
        return Promise.resolve();
      } else if (res.data.status === 13003) {
        return Promise.reject('存储空间不足');
      } else {
        return Promise.reject(res.data.message || '资源检查失败，请移除文件后重新选择');
      }
    } catch (error) {
      return Promise.reject('资源检查失败，请稍后再试');
    }
  }
  return Promise.resolve();
};

watch(
  () => props.allItems,
  (newValue, oldValue) => {
    if (newValue.length === 0 && oldValue.length === 0) {
      return;
    }

    let folderName = undefined;
    if (newValue.length > 0) {
      const first = newValue[0];
      if (first.type === 'file') {
        folderName = first?.name?.replace(/\.[^/.]+$/, '');
      } else {
        const relativePath = first?.path;
        folderName = relativePath?.split('/')[0] || undefined;
      }
    }

    formState.value = {...formState.value, name: folderName, folderName};

    // console.log('props.allItems', props.allItems);

    nextTick(() => {
      formRef.value?.validateFields(['folderName'])?.catch(() => {});
    });
    // if (!formState.value.name) {
    //   formRef.value?.clearValidate(['name', 'folderName'])?.catch(() => {});
    // } else {
    //   formState.value = {...formState.value, name: folderName, folderName};
    //   formRef.value?.clearValidate(['folderName'])?.catch(() => {});
    // }
  },
);

const handleClearFolder = () => {
  emits('clearFolder');
};

const searchController = ref(); // 控制搜索海报

const spinning = ref(false);

const handleSearch = (inputValue) => {
  // console.log('inputValue', inputValue);
  spinning.value = true;

  searchController.value?.abort();

  // 创建 AbortController 实例
  const controller = new AbortController();

  searchController.value = controller;

  return getMvsMvKeyword(
    {
      keyword: replaceSpecialChars(inputValue?.trim() || ''),
      current: 1,
      size: 100,
    },
    controller,
  )
    .then((response) => {
      const info = response.data.info;
      options.value = info.record;
      // console.log('[options.value]', options.value);
      return info;
    })
    .catch((err) => {
      // options.value = [];
      console.error('[getMvsMvKeyword err]', err);
    })
    .finally(() => {
      spinning.value = false;
    });
};
</script>

<style scoped lang="scss">
.add-resource__steps {
  margin-bottom: 20px;
}

.add-resource__mode-wrap {
  display: flex;
  justify-content: flex-start;
}

.add-resource__node-wrap {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

.add-resource__node-info {
  display: flex;
  flex-direction: column;
  color: #7b7b7b;
  font-size: 14px;
  line-height: 1.5em;
}

.add-resource__upload-area {
  display: flex;
  flex-direction: column;
  border: 1px dashed #1677ff;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  padding: 20px 0;
  width: 400px;
  box-sizing: border-box;
  user-select: none;
  cursor: pointer;
  img {
    width: 48px;
  }
}

.add-resource__upload-item {
  padding-top: 12px;
  display: flex;
  align-items: center;

  img {
    width: 12px;
    user-select: none;
    margin-right: 14px;
  }

  .add-resource__upload-info {
    & > div:first-child {
      color: #000;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 345px;
    }
    & > div:last-child {
      color: #7b7b7b;
    }
  }

  .add-resource__upload-remove {
    color: #7b7b7b;
    padding: 8px 12px;
    cursor: pointer;
    user-select: none;
  }
}

.add-resource__upload-btn {
  width: 104px;
  height: 104px;
  border: 1px dashed #d9d9d9;
  background-color: #ededed;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  user-select: none;
}

.add-resource__form.is-type-other {
  .form-item-poster:hover {
    .image-wrapper {
      border: 1px dashed #d9d9d9;

      .close-icon {
        display: block;
      }
    }
  }

  .add-resource__upload-btn {
    background-color: transparent;
    margin-bottom: 16px;
  }
}
.add-resource__form:not(.is-type-other) {
  .image-wrapper {
    margin: 0;
    padding: 0;
  }
}

.image-wrapper {
  margin-left: -12px;
  display: inline-flex;
  border: 1px dashed transparent;
  padding: 12px;
  border-radius: 8px;
  user-select: none;
  position: relative;
  .close-icon {
    position: absolute;
    right: -12px;
    top: -12px;
    cursor: pointer;
    display: none;
    transition: all ease 0.1s;
  }
}

.add-resource__next {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

// .upload-folder {
//   display: flex;
// }

.upload-status.uploadError {
  color: #f00;
}
</style>
