<template>
  <div class="sync-task-wrapper">
    <div class="page-header">
      <div style="display: flex; gap: 16px; align-items: center">
        <span>当前所在频道：</span>

        <span class="channel-type" v-if="getChannelType(channelInfo)">{{
          getChannelType(channelInfo)
        }}</span>
        <a-select
          v-model:value="defaultChannel"
          @change="handleChange"
          placeholder="请选择"
          style="width: 180px"
        >
          <a-select-option v-for="item in channelOptions" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <span>频道资源数：{{ pagination.total || 0 }}部</span>
      </div>
    </div>
    <div class="page-content">
      <a-table
        :loading="loading"
        :columns="columns"
        :data-source="data"
        :pagination="false"
        @change="onTableChange"
        class="overflow-x__auto"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">{{ column.title }}</span>
        </template>
        <template #bodyCell="{column, record}">
          <div v-if="column.key === 'channel'" style="white-space: normal">
            {{ record.title }} <template v-if="record.year">({{ record.year }})</template>
          </div>

          <template v-if="column.key === 'status'">
            {{ getTextByValue(record.status) }}
          </template>

          <template v-if="column.key === 'createAndTime'">
            <div style="white-space: nowrap">{{ record.create_by }}</div>
            <div style="white-space: nowrap">
              {{ record.create_time ? dayjs(record.create_time).format('YYYY-MM-DD HH:mm') : '' }}
            </div>
          </template>
          <template v-if="column.key === 'take_down_by'">
            <div>
              <div style="white-space: nowrap">{{ record.take_down_by || '-' }}</div>
              <div style="white-space: nowrap">
                {{
                  record.take_down_time
                    ? dayjs(record.take_down_time).format('YYYY-MM-DD HH:mm')
                    : ''
                }}
              </div>
            </div>
          </template>

          <template v-if="column.key === 'action'">
            <a-space style="white-space: nowrap">
              <a-typography-link type="link" class="btn-link" @click="showConfirm('read', record)">
                查看海报
              </a-typography-link>
              <a-typography-link
                v-if="record.status !== 'take_down'"
                type="danger"
                class="btn-link"
                @click="showConfirm('deactivate', record)"
                >下架</a-typography-link
              >
            </a-space>
          </template>
        </template>
      </a-table>
      <div class="pagination-wrapper">
        <a-pagination
          v-model:current="pagination.current"
          v-model:page-size="pagination.pageSize"
          :total="pagination.total"
          :show-total="pagination.showTotal"
          :defaultPageSize="pagination.defaultPageSize"
          showSizeChanger
          :pageSizeOptions="pagination.pageSizeOptions"
          @change="pagination.onChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onUnmounted, computed, createVNode, h} from 'vue';
import dayjs from 'dayjs';
import {
  getLinkChannelInfo,
  getLinkChannelResourceCover,
  getLinkChannelResourceList,
  getLinkChannelResourceTakeDown,
  getLinkChannelWithin,
} from '@/apis/channel';
import usePagination from '@/composable/usePagination';
import {message, Modal} from 'ant-design-vue';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {useRoute, useRouter} from 'vue-router';
import {getChannelType} from '../utils';
import {ContentManagementView} from '@/router/names.js';

const {pagination} = usePagination(getList);

const loading = ref(false);

const router = useRouter();
const route = useRoute();
const defaultChannel = ref(undefined);
const channelOptions = ref([]);
const channelInfo = ref({});

const query = ref({
  status: [],
});

const statusArray = [
  {text: '正常', value: 'normal'},
  {text: '影片整改', value: 'block'},
  {text: '下架', value: 'take_down'},
];

const getTextByValue = (type) => {
  const item = statusArray.find((item) => item.value === type);
  return item?.text || '-';
};

const columns = computed(() => [
  {
    title: '资源名称',
    key: 'channel',
    dataIndex: 'channel',
    fixed: 'left',
  },
  {
    title: '资源简介',
    key: 'summary',
    dataIndex: 'summary',
  },
  {
    title: '频道状态',
    key: 'status',
    dataIndex: 'status',
    filters: statusArray,
    filterMultiple: false,
    filteredValue: query.value.status,
    onFilter: (value, record) => record.status.includes(value),
  },

  {
    title: '上架人及上架时间',
    key: 'createAndTime',
    dataIndex: 'createAndTime',
    align: 'center',
  },

  {
    title: '下架人及下架时间',
    key: 'take_down_by',
    dataIndex: 'take_down_by',
    align: 'center',
  },
  {
    title: '操作',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 200,
    minWidth: 200,
    maxWidth: 200,
  },
]);

const data = ref([]);

function fetchData() {
  pagination.value.current = 1;
  getList();
}
const handleChange = async (value) => {
  await router.push({name: ContentManagementView, query: {...route.query, id: value}});
  fetchData();
};
async function getList() {
  try {
    loading.value = true;
    const channelId =
      defaultChannel.value || (route.query.id ? Number(route.query.id) : undefined) || undefined;
    handleChannelInfo(channelId);

    const response = await getLinkChannelResourceList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      // status: query.value.status,
      channel_id: channelId,
      status: query.value?.status.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data?.total || 0,
    };

    // const records = response.data?.data?.records || [];
    const records = response.data?.data?.records || [];

    data.value = records;
  } catch (error) {
    console.error(error);

    data.value = [];
  } finally {
    loading.value = false;
  }
}

const showConfirm = async (type, record) => {
  try {
    const response = await getLinkChannelResourceCover({resource_id: record.resource_id});

    if (response.data.status !== 200) {
      throw response;
    }

    const resoult = response.data.data;

    Modal.confirm({
      width: '508px',
      title: '查看海报',
      icon: createVNode(ExclamationCircleOutlined),
      closable: true,
      content: h('div', {}, [
        h('p', {style: 'width: 385px;height: 200px;background: #e3f0fc;'}, [
          h('img', {
            src: resoult.cover_url,
            style: 'width: inherit;height: inherit;object-fit: cover;',
          }),
        ]),
        h(
          'p',
          {style: 'font-weight: bold'},
          resoult.title + (resoult.year ? `(${resoult.title})` : ''),
        ),
        h('p', {style: ''}, resoult.summary),
        type === 'deactivate' && h('p', {style: 'color: #f00;'}, '确定将该影片下架？'),
        type === 'deactivate' && h('p', {style: 'color: #f00;'}, '操作后不可撤销！'),
      ]),
      okText: '确定',
      okButtonProps:
        type === 'read' ? {style: {display: 'none'}} : type === 'deactivate' ? {danger: true} : {},
      cancelButtonProps: type === 'read' ? {style: {display: 'none'}} : {},
      centered: true,
      async onOk() {
        if (Number.isNaN(Number(route.query.id))) {
          message.error('频道id不正确');
          return;
        }
        if (type === 'deactivate') {
          getLinkChannelResourceTakeDown({
            channel_id: Number(route.query.id),
            resource_id: record.resource_id,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status === 200) {
                message.success('频道下架成功');

                fetchData();
              } else {
                throw response;
              }
            })
            .catch((err) => {
              console.log(err);
              message.error(err?.data?.message || '频道下架失败');
              throw err;
            });
        }
      },
      onCancel() {},
    });
  } catch (error) {
    console.error([error]);
  }
};

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.status = filters.status || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;

    if (sorter.field === 'joined_count') {
      query.value.order_joined_count = sorter.order;
      query.value.order_resource_count = undefined;
    } else {
      query.value.order_joined_count = undefined;
      query.value.order_resource_count = sorter.order;
    }
    fetchData();
  }
};

const handleChannelInfo = (channelId) => {
  getLinkChannelInfo({id: channelId})
    .then((response) => {
      if (response.data.status === 200) {
        channelInfo.value = response.data.data;
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      channelInfo.value = {};
      message.error('频道详情获取失败，请刷新重试');
    });
};

onMounted(() => {
  fetchData();
  getLinkChannelWithin({current: 1, size: 100})
    .then((response) => {
      if (response.data.status === 200) {
        channelOptions.value = response?.data?.data?.records || [];
      } else {
        throw response;
      }
    })
    .catch((err) => {
      channelOptions.value = [];
      console.error([err?.message]);
    })
    .finally(() => {
      let channelId = Number(route.query.id);
      channelId = Number.isNaN(channelId) ? undefined : channelId;
      defaultChannel.value = channelId;
    });
});

onUnmounted(() => {});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}
.channel-type {
  background-color: #555;
  font-size: 12px;
  padding: 3px 10px;
  color: #fff;
}
</style>
