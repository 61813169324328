<template>
  <div ref="deviceNetworkChart" class="device-network__chart"></div>
</template>

<script setup>
import * as echarts from 'echarts/core';
import {TooltipComponent, GridComponent} from 'echarts/components';
import {BarChart} from 'echarts/charts';
import {CanvasRenderer} from 'echarts/renderers';
import {markRaw, onMounted, ref, shallowRef, watch} from 'vue';
import bytes from 'bytes'; // 引入 bytes 库
import useResize from '@/composable/useResize';

echarts.use([TooltipComponent, GridComponent, BarChart, CanvasRenderer]);

const props = defineProps({
  trafficData: {type: Object, required: true, default: () => ({})},
});

// 格式化数据
const formatData = (data) => data.map((value) => value / (1024 * 1024)); // 转换为 MB

const initOption = (data) => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow',
    },
    formatter: (params) => {
      let result = `${params[0].axisValue}<br/>`;
      params.forEach((item) => {
        result += `${item.marker}${item.seriesName}: ${bytes(item.value * 1024 * 1024)}<br/>`; // 转换回字节格式化显示
      });
      return result;
    },
  },
  grid: {
    left: '3%',
    right: '4%',
    bottom: '3%',
    containLabel: true,
  },
  xAxis: [
    {
      type: 'category',
      data: data.x_axis,
      axisTick: {
        alignWithLabel: true,
      },
    },
  ],
  yAxis: [
    {
      type: 'value',
      axisLabel: {
        formatter: '{value} MB', // 显示单位为 MB
      },
    },
  ],
  series: data.series.map((item, index) => ({
    name: item.name,
    type: 'bar',
    data: formatData(item.data),
    itemStyle: {
      color: ['#66b2ed', '#a4d8f6', '#d04a49'][index], // 保留自定义的颜色
    },
  })),
});

const deviceNetworkChart = shallowRef();
const myChart = ref(null);

useResize(() => {
  myChart.value?.resize();
});

const updateChart = () => {
  if (myChart.value && props.trafficData.x_axis) {
    const option = initOption(props.trafficData);
    myChart.value.setOption(option);
  }
};

watch(
  () => props.trafficData,
  (newData) => {
    updateChart(newData);
  },
  {deep: true},
);

onMounted(() => {
  myChart.value = markRaw(echarts.init(deviceNetworkChart.value));
  updateChart();
});
</script>

<style scoped lang="scss">
.device-network__chart {
  height: 340px;
}
</style>
