import {ref} from 'vue';

export default function useDragDom() {
  const x = ref(0);
  const y = ref(0);

  let startX, startY;

  function handleMouseDown(e) {
    if (e.target.closest('.ignore-drag')) {
      // 如果点击的是需要忽略的元素，直接返回不处理
      return;
    }
    startX = e.clientX - x.value;
    startY = e.clientY - y.value;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  }

  function handleMouseMove(e) {
    x.value = e.clientX - startX;
    y.value = e.clientY - startY;
  }

  function handleMouseUp() {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  }

  return {
    x,
    y,
    handleMouseDown,
  };
}
