<template>
  <a-modal
    v-model:open="dialogVisible"
    title="绑定新设备"
    :mask-closable="false"
    :closable="!loading"
    autocomplete="off"
    @cancel="handleCancel"
  >
    <a-spin :spinning="loading">
      <div style="margin: 40px 0px">
        <a-form ref="formRef" layout="vertical" :model="form" autocomplete="off">
          <a-form-item
            :rules="[{required: true, message: '请输入设备标识'}]"
            label="设备标识"
            name="did"
          >
            <a-input v-model:value="form.did" placeholder="请输入设备标识" allowClear></a-input>
          </a-form-item>
          <!-- <a-form-item
            :rules="[{required: true, message: '请输入设备串号'}]"
            label="设备串号（sn）"
            name="sn"
          >
            <a-input v-model:value="form.sn" placeholder="请输入设备串号" allowClear></a-input>
          </a-form-item>
          <a-form-item
            :rules="[{required: true, message: '请输入MAC地址'}]"
            label="MAC地址（mac）"
            name="mac"
          >
            <a-input v-model:value="form.mac" placeholder="请输入MAC地址" allowClear></a-input>
          </a-form-item> -->
        </a-form>
      </div>
    </a-spin>
    <template #footer>
      <a-button key="back" @click="handleCancel" :disabled="loading">取消</a-button>
      <a-button type="primary" :loading="loading" @click="handleOk" :disabled="loading">
        确认
      </a-button>
    </template>
  </a-modal>
</template>

<script setup>
import {postDeviceBind} from '@/apis/device';
// import {useUserStore} from '@/stores/userStore';
import {message} from 'ant-design-vue';
// import {storeToRefs} from 'pinia';
import {computed, nextTick, ref, watch} from 'vue';

const props = defineProps({
  visible: {type: Boolean, default: false, required: false},
});

const emits = defineEmits(['update:visible', 'success']);
const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emits('update:visible', val),
});

const formRef = ref();

const loading = ref(false);
const form = ref({
  sn: undefined,
  mac: undefined,
  did: undefined,
});
watch(
  () => props.visible,
  async (newVal) => {
    if (newVal) {
      form.value = {
        sn: undefined,
        mac: undefined,
        did: undefined,
        ...props.inputData,
      }; // 每次打开时生成新密码
      formRef.value?.clearValidate();
    } else {
      await nextTick();
      formRef.value?.clearValidate();
    }
  },
);
const handleOk = async () => {
  formRef.value
    .validate()
    .then(async () => {
      try {
        loading.value = true;

        console.log('form', form.value);

        const response = await postDeviceBind({
          // sn: form.value.sn,
          // mac: form.value.mac,
          device_uid: form.value.did,
        });

        if (response.data.status !== 200) {
          throw response;
        }

        emits('update:visible', false);
        emits('success', {...form.value, ...response.data.data});
        message.success(props.inputData?.isEdit ? '更新成功' : '添加成功');
      } catch (error) {
        console.error('[error]', [error]);
        console.log('error?.data?.message', error?.data?.message);
        const emsg = error?.data?.message;

        message.error(emsg || (props.inputData?.isEdit ? '更新成功' : '添加成功'));
      } finally {
        loading.value = false;
      }
    })
    .catch((error) => {
      console.error([error]);
    });
};

const handleCancel = () => {
  emits('update:visible', false);
};
</script>
