// @/apis/users.js
import request from '@/utils/request.js';

// 设备列表
export function getDeviceList(params) {
  return request({
    method: 'get',
    url: '/v2/link/device/list',
    params,
  });
}
// 设备列表 添加设备
export function postDeviceAdd(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/add',
    data,
  });
}
// 设备列表 绑定设备
export function postDeviceBind(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/bind',
    data,
  });
}
// 设备列表 解绑设备
export function postDeviceUnbind(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/unbind',
    data,
  });
}
// 设备列表 关机
export function postDeviceShutdown(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/shutdown',
    data,
  });
}
// 设备列表 重启
export function postDeviceReboot(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/reboot',
    data,
  });
}
// 设备列表 检查设备唯一资源
export function postDeviceCheckUnique(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/check/unique',
    data,
  });
}
// 设备列表 格式化
export function postDeviceFormat(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/format',
    data,
  });
}
// 媒资管理 资源导入 加速节点
export function getFastDeviceInfo(lanIP) {
  let baseURL;

  // eslint-disable-next-line no-undef
  const isProduction = process.env.NODE_ENV === 'production';
  if (isProduction) {
    baseURL = 'http://' + lanIP;
  }
  return request({
    baseURL,
    method: 'get',
    url: '/fast/v1/device/info',
  });
}

// /ed64981f55b0ab873fbe1c9d/fast/v1/mzone/upload/tus

// 设备列表 设备详情
export function postDeviceDetail(params) {
  return request({
    method: 'get',
    url: '/v2/link/device/detail',
    params: {device_uid: params.device_uid},
  });
}
// 设备列表 修改名称
export function postDeviceUpdateName(data) {
  return request({
    method: 'post',
    url: '/v2/link/device/update/name',
    data: {device_uid: data.device_uid, name: data.name},
  });
}
