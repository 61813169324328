import {getMobileDevice} from '@/utils/utils';
import {defineStore} from 'pinia';

export const useUserStore = defineStore('userStore', {
  state: () => {
    let userInfo = localStorage.getItem('userInfo');
    let loginState = localStorage.getItem('loginState');
    if (userInfo) {
      userInfo = JSON.parse(userInfo);
    }
    if (loginState) {
      loginState = JSON.parse(loginState);
    }

    return {
      loginState,
      userInfo,
      isTwoFactor: sessionStorage.getItem('isTwoFactor') === '1',
      taskList: [],
      isMobileDevice: getMobileDevice(),
      version: '',
    };
  },
  getters: {},
  actions: {
    setVersion(version) {
      this.version = version;
    },
    setMobileDevice(value) {
      this.isMobileDevice = value;
    },
    setTaskList(taskList) {
      this.taskList = taskList;
    },
    setTwoFactor(isTwoFactor) {
      this.isTwoFactor = isTwoFactor;
      if (isTwoFactor) {
        sessionStorage.setItem('isTwoFactor', isTwoFactor ? '1' : '0');
      } else {
        sessionStorage.removeItem('isTwoFactor');
      }
    },
    setUserInfo(userInfo) {
      if (userInfo) {
        localStorage.setItem('userInfo', JSON.stringify(userInfo));
      } else {
        localStorage.removeItem('userInfo');
      }
      this.userInfo = userInfo;
    },
    setLoginState(loginState) {
      if (loginState) {
        localStorage.setItem('loginState', JSON.stringify(loginState));
      } else {
        localStorage.removeItem('loginState');
      }
      this.loginState = loginState;
    },
  },
});
