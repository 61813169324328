<template>
  <div>
    <div class="page-content">
      <a-form :model="formState" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item label="【申请UP】的审批是否开启自动审批" :colon="false">
          <a-switch
            v-model:checked="formState.up_apply"
            :checkedValue="1"
            :unCheckedValue="0"
            checked-children="开"
            un-checked-children="关"
            @change="handleChange('up_apply', formState.up_apply)"
          />
        </a-form-item>
        <a-form-item
          v-if="formState.up_apply === 1"
          label="自动"
          name="region"
          style="margin-bottom: 60px"
        >
          <a-select
            v-model:value="formState.up_apply_approve"
            placeholder="请选择"
            style="max-width: 264px"
            @change="handleChange('up_apply_approve', formState.up_apply_approve)"
          >
            <a-select-option :value="1">同意</a-select-option>
            <a-select-option :value="0">拒绝</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="【内容举报】是否开启自动审批" :colon="false">
          <a-switch
            v-model:checked="formState.channel_resource_report"
            :checkedValue="1"
            :unCheckedValue="0"
            checked-children="开"
            un-checked-children="关"
            @change="handleChange('channel_resource_report', formState.channel_resource_report)"
          />
        </a-form-item>
        <a-form-item
          label="  "
          name="region"
          :label-col="{style: {width: '250px', visibility: 'hidden'}}"
          style="margin-bottom: 60px"
          v-if="formState.channel_resource_report === 1"
        >
          <div style="display: flex; align-items: center; gap: 6px">
            <a-input-number
              id="inputNumber"
              v-model:value="formState.channel_resource_report_cnt"
              :min="1"
              :max="10000"
              @change="
                handleChange('channel_resource_report_cnt', formState.channel_resource_report_cnt)
              "
            />
            <span>个用户举报自动下架该内容</span>
          </div>
        </a-form-item>
        <a-form-item label="【频道举报】是否开启自动审批" :colon="false">
          <a-switch
            v-model:checked="formState.channel_report"
            :checkedValue="1"
            :unCheckedValue="0"
            checked-children="开"
            un-checked-children="关"
            @change="handleChange('channel_report', formState.channel_report)"
          />
        </a-form-item>
        <a-form-item
          label="  "
          name="region"
          :label-col="{style: {width: '250px', visibility: 'hidden'}}"
          style="margin-bottom: 60px"
          v-if="formState.channel_report === 1"
        >
          <div style="display: flex; align-items: center; gap: 6px">
            <a-input-number
              id="inputNumber"
              v-model:value="formState.channel_report_cnt"
              :min="1"
              :max="10000"
              @change="handleChange('channel_report_cnt', formState.channel_report_cnt)"
            />
            <span>个用户举报自动下架该内容</span>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import {getLinkAuditAutoSettings, postLinkAuditAutoSettings} from '@/apis/review.js';
import {message} from 'ant-design-vue';

const formState = ref({
  up_apply: 0,
  up_apply_approve: 0,
  channel_resource_report: 0,
  channel_resource_report_cnt: 1,
  channel_report: 0,
  channel_report_cnt: 1,
});

const labelCol = {
  style: {
    width: '250px',
  },
};
const wrapperCol = {
  span: 14,
};

function getList() {
  getLinkAuditAutoSettings()
    .then((response) => {
      if (response?.data?.status === 200) {
        const newItem = response?.data?.data || {};
        formState.value = {
          ...newItem,
          channel_resource_report_cnt: Math.max(newItem.channel_resource_report_cnt, 1),
          channel_report_cnt: Math.max(newItem.channel_report_cnt, 1),
        };
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error('[err]', err);
    });
}

function handleChange(type, value) {
  postLinkAuditAutoSettings({business_type: type, value})
    .then((response) => {
      if (response.data.status === 200) {
        message.success('设置成功');
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error(err);
      message.error(err?.data?.message || '设置失败');
    })
    .finally(() => {
      getList();
    });
}

onMounted(() => {
  getList();
});
</script>
<style lang="scss" scoped></style>
