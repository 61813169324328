<template>
  <div class="rating-container">
    <template v-for="(item, index) in [0, 1, 2, 3, 4]">
      <template v-if="index < starCount">
        <img :key="index" src="@/assets/star.svg" class="star-icon" />
      </template>
      <template v-else>
        <img :key="index" src="@/assets/star_gray.svg" class="star-icon" />
      </template>
    </template>
    <span class="rating-text" :class="[ratingText > 0 ? 'yellow' : '']">{{ ratingText }}</span>
  </div>
</template>

<script setup>
import {ref, watch} from 'vue';

const props = defineProps({
  score: {
    type: Number,
    value: 0,
  },
  ratingText: {
    type: String,
    value: '暂无评分',
  },
});

const starCount = ref(4);

// function updateStarCount(score) {
//   starCount.value = Math.round(score / 2);
// }

// watch(
//   props.score,
//   (score) => {
//     updateStarCount(score ? Number(score) : 0);
//   },
//   {immediate: true},
// );
</script>
<style lang="scss">
.rating-container {
  display: flex;
  align-items: center;
}

.star-icon {
  width: 16px;
  height: 16px;
}

.rating-text {
  margin-left: 6px;
  color: #7b7b7b;
  font-size: 12px;
}

.rating-text.yellow {
  color: #fbb03b;
}
</style>
