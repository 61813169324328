import {createRouter, createWebHistory} from 'vue-router';
import NotFoundView from '@/views/NotFoundView.vue'; // 引入NotFoundView组件
import LoginView from '@/views/LoginView.vue';
import TwoFactorView from '@/views/TwoFactorView.vue';
import Layout from '@/views/index.vue';

import UpOwnerApplicationFormView from '@/views/UpOwnerApplicationFormView.vue';
import UpApplicationProgressView from '@/views/UpApplicationProgressView.vue';

import SettingsView from '@/views/SettingsView/index.vue';
import DeviceListView from '@/views/DeviceListView/index.vue';
import DeviceDetailView from '@/views/DeviceListView/DeviceDetailView.vue';

import SyncTasksView from '@/views/SyncTasksView/index.vue';
import CompletedTasksView from '@/views/SyncTasksView/CompletedTasksView.vue';
import TaskListView from '@/views/SyncTasksView/TaskListView.vue';

import ResourceManagementView from '@/views/ResourceManagementView/index.vue';
import ResourceListView from '@/views/ResourceManagementView/media-library.vue';
import AddResourceView from '@/views/ResourceManagementView/AddResourceView.vue';
// import TransmissionList from '@/views/ResourceManagementView/transmission-list.vue';
import MaterialLibrary from '@/views/ResourceManagementView/material-library/index.vue';
import MaterialEdit from '@/views/ResourceManagementView/material-library/edit.vue';
import MaterialEditSuccess from '@/views/ResourceManagementView/material-library/edit-success.vue';

import ConfigurationAndOperationsView from '@/views/ConfigurationAndOperationsView/index.vue';
import PersonnelConfigurationView from '@/views/ConfigurationAndOperationsView/PersonnelConfigurationView.vue';
import OperationsConfigurationView from '@/views/ConfigurationAndOperationsView/OperationsConfigurationView.vue';

import ChannelManagementView from '@/views/ChannelManagementView/index.vue';
import NewUserApplicationView from '@/views/ChannelManagementView/NewUserApplicationView.vue';
import CreatedChannelsView from '@/views/ChannelManagementView/CreatedChannelsView.vue';
import ManagedChannelsView from '@/views/ChannelManagementView/ManagedChannelsView/index.vue';
import ContentManagementView from '@/views/ChannelManagementView/ManagedChannelsView/ContentManagementView.vue';
import MemberManagementView from '@/views/ChannelManagementView/ManagedChannelsView/MemberManagementView.vue';

import ReviewModuleView from '@/views/ReviewModuleView/index.vue';
import AutomationSettingsView from '@/views/ReviewModuleView/AutomationSettingsView.vue';
import ApplicationProcessingView from '@/views/ReviewModuleView/ApplicationProcessingView.vue';
import ContentBlockingView from '@/views/ReviewModuleView/ContentBlockingView.vue';
// import ChannelManagementView from '@/views/ChannelManagementView.vue';
// import RankingsView from '@/views/RankingsView.vue';
import * as RouteNames from '@/router/names.js';
import {DEVICE_MANAGER, CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER} from '@/router/roles';
import {useUserStore} from '@/stores/userStore';
// import {message} from 'ant-design-vue';

import {
  UserOutlined,
  VideoCameraOutlined,
  AppstoreOutlined,
  CloudSyncOutlined,
  NotificationOutlined,
  ScheduleOutlined,
} from '@ant-design/icons-vue';
import {resetLoginData} from '@/utils/logout';
import {checkForUpdate} from '@/versionService';

export const routes = [
  {
    path: '/login',
    name: RouteNames.LoginView,
    component: LoginView,
    // No meta needed as everyone can access the login page
  },
  {
    path: '/up-owner-application-form',
    name: RouteNames.UpOwnerApplicationFormView,
    component: UpOwnerApplicationFormView,
    meta: {roles: [], title: 'UP主申请/申请中（表单页面）'},
  },
  {
    path: '/up-application-progress',
    name: RouteNames.UpApplicationProgressView,
    component: UpApplicationProgressView,
    meta: {roles: [], title: 'UP申请进度'},
  },
  {
    path: '/two-factor',
    name: RouteNames.TwoFactorView,
    component: TwoFactorView,
    meta: {roles: [], title: '输入验证码页面'},
  },
  {
    path: '/',
    component: Layout,
    name: RouteNames.Layout,
    redirect: '/devices',
    children: [
      {
        path: '/settings',
        name: RouteNames.SettingsView,
        component: SettingsView,
        meta: {
          roles: [DEVICE_MANAGER, CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
          title: '个人设置',
          hidden: true, // 不显示在左侧菜单
        }, // Accessible by 运维, 管理员, 超级管理员
      },
      {
        path: '/devices',
        name: RouteNames.DeviceListView,
        component: DeviceListView,
        meta: {
          roles: [DEVICE_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
          title: '设备列表',
          icon: AppstoreOutlined,
        }, // Accessible by 运维, 管理员, 超级管理员
        children: [
          {
            path: '/device/:device_uid',
            name: RouteNames.DeviceDetailView,
            component: DeviceDetailView,
            meta: {
              roles: [DEVICE_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
              title: '基础详情',
              hidden: true,
            },
          },
        ],
      },
      {
        path: '/sync',
        name: RouteNames.SyncTasksView,
        component: SyncTasksView,
        redirect: '/sync/list',
        meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '同步任务', icon: CloudSyncOutlined}, // Accessible by 管理员, 超级管理员,
        children: [
          {
            path: '/sync/list',
            name: RouteNames.TaskListView,
            component: TaskListView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '任务列表'},
          },
          {
            path: '/sync/completed',
            name: RouteNames.CompletedTasksView,
            component: CompletedTasksView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '已完成'},
          },
        ],
      },
      {
        path: '/resource',
        name: RouteNames.ResourceManagementView,
        component: ResourceManagementView,
        meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '媒资管理', icon: VideoCameraOutlined}, // Accessible by 管理员, 超级管理员
        redirect: '/resource/list',
        children: [
          {
            path: '/resource/list',
            name: RouteNames.ResourceListView,
            component: ResourceListView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '媒资库'},
          },
          {
            path: '/resource/material',
            name: RouteNames.MaterialLibrary,
            component: MaterialLibrary,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '素材库'},
            children: [
              {
                path: '/resource/material/edit',
                name: RouteNames.MaterialEdit,
                component: MaterialEdit,
                meta: {
                  roles: [SUPER_ADMIN, CHANNEL_MASTER],
                  title: '编辑',
                  hidden: true,
                },
              },
              {
                path: '/resource/material/successful',
                name: RouteNames.MaterialEditSuccess,
                component: MaterialEditSuccess,
                meta: {
                  roles: [SUPER_ADMIN, CHANNEL_MASTER],
                  title: '编辑',
                  hidden: true,
                },
              },
            ],
          },
          {
            path: '/resource/add',
            name: RouteNames.AddResourceView,
            component: AddResourceView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '新增资源'},
          },
          // {
          //   path: '/resource/transmission',
          //   name: RouteNames.TransmissionList,
          //   component: TransmissionList,
          //   meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '传输列表'},
          // },
        ],
      },
      {
        path: '/config',
        name: RouteNames.ConfigurationAndOperationsView,
        component: ConfigurationAndOperationsView,
        redirect: '/config/personnel',
        meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '配置运维', icon: UserOutlined}, // Only accessible by 管理员
        children: [
          {
            path: '/config/personnel',
            name: RouteNames.PersonnelConfigurationView,
            component: PersonnelConfigurationView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '人员配置'}, // Only accessible by 管理员
          },
          {
            path: '/config/operations',
            name: RouteNames.OperationsConfigurationView,
            component: OperationsConfigurationView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '运维配置'}, // Only accessible by 管理员
            // Inherits meta from parent
          },
        ],
      },
      {
        path: '/channel',
        name: RouteNames.ChannelManagementView,
        component: ChannelManagementView,
        meta: {
          roles: [CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
          title: '频道管理',
          icon: NotificationOutlined,
        }, // Only accessible by 管理员
        children: [
          {
            path: '/channel/new',
            name: RouteNames.NewUserApplicationView,
            component: NewUserApplicationView,
            meta: {roles: [CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER], title: '新用户申请'}, // Only accessible by 管理员
          },
          {
            path: '/channel/created',
            name: RouteNames.CreatedChannelsView,
            component: CreatedChannelsView,
            meta: {roles: [SUPER_ADMIN, CHANNEL_MASTER], title: '创建的频道'}, // Only accessible by 管理员
          },
          {
            path: '/channel/managed',
            name: RouteNames.ManagedChannelsView,
            component: ManagedChannelsView,
            meta: {roles: [CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER], title: '管理的频道'}, // Only accessible by 管理员
            children: [
              {
                path: '/channel/managed/content',
                name: RouteNames.ContentManagementView,
                component: ContentManagementView,
                meta: {
                  roles: [CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
                  title: '内容管理',
                  hidden: true,
                }, // Only accessible by 管理员
              },
              {
                path: '/channel/managed/member',
                name: RouteNames.MemberManagementView,
                component: MemberManagementView,

                meta: {
                  roles: [CHANNEL_MANAGER, SUPER_ADMIN, CHANNEL_MASTER],
                  title: '成员管理',
                  hidden: true,
                }, // Only accessible by 管理员
              },
            ],
          },
        ],
      },
      {
        path: '/review',
        name: RouteNames.ReviewModuleView,
        component: ReviewModuleView,
        meta: {roles: [SUPER_ADMIN], title: '审核模块', icon: ScheduleOutlined},
        children: [
          {
            path: '/review/settings',
            name: RouteNames.AutomationSettingsView,
            component: AutomationSettingsView,
            meta: {roles: [SUPER_ADMIN], title: '自动化设置'},
          },
          {
            path: '/review/processing',
            name: RouteNames.ApplicationProcessingView,
            component: ApplicationProcessingView,
            meta: {roles: [SUPER_ADMIN], title: '申请处理'},
          },
          {
            path: '/review/blocking',
            name: RouteNames.ContentBlockingView,
            component: ContentBlockingView,
            meta: {roles: [SUPER_ADMIN], title: '屏蔽内容'},
          },
        ],
      },
    ],
  },

  {
    path: '/:pathMatch(.*)*', // 捕获所有未匹配的路径
    name: RouteNames.NotFoundView,
    component: NotFoundView,
    meta: {title: '404 - Not Found'},
  },
];
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export const resetRouter = () => {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
};

router.beforeEach((to, from, next) => {
  // const currentUserRole = getCurrentUserRole(); // 假设这个函数返回当前用户的角色常量
  const userStore = useUserStore();
  const userInfo = userStore.userInfo;
  const loginState = userStore.loginState;
  const currentUserRole = userInfo?.role; // 假设这个函数返回当前用户的角色常量

  // dHxnvvJxNxdbuf9D
  // 如果是二次验证页面
  if (loginState?.verify) {
    next();
  } else {
    const requiredRoles = to?.meta?.roles;
    // console.log('requiredRoles', requiredRoles);
    if (requiredRoles && requiredRoles.length > 0) {
      // console.log(1);
      if (requiredRoles.includes(currentUserRole)) {
        next();
      } else {
        resetLoginData();
        resetRouter();

        next({name: RouteNames.LoginView}); // 或者重定向到错误页面或登录页面
        // next(from.path); // 或者重定向到错误页面或登录页面
        // message.error('无权限访问该页面');
      }
    } else {
      // console.log(2);
      next(); // 如果没有设置roles，允许访问
    }
  }

  checkForUpdate()
    .then((updateAvailable) => {
      if (updateAvailable) {
        window.location.reload();
      }
    })
    .catch((err) => {
      console.error(err);
    });
});

router.afterEach((to) => {
  // 存储当前路由到 localStorage
  sessionStorage.setItem('lastVisitedRoute', to.name);
});
export default router;
