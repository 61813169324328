import {nextTick, ref} from 'vue';

export default function usePagination(getList = () => {}) {
  const storedPageSize = localStorage.getItem('pageSize') || 10;

  // console.log('storedPageSize', storedPageSize);

  const pagination = ref({
    current: 1,
    pageSize: parseInt(storedPageSize, 10),
    total: 0,
    defaultPageSize: parseInt(storedPageSize, 10),
    showTotal: (total) => `共 ${total} 条记录`,
    showSizeChanger: true,
    pageSizeOptions: ['5', '10', '20', '50', '100'],
    onShowSizeChange: onPageSizeChange,
    onChange: onPageChange,
  });

  function onPageChange(value) {
    pagination.value = {
      ...pagination.value,
      current: value,
    };
    localStorage.setItem('pageSize', pagination.value.pageSize);
    nextTick(() => {
      getList();
    });
  }

  function onPageSizeChange(value) {
    pagination.value = {
      ...pagination.value,
      pageSize: value,
    };
    localStorage.setItem('pageSize', pagination.value.pageSize);
    nextTick(() => {
      getList();
    });
  }
  return {
    pagination,
    onPageChange,
    onPageSizeChange,
  };
}
