// upload.js

// 频道logo/封面上传
import request from '@/utils/request.js';

// 频道logo上传
export function postLinkUploadIcon(formData) {
  return request({
    method: 'post',
    url: '/v2/link/upload/icon',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}
// 电影封面上传
export function postLinkUploadCover(formData) {
  return request({
    method: 'post',
    url: '/v2/link/upload/cover',
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: formData,
  });
}
