<template>
  <div>
    <div class="page-content">
      <a-table
        :loading="loading"
        class="overflow-x__auto"
        :columns="columns"
        :data-source="data"
        :pagination="false"
      >
        <template #headerCell="{column}">
          <span style="white-space: nowrap">
            {{ column.title }}
          </span>
        </template>
        <template #bodyCell="{column, record}">
          <span v-if="column.key === 'device_uid'" style="white-space: nowrap">
            {{ record.device_uid }}
          </span>
          <template v-if="column.key === 'status'">
            {{ STATUS_TYPE[record.online] || '' }}
            <template v-if="record.status !== 'online' && record.offline_time"
              >({{ formatTime(record.offline_time) }})</template
            >
          </template>
          <template v-if="column.key === 'master'">
            <div
              v-if="!record.isEdit"
              class="device-list"
              :title="record.ramText"
              @click="handleClick(record)"
            >
              {{ record.ramText }} <FormOutlined class="hidden-container" />
            </div>
            <a-select
              v-else
              :data-device_uid="record.device_uid"
              v-model:value="record.ramIds"
              style="min-width: 200px; width: 100%"
              mode="multiple"
              placeholder="请选择"
              :maxTagCount="3"
              @blur="handleBlur(record)"
              @change="handleChange(record.device_uid, $event)"
            >
              <a-select-option v-for="device in userOptions" :value="device.id" :key="device.id"
                >{{ device.username }}
                <template v-if="device.nickname && device.mark">
                  ({{ device.nickname }}/{{ device.mark }})
                </template>
                <template v-if="device.nickname && !device.mark">
                  ({{ device.nickname }})
                </template>
                <template v-if="!device.nickname && device.mark">({{ device.mark }})</template>
              </a-select-option>
            </a-select>
          </template>
        </template>
      </a-table>
    </div>
  </div>
</template>
<script setup>
import {nextTick, onMounted, ref} from 'vue';
import CopyText from '@/components/CopyText/index.vue';
import usePagination from '@/composable/usePagination';
import {getLinkRamDeviceList, getLinkRamList, getLinkRamAssign} from '@/apis/config';
import {formatTime} from '@/utils/utils';
import {message} from 'ant-design-vue';
import {FormOutlined} from '@ant-design/icons-vue';
import {STATUS_TYPE} from '@/utils/constants';

const loading = ref(false);

const columns = ref(
  [
    {
      title: '设备ID',
      key: 'device_uid',
      fixed: 'left',
    },
    {
      title: '设备名称',
      key: 'name',
    },
    {
      title: '设备管理员',
      key: 'master',
    },
    {
      title: '状态',
      key: 'status',
      width: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);
const data = ref([]);

const {pagination} = usePagination(getList);

const userOptions = ref([]);

async function getList() {
  try {
    loading.value = true;
    const response = await getLinkRamDeviceList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
    });

    pagination.value = {
      ...pagination.value,
      total: response.data.data.total,
    };

    data.value = (response?.data?.data?.records || []).map((item) => {
      return {
        ...item,
        ramIds: (item?.ram || []).map((item) => item.id),
        ramText: (item.ram || [])
          .map((item) => `${item.username}${item.nickname ? `(${item.nickname})` : ''}`)
          .join('，'),
      };
    });
  } catch (error) {
    console.error('error', error);
    data.value = [];
  } finally {
    loading.value = false;
  }
}

const handleChange = (device_uid, ram_id) => {
  getLinkRamAssign({device_uid, ram_id})
    .then(() => {})
    .catch((err) => {
      message.error('修改设备管理员失败');
      getList();
      console.log([err]);
    });
};

const handleBlur = async (record) => {
  record.isEdit = false;
  await getList();
  data.value = [...data.value];
};

const handleClick = async (record) => {
  record.isEdit = true;
  data.value = [...data.value];
  await nextTick();
  // 获取具有指定 data-device_uid 属性值的 input 元素
  const inputElement = document.querySelector(`[data-device_uid="${record.device_uid}"] input`);

  // 检查元素是否存在
  if (inputElement) {
    // 将焦点设置到该元素上
    inputElement?.focus();
    // 选中该元素的内容
    // inputElement.select();
  } else {
    console.log('没有找到匹配的 input 元素');
  }
};
onMounted(() => {
  getList();

  getLinkRamList({current: 1, size: 100})
    .then((response) => {
      if (response.data.status === 200) {
        userOptions.value = response.data.data.records;
      } else {
        throw response;
      }
    })
    .catch((err) => {
      userOptions.value = [];
      console.error([err]);
    });
});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
}
.device-list {
  min-width: 300px;
  display: flex;
  flex-flow: row wrap;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}
</style>
