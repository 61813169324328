import {postDeviceCheckUnique} from '@/apis/device';
import {message, Modal} from 'ant-design-vue';
import {createVNode, h} from 'vue';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';

export default async function useDeviceCheckUnique(record) {
  let exists = false;
  let resources = [];

  try {
    const response = await postDeviceCheckUnique({
      device_uid: record.device_uid,
    });

    if (response.data.status === 200) {
      exists = response.data.data.exists;
      resources = response.data.data.resources;
    } else {
      throw response;
    }
  } catch (error) {
    console.error(error);
    message.error(error?.data?.message || '检查设备唯一资源失败');
    throw error;
  }

  if (exists) {
    await new Promise((resolve, reject) => {
      Modal.confirm({
        title: `设备${record.device_uid}（${record.name}）有唯一资源？`,
        icon: createVNode(ExclamationCircleOutlined),
        content: h('div', {}, [
          ...resources.map((resource) => {
            return h('p', {style: 'color: #f00'}, resource.title || resource.name);
          }),
          // h('p', {style: 'color: #f00'}, '周处除三害'),
          // h('p', {style: 'color: #f00'}, '周处除三害1'),
          // h('p', {style: 'color: #f00'}, '周处除三害2'),
          // h('p', {style: 'color: #f00'}, '周处除三害3'),
          h('p', {}, '你还要继续吗？'),
        ]),
        okText: '继续',
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        },
      });
    });
  }
}
