import request from '@/utils/request.js';
// 同步中任务
export function getSyncSyncingList(params) {
  return request({
    method: 'get',
    url: '/v2/link/sync/syncing',
    params: {
      current: params.current || 1,
      size: params.size || 10,
      type: params.type, // seed-做种;disaster-容灾
      status: params.status, // pending-未开始;syncing-同步中;success;failed
      sort: params.sort, // create_time
      order: params.order, // desc | asc
    },
  });
}
// 完成列表
export function getSyncSyncedList(params) {
  return request({
    method: 'get',
    url: '/v2/link/sync/synced',
    params: {
      current: params.current || 1,
      size: params.size || 10,
      type: params.type, // seed-做种;disaster-容灾
      sort: params.sort, // create_time
      order: params.order, // desc | asc
    },
  });
}
