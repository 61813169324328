<template>
  <a-spin :spinning="spinning" :delay="300" wrapperClassName="login-spin">
    <main class="login-container">
      <div class="login-form">
        <div style="margin-top: auto">
          <div class="title-wrapper">影趣社创作中心</div>
          <a-form
            class="input-wrapper"
            :model="formState"
            name="basic"
            autocomplete="off"
            @finish="onFinish"
            @finishFailed="onFinishFailed"
            size="large"
          >
            <a-form-item
              label=""
              name="username"
              :rules="[
                {
                  required: true,
                  message: '请输入用户名',
                },
              ]"
            >
              <a-input placeholder="请输入用户名" v-model:value="formState.username" />
            </a-form-item>

            <a-form-item
              label=""
              name="password"
              :rules="[{required: true, message: '请输入密码'}]"
            >
              <a-input-password
                placeholder="请输入密码"
                v-model:value="formState.password"
                autocomplete="off"
              />
            </a-form-item>

            <a-form-item name="remember">
              <a-checkbox v-model:checked="formState.remember" class="remember"
                >自动登录</a-checkbox
              >
            </a-form-item>

            <a-form-item>
              <a-button
                :type="isFormValid ? 'primary' : 'default'"
                html-type="submit"
                style="width: 100%"
                >登录</a-button
              >
            </a-form-item>
          </a-form>
        </div>
        <PageFooter />
      </div>
    </main>
  </a-spin>
</template>

<script setup>
import {useRouter} from 'vue-router';
import * as RouteNames from '@/router/names.js';
import {reactive, onMounted, ref, computed, nextTick} from 'vue';
import {saveUserData, loadUserData} from '@/utils/useLocalStorage.js'; // 调整路径根据您的项目结构
import {getLinkAuthProgress, postAuthRole, postLogin} from '@/apis/users';
import {useUserStore} from '@/stores/userStore';
import {message} from 'ant-design-vue';

import {handleLogout} from '@/utils/logout';
import {updateLoginState} from '@/utils/utils';
import PageFooter from '@/components/PageFooter/index.vue';
import {CHANNEL_MANAGER, getRoleName} from '@/router/roles';
import useLoginSelectRole from '@/composable/useLoginSelectRole';

const {showConfirm} = useLoginSelectRole();

const userStore = useUserStore();
const router = useRouter();
const formState = reactive({
  username: '',
  password: '',
  remember: false,
});

const spinning = ref(false);

const isFormValid = computed(() => {
  return formState.username && formState.password;
});

// 页面加载时尝试从localStorage恢复登录信息
onMounted(async () => {
  const {username, password, remember} = await loadUserData();
  formState.username = username;
  if (remember) {
    formState.password = password;
    formState.remember = remember;
  }

  handleLogout();
});

const onFinish = async (values) => {
  // 根据用户选择来保存或清除数据
  saveUserData(values);

  spinning.value = true;

  try {
    const loginResponse = await postLogin({
      username: values.username?.trim(),
      password: values.password?.trim(),
    });

    if (loginResponse?.data?.status === 200) {
      const loginData = {...loginResponse.data.data};

      const createAt = Date.now(); // 当前时间的时间戳（毫秒）
      const expiresInMs = loginData.expires_in * 1000; // 将过期时间转换为毫秒
      const expiresAt = createAt + expiresInMs; // 计算过期时间戳
      loginData.expiresAt = expiresAt;
      loginData.createAt = createAt;

      if (!loginData.qualify) {
        // qualify为false就不是频道主或管理员，进入申请页或者进度页面

        // 通过接口查询 进入哪一个页面

        const progressResponse = await getLinkAuthProgress({token: loginData.temp_token});

        console.log('progressResponse', progressResponse);

        if (progressResponse.data.status === 200) {
          if (progressResponse.data.data.progress) {
            // 跳转到申请进度页面
            router.push({
              name: RouteNames.UpApplicationProgressView,
              query: {temp_token: loginData.temp_token},
            });
          } else {
            // 跳转到申请页面
            router.push({
              name: RouteNames.UpOwnerApplicationFormView,
              query: {temp_token: loginData.temp_token},
            });
          }
        } else {
          throw progressResponse;
        }
      } else {
        if (loginData.verify) {
          userStore.setLoginState(loginData);
          await nextTick();
          router.push({name: RouteNames.TwoFactorView});
        } else {
          // loginData.roles 的值可能是 ["worker","manager","admin","master"]
          const currentRoles = (loginData?.roles || []).map((item) => ({
            value: item,
            label: getRoleName(item),
          }));

          let role;
          if (currentRoles.length >= 2) {
            role = await showConfirm(currentRoles);
            console.log('[showConfirm role]', role);
          } else if (loginData.roles.length === 1) {
            role = loginData.roles[0];
          }

          const roleResponse = await postAuthRole({
            token: loginData.temp_token,
            role: role,
          });

          const authRoleData = roleResponse.data.data;
          const loginState = authRoleData;

          console.log('[role]', role);

          await updateLoginState({userStore, loginState, loginData});

          if (role === CHANNEL_MANAGER) {
            // 频道管理
            router.replace({name: RouteNames.NewUserApplicationView});
          } else {
            router.replace({name: RouteNames.DeviceListView});
          }
        }
      }
    } else {
      throw loginResponse;
    }
  } catch (error) {
    console.error(error);

    if (error?.code === 'ERR_BAD_RESPONSE') {
      message.error('网络异常，请稍后重试');
    } else {
      message.error(error?.data?.message || '账户或密码错误，请重新输入');
    }
  } finally {
    spinning.value = false;
  }
};

const onFinishFailed = (errorInfo) => {
  console.log('Failed:', errorInfo);
};
</script>

<style lang="scss">
.login-spin {
  height: 100vh;
  display: block;
}
</style>
<style scoped lang="scss">
.login-container {
  height: 100vh;
  display: flex;
  flex-flow: nowrap;
  background: #f0f2f5 url('@/assets/background.svg') no-repeat 50%;
  background-size: 100%;
}

.input-wrapper {
  width: 368px;
}

.title-wrapper {
  width: 368px;
  text-align: left;
  font-size: 20px;
  margin-bottom: 24px;
  font-weight: bold;
}
.remember {
  user-select: none;
}
.leftbar {
  flex: 0 0 0vw;
}
.login-form {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>
