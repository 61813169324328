// @/apis/users.js
import request from '@/utils/request.js';

// 登录接口
export function postLogin(data) {
  return request({
    method: 'post',
    url: '/v2/link/auth/login',
    data,
  });
}
// 获取角色
export function postAuthRole(data) {
  return request({
    method: 'post',
    url: '/v2/link/auth/role',
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    data: {
      role: data.role,
    },
  });
}
// 刷新token
export function postRefreshToken(data) {
  return request({
    method: 'post',
    url: '/v2/link/auth/access_token',
    data: {
      grant_type: data.grant_type,
      refresh_token: data.refresh_token,
    },
  });
}

// 获取用户信息
export function getUserProfile() {
  return request({
    method: 'get',
    url: '/v2/link/my/info',
  });
}

// 更新用户信息，已废弃
export function updateUserProfile(data = {}) {
  return request({
    method: 'post',
    url: '/v2/link/my/update/info',
    data: {verify: data.verify, nickname: data.nickname},
  });
}
// 切换二次验证开关
export function postUpdateSwitchVerify(data = {}) {
  return request({
    method: 'post',
    url: '/v2/link/my/switch/verify',
    data: {verify: data.verify},
  });
}
// 修改密码
export function updateUserPassword(data = {}) {
  return request({
    method: 'post',
    url: '/v2/link/my/update/password',
    data: {old_password: data.old_password, new_password: data.new_password},
  });
}
// 二次验证
export function postAuthVerfiy(data = {}) {
  return request({
    method: 'post',
    url: '/v2/link/auth/verify',
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    data: {username: data.username, code: data.code},
  });
}
// 更新昵称
export function postUpdateNickname(data = {}) {
  return request({
    method: 'post',
    url: '/v2/link/my/update/nickname',
    data: {nickname: data.nickname},
  });
}
// 更新手机号
export function sendVerificationCode(data) {
  return request({
    method: 'post',
    url: '/v2/link/my/update/phone',
    data: {phone: data.phone},
  });
}
// 校验验证码
export function validateVerificationCode(data) {
  return request({
    method: 'post',
    url: '/v2/link/my/update/phone/verify',
    data: {code: data.code, phone: data.phone},
  });
}
// 查询申请页面进度
export function getLinkAuthProgress(data) {
  return request({
    method: 'get',
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    url: '/v2/link/auth/progress',
  });
}
// 频道主申请 表单
export function postLinkAuthApply(data) {
  return request({
    method: 'post',
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    url: '/v2/link/auth/apply',
    data: {
      name: data.name,
      phone: data.phone,
      reason: data.reason,
    },
  });
}
// 频道主申请 欢迎入驻
export function postLinkAuthJoin(data) {
  return request({
    method: 'post',
    headers: {
      Authorization: `Bearer ${data.token}`,
    },
    url: '/v2/link/auth/join',
    data: {
      name: data.name,
      phone: data.phone,
      reason: data.reason,
    },
  });
}
