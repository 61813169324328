export const VAR_STATUS = {
  chunking: '切片中',
  syncing: '同步中',
  seeding: '做种中',
};
export const VAR_TYPE = {
  movie: '电影',
  other: '其他',
};

export const STATUS_TYPE = {
  online: '在线',
  shutdown: '关机',
  rebooting: '重启',
  offline: '离线',
  formatting: '格式化',
};

export const deviceStatusOptions = [
  {text: '切片中', value: 'chunking'},
  {text: '等待中', value: 'pending'},
  {text: '同步中', value: 'syncing'},
  {text: '暂停', value: 'paused'},
  {text: '成功', value: 'success'},
  {text: '失败', value: 'failed'},
];

export const getDeviceStatusName = (status) => {
  return deviceStatusOptions.find((item) => item.value === status)?.text || '';
};
