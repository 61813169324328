<template>
  <div>
    <div class="page-content">
      <a-tabs v-model:activeKey="activeKey" @tabClick="onTabClick">
        <a-tab-pane key="reportChannel" :tab="`屏蔽频道(${paginationReportChannel.total})`">
          <div class="ResourceListView page-header search-form">
            <a-form :model="queryReportChannel" name="basic" layout="inline" autocomplete="off">
              <a-form-item name="query" style="margin-bottom: 20px">
                <a-input
                  style="width: 264px"
                  v-model:value="queryReportChannel.query"
                  placeholder="请输入频道名称/频道id"
                  allowClear
                  @keydown.enter="fetchDataReportChannel"
                />
              </a-form-item>

              <a-form-item
                label="搜索"
                :label-col="{style: {visibility: 'hidden', width: '1px', overflow: 'hidden'}}"
                style="margin-bottom: 20px"
              >
                <a-space>
                  <a-button type="primary" @click="fetchDataReportChannel">搜索</a-button>
                </a-space>
              </a-form-item>
            </a-form>
          </div>
          <TableBlocklResource
            :pageLoading="pageLoading"
            :dataSource="dataSourceReportChannel"
            :query="queryReportChannel"
            :onTableChange="onTableChange"
            :fetchData="getListReportChannel"
          />
          <div class="pagination-wrapper">
            <a-pagination
              v-model:current="paginationReportChannel.current"
              v-model:page-size="paginationReportChannel.pageSize"
              :total="paginationReportChannel.total"
              :show-total="paginationReportChannel.showTotal"
              :defaultPageSize="paginationReportChannel.defaultPageSize"
              showSizeChanger
              :pageSizeOptions="paginationReportChannel.pageSizeOptions"
              @change="paginationReportChannel.onChange"
            />
          </div>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script setup>
import {ref} from 'vue';
import TableBlocklResource from './components/TableBlocklResource.vue';
import useTableBlockResource from './components/useTableBlockResource.js';

const pageLoading = ref(false);

const {
  pagination: paginationReportChannel,
  dataSource: dataSourceReportChannel,
  fetchData: fetchDataReportChannel,
  query: queryReportChannel,
  getList: getListReportChannel,
} = useTableBlockResource(pageLoading);

const activeKey = ref('reportChannel');

function onTabClick(event) {
  console.log('event', event);
  let fetchDataFunc = () => {};
  if (event === 'reportChannel') {
    fetchDataFunc = fetchDataReportChannel;
  }
  fetchDataFunc();
}

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  let fetchDataFunc = () => {};

  if (activeKey.value === 'reportChannel') {
    fetchDataFunc = fetchDataReportChannel;
  }

  if (action === 'filter') {
    queryReportChannel.value.status = filters.status || [];
    fetchDataFunc();
  }

  if (action === 'sort') {
    queryReportChannel.value.sort = sorter.field;
    queryReportChannel.value.order = sorter.order;
    fetchDataFunc();
  }
};
</script>
<style lang="scss" scoped></style>
