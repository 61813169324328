<template>
  <div class="devices-wrapper">
    <div v-if="userInfo && userInfo.role === CHANNEL_MASTER" class="page-header">
      <a-button @click="onAddDevice" type="primary">绑定新设备</a-button>
    </div>
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="data"
      :pagination="false"
      @change="onTableChange"
      class="overflow-x__auto"
    >
      <template #headerCell="{column}">
        <span style="white-space: nowrap">{{ column.title }}</span>
      </template>

      <template #bodyCell="{column, record}">
        <template v-if="column.key === 'device_uid'">
          <span style="white-space: nowrap">{{ record.device_uid }}</span>
        </template>
        <template v-if="column.key === 'name'">
          <!-- <div style="min-width: 120px; max-width: 180px"> -->
          <div v-if="!record.isEdit" @click="handleClickRename(record, column)" class="name-text">
            {{ record.name }}<FormOutlined class="hidden-container" />
          </div>
          <a-input
            v-else
            :readonly="!record.isEdit"
            class="hidden-input-border"
            style="width: 100%; min-width: 200px; box-sizing: border-box"
            :data-device_uid="record.device_uid"
            v-model:value="record.rename"
            @keydown.enter="handleKeyDown(record, column)"
            @blur="handleKeyDown(record, column)"
          />
          <!-- </div> -->
          <!-- <div
            v-show="!record.isEdit"
            @click="handleClickRename(record, column)"
            class="edit-name hidden-input-border"
            style="min-width: 120px; max-width: 180px"
          >
            {{ record.name }}
          </div> -->
        </template>
        <template v-if="column.key === 'warn'">
          <template v-if="record.tag && record.tag.length === 0">-</template>
          <a-tag v-for="item in record.tag || []" :key="item" color="error">{{
            TAG_NAME[item]
          }}</a-tag>
        </template>
        <template v-if="column.key === 'nat_type'">
          <div style="white-space: nowrap">{{ record.nat_type ? getNatTypeText(record.nat_type) : '-' }}</div>
          <!-- <div style="white-space: nowrap">{{ record.nat_type }}</div> -->
        </template>
        <template v-if="column.key === 'ipAddress'">
          <div v-if="record.wan_ip" class="ip-wrapper">
            <div>{{ record.wan_ip }}(公)</div>
          </div>
          <div v-if="record.lan_ip" class="ip-wrapper">
            <div>{{ record.lan_ip }}(私有)</div>
          </div>
        </template>

        <template v-if="column.key === 'traffic'">
          {{ record.traffic ? record.traffic : '-' }}
        </template>
        <template v-if="column.key === 'status'">
          <div style="white-space: nowrap">
            {{ STATUS_TYPE[record.status] || '' }}
            <template v-if="record.status !== 'online' && record.offline_time"
              >({{ formatTime(record.offline_time) }})</template
            >
          </div>
        </template>
        <template v-if="column.key === 'disk'">
          <template v-for="item in record.hdd_info || []" :key="item.position">
            <div class="dist-wrapper">
              <span>盘符{{ item.position }}:</span>
              <span>{{ item.brand }} {{ item.size }} {{ item.uuid }}</span>
            </div>
          </template>
        </template>
        <template v-if="column.key === 'action'">
          <a-space style="white-space: nowrap">
            <a-typography-link type="link" class="btn-link">
              <RouterLink :to="{name: DeviceDetailView, params: {device_uid: record.device_uid}}"
                >详情</RouterLink
              >
            </a-typography-link>
            <a-typography-link
              v-if="userInfo && userInfo.role === CHANNEL_MASTER"
              type="link"
              class="btn-link"
              @click="showConfirm('unbind', record)"
              >解绑</a-typography-link
            >
            <a-dropdown v-if="record.status === 'online'">
              <a-typography-link type="link" class="btn-link"
                >更多<DownOutlined />
              </a-typography-link>
              <template #overlay>
                <a-menu>
                  <a-menu-item>
                    <a href="javascript:;" @click="showConfirm('shutdown', record)">关机</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="showConfirm('restart', record)">重启</a>
                  </a-menu-item>
                  <a-menu-item>
                    <a href="javascript:;" @click="showFormatConfirm('format', record)">格式化</a>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
          </a-space>
        </template>
      </template>
    </a-table>
    <div class="pagination-wrapper">
      <a-pagination
        v-model:current="pagination.current"
        v-model:page-size="pagination.pageSize"
        :total="pagination.total"
        :show-total="pagination.showTotal"
        :defaultPageSize="pagination.defaultPageSize"
        showSizeChanger
        :pageSizeOptions="pagination.pageSizeOptions"
        @change="pagination.onChange"
      />
    </div>

    <AddDeviceModal v-model:visible="visibleAddDevice" @success="fetchData" />
    <FormatModal
      v-model:visible="visibleFormatConfirm"
      :item="selectDeviceInfo"
      @success="getList"
    />
  </div>
</template>
<script setup>
import {createVNode, onMounted, ref, h, computed, nextTick} from 'vue';
import {ExclamationCircleOutlined, DownOutlined, FormOutlined} from '@ant-design/icons-vue';
import {
  getDeviceList,
  postDeviceReboot,
  postDeviceShutdown,
  postDeviceUnbind,
  postDeviceUpdateName,
} from '@/apis/device';
import usePagination from '@/composable/usePagination';
import AddDeviceModal from './AddDeviceModal.vue';
import FormatModal from './FormatModal.vue';
import {message, Modal, notification} from 'ant-design-vue';
import {formatTime} from '@/utils/utils';
import useDeviceCheckUnique from '@/composable/useDeviceCheckUnique';
import {DeviceDetailView} from '@/router/names';
import {STATUS_TYPE} from '@/utils/constants';
import {CHANNEL_MASTER} from '@/router/roles';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';

const userStore = useUserStore();

const {userInfo} = storeToRefs(userStore);

const TAG_NAME = {
  not_format: '未格式化',
  hard_nat: '网络NAT',
};

const loading = ref(false);

const query = ref({
  tag: [], // not_format hard_nat
  status: [], // true:在线 false:离线
  nat_type: [], // wasy hard
  taskType: [], // seed-做种;disaster-容灾
  sort: undefined, // create_time
  order: undefined, // desc | asc
});

const natTypes = [
  {text: 'Unknown', value: 'unknown'},
  {text: 'None', value: 'none'},
  {text: 'FullCone', value: 'full_cone'},
  {text: 'RestrictedCone', value: 'restricted_cone'},
  {text: 'FullOrRestrictedCone', value: 'full_or_restricted_cone'},
  {text: 'PortRestrictedCone', value: 'port_restricted_cone'},
  {text: 'Symmetric', value: 'symmetric'},
];

const getNatTypeText = (value) => {
  return (value && natTypes.find((item) => item.value === value)?.text) || '';
};

const columns = computed(() =>
  [
    {
      title: '实例ID',
      key: 'device_uid',
      fixed: 'left',
      align: 'center',
    },
    {
      title: '名称',
      key: 'name',
      align: 'center',
      width: 250,
    },
    {
      title: '警告',
      key: 'warn',
      align: 'center',
      filters: [
        {text: '未格式化', value: 'not_format'},
        {text: '网络NAT', value: 'hard_nat'},
      ],
      filterMultiple: false,
      filteredValue: query.value.tag,
      onFilter: (value, record) => {
        return (record?.tag || []).includes(value);
      },
    },
    {
      title: '状态',
      key: 'status',
      align: 'center',
      filters: Object.keys(STATUS_TYPE).map((item) => ({text: STATUS_TYPE[item], value: item})),
      filterMultiple: false,
      filteredValue: query.value.status,
      onFilter: (value, record) => {
        return record.status === value;
      },
    },
    {
      title: '磁盘',
      key: 'disk',
    },
    {
      title: 'NAT',
      key: 'nat_type',
      filters: natTypes,
      filterMultiple: true,
      filteredValue: query.value.nat_type,
      // onFilter: (value, record) => record.nat_type === value,
    },
    {
      title: 'IP',
      key: 'ipAddress',
    },
    {
      title: '流量（block）',
      key: 'traffic',
      align: 'center',
      sorter: (a, b) => a.traffic - b.traffic,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const data = ref([]);

const visibleFormatConfirm = ref(false); // 格式化弹窗二次确认

const selectDeviceInfo = ref({});

const visibleAddDevice = ref(false);
const {pagination} = usePagination(getList);

// eslint-disable-next-line
const onTableChange = (pagination, filters, sorter, {action, currentDataSource}) => {
  // console.log(pagination, filters, sorter, {action, currentDataSource});

  if (action === 'filter') {
    query.value.tag = filters.warn || [];
    query.value.status = filters.status || [];
    query.value.nat_type = filters.nat_type || [];

    fetchData();
  }

  if (action === 'sort') {
    query.value.sort = sorter.field;
    query.value.order = sorter.order;
    fetchData();
  }
};

const showConfirm = (type, record) => {
  // console.log('type', type);
  // console.log('record', record);

  let title;

  let text = '';
  if (type === 'unbind') {
    text = '解绑';
  } else if (type === 'shutdown') {
    text = '关机';
  } else if (type === 'restart') {
    text = '重启';
  }

  const deviceName = record.name ? `（${record.name}）` : '';

  if (type === 'unbind') {
    title = `是否要【${text}】设备${record.device_uid}${deviceName}?`;
  } else if (type === 'shutdown') {
    title = `是否要【${text}】设备${record.device_uid}${deviceName}?`;
  } else if (type === 'restart') {
    title = `是否要【${text}】设备${record.device_uid}${deviceName}?`;
  }

  Modal.confirm({
    title,
    icon: createVNode(ExclamationCircleOutlined),
    content: h('div', {}, [
      h('span', {style: 'color: #f00'}, '会影响用户的下载'),
      h('span', {}, '，你还要继续吗？'),
    ]),
    okText: '继续',
    centered: true,
    async onOk() {
      // 检查设备唯一资源
      await useDeviceCheckUnique(record);
      let promise;

      if (type === 'unbind') {
        promise = postDeviceUnbind({device_uid: record.device_uid});
      }
      if (type === 'shutdown') {
        promise = postDeviceShutdown({device_uid: record.device_uid});
      }
      if (type === 'restart') {
        promise = postDeviceReboot({device_uid: record.device_uid});
      }

      if (!promise) {
        fetchData();
        return;
      }

      promise
        .then((response) => {
          if (response.data.status === 200) {
            notification.success({
              message: `设备${record.device_uid}${deviceName}${text}执行成功。`,
              description: '后台进行中的任务，进度完成。',
              duration: 15,
            });
          } else {
            throw response;
          }
        })
        .catch((err) => {
          console.error(err);
          notification.error({
            message: `设备${record.device_uid}${deviceName}${text}执行失败。`,
            description: err?.data?.message || '',
            duration: 15,
          });
        })
        .finally(() => {
          fetchData();
        });
    },
    onCancel() {},
  });
};
const showFormatConfirm = (type, record) => {
  visibleFormatConfirm.value = true;
  selectDeviceInfo.value = record;
};

function onAddDevice() {
  visibleAddDevice.value = true;
}

function fetchData() {
  pagination.value.current = 1;
  getList();
}

async function getList() {
  try {
    loading.value = true;
    const response = await getDeviceList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      tag: query.value.tag.join('') || undefined,
      status: query.value.status.join('') || undefined,
      nat_type: query.value.nat_type.join(',') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    });

    pagination.value = {
      ...pagination.value,
      total: response?.data?.data?.total || 0,
    };

    data.value = (response?.data?.data?.records || []).map((item, index) => {
      return {...item, rename: item.name, index: index + 1};
    });
  } catch (error) {
    console.error('error', error);
    data.value = [];
  } finally {
    loading.value = false;
  }
}

const handleKeyDown = (record) => {
  if (!record?.rename?.trim() || record?.rename?.trim() === record?.name?.trim()) {
    record.isEdit = false;
    return;
  }

  if (record.name === record.rename) {
    record.isEdit = false;
    return;
  }
  record.name = record.rename;

  postDeviceUpdateName({device_uid: record.device_uid, name: record?.rename?.trim()})
    .then((response) => {
      if (response.data.status === 200) {
        // message.success('修改设备名称成功');
        getList();
      } else {
        throw response;
      }
    })
    .catch((err) => {
      console.error([err]);
      message.error(err?.data?.message || '修改名称失败');
      getList();
    })
    .finally(() => {
      record.isEdit = false;
    });
};
const handleClickRename = (record, column) => {
  console.log('[record, column]', record, column);
  record.isEdit = true;
  data.value = [...data.value];

  nextTick(() => {
    // 获取具有指定 data-device_uid 属性值的 input 元素
    const inputElement = document.querySelector(`input[data-device_uid="${record.device_uid}"]`);

    // 检查元素是否存在
    if (inputElement) {
      // 将焦点设置到该元素上
      inputElement?.focus();
      // 选中该元素的内容
      inputElement.select();
    } else {
      console.log('没有找到匹配的 input 元素');
    }
  });
};
onMounted(() => {
  fetchData();
});
</script>

<style scoped lang="scss">
.page-header {
  display: flex;
  margin-bottom: 24px;
}
.ip-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > div {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }
}
.dist-wrapper {
  display: flex;
  span {
    white-space: nowrap;
  }
}

.name-text {
  display: flex;
  justify-content: center;
  gap: 8px;
  min-width: 200px;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  cursor: pointer;
}

.edit-name {
  border: 1px solid;
  border-color: #d9d9d9;
  min-height: 32px;
  box-sizing: border-box;
  padding: 4px 11px;
  border-radius: 4px;
  transition: all 0.2s;
  width: 100%;
  &:hover {
    border-color: #4096ff;
  }
}
</style>
