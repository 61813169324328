import {ref} from 'vue';
import {getLinkAuditReportChannelResourceList} from '@/apis/review.js';
import usePagination from '@/composable/usePagination';

export default function useTableReportResource(pageLoading) {
  const {pagination} = usePagination(getList);

  const query = ref({
    report_cnt: [], // 举报数量
    sort: undefined, // create_time
    order: undefined, // desc | asc
  });

  const dataSource = ref([]);

  function fetchData() {
    pagination.value.current = 1;
    getList();
  }

  function getList(iniital = false) {
    if (!iniital && pageLoading.value) {
      return;
    }
    pageLoading.value = true;
    getLinkAuditReportChannelResourceList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      report_cnt: query.value.report_cnt.join('') || undefined,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          dataSource.value = response?.data?.data?.records || [];
          pagination.value = {
            ...pagination.value,
            total: response?.data?.data?.total || 0,
          };
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.error('[err]', err);
      })
      .finally(() => {
        pageLoading.value = false;
      });
  }

  return {getList, fetchData, pagination, dataSource, query};
}
