import {updateUserPassword} from '@/apis/users';
import {message} from 'ant-design-vue';
import {nextTick, ref} from 'vue';

export default function usePassowrd() {
  const formState = ref({
    oldPassword: undefined,
    newPassword: undefined,
    confirmPassword: undefined,
  });

  const changePasswordRef = ref();
  const changePasswordVisible = ref(false);

  function handleOpenChangePassword() {
    formState.value = {
      oldPassword: undefined,
      newPassword: undefined,
      confirmPassword: undefined,
    };
    changePasswordVisible.value = true;
  }

  async function handlePasswordCancel() {
    changePasswordVisible.value = false;
    await nextTick();
    changePasswordRef.value?.clearValidate();
  }
  async function handlePasswordOk() {
    changePasswordRef.value.validate().then(async () => {
      try {
        const passwordResponse = await updateUserPassword({
          old_password: formState.value?.oldPassword?.trim(),
          new_password: formState.value?.newPassword?.trim(),
        });

        if (passwordResponse.data?.status === 200) {
          message.success('修改密码成功');
          changePasswordVisible.value = false;
        } else {
          throw passwordResponse;
        }
      } catch (error) {
        message.error(error?.data.message || '修改密码失败');
      }
    });
  }

  // 新密码和确认密码的匹配校验
  const validatePasswordMatch = (_, value) => {
    if (!value) {
      return Promise.resolve();
    }
    if (value?.trim() === formState.value.newPassword?.trim()) {
      return Promise.resolve();
    }
    return Promise.reject(new Error('两次输入的密码不匹配'));
  };

  const passwordRules = [
    {required: true, message: '请输入新密码'},
    {min: 6, message: '密码至少为6个字符'},
    // 可以添加更多复杂性规则，如正则表达式
  ];

  const confirmPasswordRules = [
    {required: true, message: '请再次输入新密码'},
    {validator: validatePasswordMatch},
  ];
  return {
    formState,
    changePasswordRef,
    changePasswordVisible,
    handleOpenChangePassword,
    handlePasswordCancel,
    handlePasswordOk,
    validatePasswordMatch,
    passwordRules,
    confirmPasswordRules,
  };
}
