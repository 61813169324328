<template>
  <a-table
    rowKey="id"
    :loading="pageLoading"
    :columns="columns"
    :data-source="dataSource"
    :pagination="false"
    @change="onTableChange"
    class="overflow-x__auto"
  >
    <template #headerCell="{column}">
      <span v-if="column.key" style="white-space: nowrap">{{ column.title }}</span>
    </template>
    <template #bodyCell="{column, record}">
      <template v-if="column.key === 'filename'">
        <div class="oneline-ellipsis" style="text-align: center" :title="record.filename">
          {{ record.filename }}
        </div>
      </template>
      <template v-if="column.key === 'title'">
        <div class="oneline-ellipsis" style="text-align: center" :title="record.title">
          {{ record.title }}
        </div>
      </template>
      <template v-if="column.key === 'type'">
        <div class="oneline-ellipsis" style="text-align: center">
          <template v-if="record.type === 'movie'">电影</template>
          <template v-else-if="record.type === 'other'">其他</template>
          <template v-else>未知</template>
        </div>
      </template>
      <template v-if="column.key === 'create_time'">
        <span style="white-space: nowrap">{{
          record.create_time ? dayjs(record.create_time).format('YYYY/MM/DD HH:mm:ss') : ''
        }}</span>
      </template>
      <template v-if="column.key === 'action'">
        <a-space style="white-space: nowrap">
          <a-typography-link type="link" class="btn-link" @click="handleEdit(record)"
            >编辑</a-typography-link
          >
          <a-typography-link type="danger" class="btn-link" @click="showConfirm('delete', record)"
            >删除</a-typography-link
          >
        </a-space>
      </template>
    </template>
  </a-table>
</template>

<script setup>
import {computed, createVNode, ref, h} from 'vue';
import dayjs from 'dayjs';
import {ExclamationCircleOutlined} from '@ant-design/icons-vue';
import {Modal, message} from 'ant-design-vue';
import {MaterialEdit} from '@/router/names';
import {useRouter} from 'vue-router';
import {postLinkMaterialRemove} from '@/apis/media';

const props = defineProps({
  pageLoading: {type: Boolean, default: false},
  dataSource: {type: Array, default: () => []},
  query: {type: Object, default: () => ({})},
  onTableChange: {type: Function, default: () => {}},
  fetchData: {type: Function, default: () => {}},
});

const columns = computed(() =>
  [
    {
      title: '文件名',
      key: 'filename',
      align: 'center',
    },
    {
      title: '类型',
      key: 'type',
      align: 'center',
    },
    {
      title: '标题',
      key: 'title',
      align: 'center',
    },
    {
      title: '文件大小',
      key: 'size',
      align: 'center',
      sorter: (a, b) => a.size - b.size,
    },
    {
      title: '上传时间',
      key: 'create_time',
      align: 'center',
      sorter: (a, b) => a.create_time - b.create_time,
    },
    {
      title: '操作',
      key: 'action',
      fixed: 'right',
      align: 'center',
      width: 200,
      minWidth: 200,
      maxWidth: 200,
    },
  ].map((item) => ({...item, dataIndex: item.key})),
);

const router = useRouter();
const handleEdit = (record) => {
  router.push({name: MaterialEdit, query: {id: record.id}});
};

const loading = ref(false);

const showConfirm = (type, record) => {
  let confirmModal = null;
  const title = record.filename || '';
  const titleText = '删除操作';
  confirmModal = Modal.confirm({
    title: titleText,
    icon: createVNode(ExclamationCircleOutlined),
    // content: `你确定删除 ${title}\n操作后不可撤销！`,

    content: h('div', {}, [
      h('p', {style: 'color: #f00'}, `你确定删除 ${title}`),
      h('p', {}, `操作后不可撤销！`),
    ]),

    okText: '确定',
    okButtonProps: {loading: loading.value},
    centered: true,
    async onOk() {
      loading.value = true;
      confirmModal.update({okButtonProps: {loading: true}});
      try {
        const response = await postLinkMaterialRemove({id: record.id});
        if (response.data.status === 200) {
          message.success(titleText + '成功');
          confirmModal.destroy();
        } else {
          throw response;
        }
      } catch (err) {
        console.error(err);
        message.error(err?.data?.message || titleText + '失败');
        confirmModal.update({okButtonProps: {loading: false}});
        throw err;
      } finally {
        loading.value = false;

        props.fetchData();
      }
    },
    onCancel() {},
  });
};
</script>

<style lang="scss" scoped>
.oneline-ellipsis {
  margin: 0 auto;
}
@media only screen and (min-width: 1200px) {
  .oneline-ellipsis {
    max-width: 250px;
  }
}
@media only screen and (min-width: 1400px) {
  .oneline-ellipsis {
    max-width: 300px;
  }
}
@media only screen and (min-width: 1700px) {
  .oneline-ellipsis {
    max-width: 350px;
  }
}
</style>
