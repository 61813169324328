// @/apis/review.js
import request from '@/utils/request.js';

// 获取 审核模块/自动化设置/列表
export function getLinkAuditAutoSettings(params) {
  return request({method: 'get', url: '/v2/link/audit/auto/setting', params});
}
// 设置 审核模块/自动化设置/列表
export function postLinkAuditAutoSettings(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/auto/setting',
    data: {
      business_type: data.business_type,
      value: data.value,
    },
  });
}
// 审核模块/Up主申请/列表
export function getLinkAuditMasterApplyList(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/master/apply/list',
    params: {
      current: params.current,
      size: params.size,
      status: params.status,
      sort: params.sort,
      order: params.order,
    },
  });
}
// 审核模块/Up主申请/列表 同意/拒绝
export function postLinkAuditMasterApply(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/master/apply',
    data: {
      id: Array.isArray(data.id) ? data.id.map((item) => Number(item)) : [Number(data.id)],
      status: data.status, // approved/reject
    },
  });
}

// 审核模块/频道举报/列表
export function getLinkAuditReportChannelList(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel/list',
    params: {
      current: params.current,
      size: params.size,
      report_cnt: params.report_cnt,
      resource_cnt: params.resource_cnt,
      sort: params.sort,
      order: params.order,
    },
  });
}
// 审核模块/频道举报/列表/展开
export function getLinkAuditReportChannelExpand(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel/expand',
    params: {
      current: params.current,
      size: params.size,
      channel_id: params.channel_id,
    },
  });
}
// 审核模块/频道举报/列表/详情
export function getLinkAuditReportChannelDetail(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel/detail',
    params: {channel_id: params.channel_id},
  });
}
// 审核模块/频道举报/列表/下架
export function postLinkAuditReportChannelBlock(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/report/channel/block',
    data: {channel_id: data.channel_id},
  });
}
// 审核模块/频道举报/列表/忽略
export function postLinkAuditReportChannelIgnore(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/report/channel/ignore',
    data: {channel_id: data.channel_id},
  });
}
// 审核模块/举报资源/列表
export function getLinkAuditReportChannelResourceList(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel_resource/list',
    params: {
      current: params.current,
      size: params.size,
      report_cnt: params.report_cnt,
      sort: params.sort,
      order: params.order,
    },
  });
}
// 审核模块/举报资源/列表/展开
export function getLinkAuditReportChannelResourceExpand(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel_resource/expand',
    params: {
      current: params.current,
      size: params.size,
      channel_resource_id: params.channel_resource_id,
    },
  });
}
// 审核模块/举报资源/列表/详情
export function getLinkAuditReportChannelResourceDetail(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/report/channel_resource/detail',
    params: {channel_id: params.channel_id},
  });
}
// 审核模块/举报资源/列表/下架
export function postLinkAuditReportChannelResourceBlock(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/report/channel_resource/block',
    data: {channel_resource_id: data.channel_resource_id},
  });
}
// 审核模块/举报资源/列表/忽略
export function postLinkAuditReportChannelResourceIgnore(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/report/channel_resource/ignore',
    data: {channel_resource_id: data.channel_resource_id},
  });
}
// 审核模/屏蔽内容/列表
export function getLinkAuditBlockChannelResource(params) {
  return request({
    method: 'get',
    url: '/v2/link/audit/block/channel/list',
    params: {
      current: params.current,
      size: params.size,
      sort: params.sort,
      order: params.order,
      query: params.query,
    },
  });
}
// 审核模块/屏蔽内容/列表/恢复
export function postLinkAuditBlockChannelResource(data) {
  return request({
    method: 'post',
    url: '/v2/link/audit/block/channel/recover',
    data: {channel_id: data.channel_id},
  });
}
