<template>
  <teleport to="body">
    <div
      v-show="dialogVisible"
      class="upload-list"
      :style="{transform: `translate(${x}px, ${y}px)`}"
      @mousedown="handleMouseDown"
    >
      <h3 class="upload-head">
        <span style="margin-right: auto">上传列表</span>
        <!-- <a-button
          style="margin-left: auto"
          type="text"
          class="ignore-drag"
          @click="dialogVisible = false"
          ><MinusOutlined
        /></a-button> -->
        <a-space>
          <!-- <a-button v-if="true" size="small"><PauseCircleOutlined />全部暂停</a-button>
          <a-button v-else size="small"><ArrowUpOutlined />全部继续</a-button>
          <a-button size="small"><DeleteOutlined />全部清空</a-button> -->
          <!-- <a-popconfirm
            v-if="taskList.length > 0"
            placement="topRight"
            title="你确定全部清空上传任务吗?"
            ok-text="确定"
            cancel-text="取消"
            @confirm="onCloseList"
          >
            <a-button size="small"><DeleteOutlined />全部清空</a-button>
          </a-popconfirm> -->
          <a-button type="text" class="ignore-drag" @click="dialogVisible = false"
            ><CloseOutlined
          /></a-button>
        </a-space>
        <!-- <a-popconfirm
          v-else
          placement="topRight"
          title="你确定取消所有上传任务吗?"
          ok-text="确定"
          cancel-text="取消"
          @confirm="onCloseList"
        >
          <a-button type="text" class="ignore-drag"><CloseOutlined /></a-button>
        </a-popconfirm> -->
      </h3>
      <ul class="upload-wrap">
        <li v-if="taskList.length === 0" class="upload-folder">暂无任务</li>
        <li v-for="item in taskList" :key="item.tid" class="upload-folder">
          <div class="upload-poster">
            <img :src="item.coverUrl" fit="cover" ondragstart="return false;" />
          </div>
          <div class="upload-content">
            <div class="upload-title">{{ item.name }}</div>
            <div class="upload-progress">
              <a-progress
                :percent="item.progress"
                :showInfo="false"
                size="small"
                :status="item.status === 'uploaded' ? '' : 'active'"
              />
            </div>
            <div class="upload-status">
              <template v-if="item.status === 'uploadError'"> 上传失败 </template>
              <template v-else>
                {{ item.formattedTxt }}
              </template>
              <!-- <template v-if="item.status === 'uploaded'"> 上传完成 </template> -->
              <!-- 10.2MB/507MB(2%) - 500MB/s 剩余1秒 -->
            </div>
          </div>
          <div class="button-group">
            <!-- <a-button v-if="item.status === 'paused'" type="text" class="ignore-drag"
              ><ArrowUpOutlined
            /></a-button>
            <a-button v-else-if="item.status === 'uploading'" type="text" class="ignore-drag"
              ><PauseOutlined
            /></a-button> -->
            <a-button type="text" class="ignore-drag" @click="onCancelTask(item)"
              ><CloseOutlined
            /></a-button>
          </div>
        </li>
      </ul>
    </div>
  </teleport>
</template>

<script setup>
import {onMounted, computed, watch} from 'vue';
import {
  // PauseOutlined,
  // MinusOutlined,
  // PauseCircleOutlined,
  // DeleteOutlined,
  // ArrowUpOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import useDragDom from './useDragDom';
import {useUserStore} from '@/stores/userStore';
import {storeToRefs} from 'pinia';

const {x, y, handleMouseDown} = useDragDom();

const userStore = useUserStore();

const {taskList} = storeToRefs(userStore);
const props = defineProps({
  visible: {type: Boolean, default: false},
});
const emit = defineEmits(['visible']);

const dialogVisible = computed({
  get: () => props.visible,
  set: (val) => emit('update:visible', val),
});

watch(dialogVisible, (visible) => {
  if (!visible) {
    x.value = 0;
    y.value = 0;
  }
});

// function onCloseList() {
//   dialogVisible.value = false;
//   userStore.setTaskList([]);
// }

function onCancelTask(item) {
  item.cancelUpload();

  taskList.value = taskList.value.filter((child) => item !== child);
}

onMounted(() => {
  userStore.setTaskList([]);

  // setTimeout(() => {
  //   userStore.setTaskList([
  //     {
  //       cid: '552642619031621',
  //       type: '1',
  //       folderName:
  //         'Win-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpackedWin-unpacked',
  //       cover_url: 'https://image.tmdb.org/t/p/w300/p3LSZ4dmNwT8apzAVFbo22pD7MU.jpg',
  //       seed: 1,
  //       downloaded: 0,
  //       downloaded_total: 0
  //     },
  //     {
  //       cid: '552640976740421',
  //       type: '1',
  //       title: '如何使用curl测试你的网站是否支持http3',
  //       cover_url: 'https://image.tmdb.org/t/p/w300/4z7q8iJqs1hPnvKuv848Zo0TIz7.jpg',
  //       seed: 1,
  //       downloaded: 0,
  //       downloaded_total: 0
  //     },
  //     {
  //       cid: '552639659245637',
  //       type: '1',
  //       title: '如何使用curl测试你的网站是否支持http3',
  //       cover_url: 'https://image.tmdb.org/t/p/w300/9vxd9KT3CheQExtL6WtfnG8zhl7.jpg',
  //       seed: 1,
  //       downloaded: 0,
  //       downloaded_total: 0
  //     }
  //   ]);
  // }, 1000 * 1);
});
</script>

<style scoped lang="scss">
.upload-list {
  position: fixed;
  z-index: 20000;
  width: 484px;
  right: 24px;
  bottom: 24px;
  background-color: #fff;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  border-radius: 8px;

  user-select: none;

  .upload-head {
    font-weight: bold;
    font-size: 14px;
    padding: 10px 16px;
    cursor: default;
    user-select: none;
    background-color: #ccc;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    align-items: center;
  }
  .upload-wrap {
    max-height: 300px;
    overflow: auto;
    margin: 0px 16px;
    .upload-folder {
      display: flex;
      align-items: center;
      //   height: 112px;
      font-size: 14px;

      padding: 16px 0px;
      gap: 16px;

      .upload-poster {
        user-select: none;
        img {
          width: 60px;
          height: 80px;
          object-fit: cover;
        }
      }

      .upload-content {
        flex: 1;
        align-self: flex-start;
        .upload-title {
          width: 296;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          margin-bottom: 4px;
        }
        .upload-status {
          font-size: 12px;
          color: rgba(0, 0, 0, 0.5);
        }
      }

      .button-group {
        display: flex;
      }
    }
  }

  .ignore-drag {
    padding: 4px 8px;
  }
}
</style>
