export const Layout = 'Layout'; // 布局组件
export const LoginView = 'LoginView'; // 登录页面
export const TwoFactorView = 'TwoFactorView'; // 二次验证

export const UpOwnerApplicationFormView = 'UpOwnerApplicationFormView'; // UP主申请/申请中（表单页面）
export const UpApplicationProgressView = 'UpApplicationProgressView'; // UP申请进度

export const DeviceListView = 'DeviceListView'; // 设备列表
export const DeviceDetailView = 'DeviceDetailView'; // 设备列表/基础详情
export const SettingsView = 'SettingsView'; // 个人信息

export const SyncTasksView = 'SyncTasksView'; // 同步任务
export const TaskListView = 'TaskListView'; // 任务列表
export const CompletedTasksView = 'CompletedTasksView'; // 已完成任务

export const ResourceManagementView = 'ResourceManagementView'; // 媒体资源管理
export const ResourceListView = 'ResourceListView'; // 媒体管理/资源列表（媒资库）
export const AddResourceView = 'AddResourceView'; // 媒体管理/新增资源
export const MaterialLibrary = 'MaterialLibrary'; // 媒体管理/素材库
export const MaterialEdit = 'MaterialEdit'; // 媒体管理/素材库/资源编辑页
export const MaterialEditSuccess = 'MaterialEditSuccess'; // 媒体管理/素材库/资源编辑页/提交成功
export const TransmissionList = 'TransmissionList'; // 媒体管理/传输列表

export const ConfigurationAndOperationsView = 'ConfigurationAndOperationsView'; // 配置运维
export const PersonnelConfigurationView = 'PersonnelConfigurationView'; // 配置运维/人员配置
export const OperationsConfigurationView = 'OperationsConfigurationView'; // 配置运维/运维配置

export const ChannelManagementView = 'ChannelManagementView'; // 频道管理
export const NewUserApplicationView = 'NewUserApplicationView'; //  频道管理/新用户申请
export const CreatedChannelsView = 'CreatedChannelsView'; //  频道管理/创建的频道
export const ManagedChannelsView = 'ManagedChannelsView'; //  频道管理/管理的频道
export const ContentManagementView = 'ContentManagementView'; //  频道管理/管理的频道/内容管理
export const MemberManagementView = 'MemberManagementView'; //  频道管理/管理的频道/成员管理

export const ReviewModuleView = 'ReviewModuleView'; // 审核模块
export const AutomationSettingsView = 'AutomationSettingsView'; // 审核模块/自动化设置
export const ApplicationProcessingView = 'ApplicationProcessingView'; // 审核模块/申请处理
export const ContentBlockingView = 'ContentBlockingView'; // 审核模块/屏蔽内容

export const NotFoundView = 'NotFoundView'; // 404页面
