import {getNextId} from '@/utils/utils';
import {message} from 'ant-design-vue';

export default function useDropFile(successDrop = () => {}) {
  // 定义一个异步的 dropHandler 函数，用于处理拖放事件
  async function dropHandler(event) {
    // 检查拖拽的数据类型是否包含 "Files"（表示拖拽的是否为文件）
    if (!event.dataTransfer.types.includes('Files')) {
      // 如果不是文件，则不执行任何操作并返回
      return;
    }
    // 阻止事件的默认行为，允许在目标元素上放置文件
    event.preventDefault();

    // 调用 handleFiles 函数处理拖放的文件，并等待其异步完成
    const files = await handleFiles(event);
    // 在控制台打印处理后的文件信息
    console.log('[handleFiles]', files);
    // 调用 uploadFiles 函数上传处理后的文件
    // uploadFiles(files);

    // emits('changeDropFile', files);
    // return files;

    successDrop(files);
  }

  function dragOverHandler(ev) {
    // 处理拖放过程中的事件，防止默认的文件打开行为
    ev.preventDefault();
  }

  return {dragOverHandler, dropHandler};
}

const MAX_FILES = Infinity;
const MAX_DEPTH = Infinity;

function removeLeadingSlash(path) {
  // 检查字符串是否以 / 开头
  if (path.startsWith('/')) {
    // 如果是，则删除 /
    return path.substring(1);
  }
  // 否则，返回原字符串
  return path;
}

async function traverseDirectory(files, entry, path = '', depth = 0) {
  if (depth > MAX_DEPTH) {
    throw new Error(`文件夹超过${MAX_DEPTH}层，无法上传`);
  }

  if (files.length > MAX_FILES) {
    throw new Error(`文件数超过${MAX_FILES}，无法上传`);
  }

  const dirReader = entry.createReader();
  let entries;

  do {
    entries = await new Promise((resolve) => dirReader.readEntries(resolve));

    for (const ent of entries) {
      if (ent.isFile) {
        const file = await new Promise((resolve) => ent.file(resolve));
        if (!file.webkitRelativePath) {
          file.myRelativePath = ent.fullPath;
        }

        const path = file.webkitRelativePath || file.myRelativePath;
        // 如果是文件，直接添加到文件列表
        files.push({
          type: 'file',
          file,
          name: file.name,
          nextId: getNextId(),
          path: removeLeadingSlash(path),
        });
      } else if (ent.isDirectory) {
        console.log('ent.........', ent);
        files.push({type: 'folder', path: removeLeadingSlash(ent.fullPath)});
        // 如果是目录，调用traverseDirectory处理目录
        // eslint-disable-next-line
        const subFiles = await traverseDirectory(files, ent, `${path}/${ent.name}`, depth + 1);
      }
    }

    console.log('entries', entries);
  } while (entries.length > 0);
}

async function handleFiles(event) {
  try {
    const items = Array.from(event.dataTransfer.items);

    if (items.length > 1) {
      message.error('暂不支持拖拽多个文件或文件夹上传');
      return;
    }

    console.log('items', items);

    const files = [];

    const length = items.length;

    const taskList = [];

    for (let i = 0; i < length; i++) {
      try {
        const item = items[i] && items[i].webkitGetAsEntry ? items[i].webkitGetAsEntry() : items[i];

        console.log('item.name', item.name);

        if (item) {
          if (item.isFile) {
            const file = items[i].getAsFile();
            files.push({
              type: 'file',
              nextId: getNextId(),
              name: file.name,
              path: '',
              file,
            });
          } else if (item.isDirectory) {
            files.push({type: 'folder', path: item.name});
            const taskItem = traverseDirectory(files, item);

            taskList.push(taskItem);
          }
        }
      } catch (err) {
        console.error('[handleFiles err]', i, err);
      }
    }

    await Promise.all(taskList);

    return files;
  } catch (error) {
    console.error('[handleFiles error]', error);
    throw error;
  }
}
