// roles.js

// worker-运维；manager-管理员；master-频道主；admin-超管

export const DEVICE_MANAGER = 'worker'; // 运维（设备管理）
export const CHANNEL_MANAGER = 'manager'; // 管理员（频道管理）
export const SUPER_ADMIN = 'admin'; // 超级管理员
export const CHANNEL_MASTER = 'master'; // 频道主（UP主）

// 根据角色id获取名称
export function getRoleName(role) {
  let roleName;
  switch (role) {
    case DEVICE_MANAGER:
      roleName = '设备管理';
      break;
    case CHANNEL_MANAGER:
      roleName = '频道管理';
      // 新用户申请
      // 管理的频道
      break;
    case SUPER_ADMIN:
      roleName = '超级管理员';
      break;
    case CHANNEL_MASTER:
      roleName = 'UP主';
      // 新用户申请
      // 创建的频道
      // 管理的频道
      break;
    default:
      break;
  }
  return roleName;
}
