<template>
  <div class="edit-success">
    <div class="edit-success__icon">
      <img width="73px" src="@/assets/edit_success.png" ondragstart="return false" />
    </div>
    <div class="edit-success__title">提交成功</div>
    <div class="edit-success__description">该资源已上传至媒资库，可前往媒资库查看</div>
    <div class="edit-success__buttions">
      <a-space size="large">
        <a-button style="width: 160px" @click="handleResourceLib">前往媒资库</a-button>
        <a-button style="width: 160px" @click="handleMaterialLib" type="primary">继续上传</a-button>
      </a-space>
    </div>
  </div>
</template>
<script setup>
import {useRouter} from 'vue-router';
import {MaterialLibrary, ResourceListView} from '@/router/names';

const router = useRouter();

function handleResourceLib() {
  router.push({name: ResourceListView});
}
function handleMaterialLib() {
  router.push({name: MaterialLibrary});
}
</script>
<style lang="scss" scoped>
.edit-success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .edit-success__title {
    font-size: 24px;
    font-weight: 600;
    color: #000;
    padding-top: 24px;
  }

  .edit-success__description {
    font-size: 14px;
    font-weight: 400;
    color: #7b7b7b;
    padding: 24px 0 48px 0;
  }
}
</style>
