import {nextTick, onMounted, ref} from 'vue';
import usePagination from '@/composable/usePagination';
import {getLinkMaterialList} from '@/apis/media';

export default function useTableBlockResource(pageLoading) {
  const {pagination} = usePagination(getList);

  const query = ref({
    query: '', // 频道名称 / 频道id
    sort: undefined, // create_time
    order: undefined, // desc | asc
  });

  const randomKey = ref(Math.random());

  const dataSource = ref([]);

  function fetchData() {
    if (pageLoading.value) {
      return;
    }
    pagination.value.current = 1;
    getList();
  }

  function reset() {
    if (pageLoading.value) {
      return;
    }
    pagination.value.current = 1;
    query.value = {
      query: '',
      sort: undefined, // create_time
      order: undefined, // desc | asc
    };

    randomKey.value += 1;

    nextTick(() => {
      getList();
    });
  }
  function getList() {
    if (pageLoading.value) {
      return;
    }
    pageLoading.value = true;
    getLinkMaterialList({
      current: pagination.value.current,
      size: pagination.value.pageSize,
      sort: query.value.order === undefined ? undefined : query.value.sort,
      query: query.value.query?.trim() || undefined,
      order:
        query.value.order === undefined
          ? undefined
          : query.value.order === 'ascend'
            ? 'asc'
            : 'desc',
    })
      .then((response) => {
        if (response?.data?.status === 200) {
          dataSource.value = response?.data?.data?.records || [];
          pagination.value = {
            ...pagination.value,
            total: response?.data?.data?.total || 0,
          };
        } else {
          throw response;
        }
      })
      .catch((err) => {
        console.error('[err]', err);
      })
      .finally(() => {
        pageLoading.value = false;
      });
  }

  onMounted(() => {
    getList();
  });

  return {randomKey, reset, getList, fetchData, pagination, dataSource, query};
}
